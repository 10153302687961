import { useLocation, useNavigate } from "react-router";
import { screens } from "../../screens/Navigation.types";
import { useAppDispatch } from "../../store";
import { authActions } from "../../store/auth/AuthReducer";
import { useEffect, useMemo, useState } from "react";
import { MenuItem } from "./SideNav.types";
import { useCounterService } from "../../services/useCounterService";
import { UserRoles } from "../../entities/IUser";
import { useGetFiltersByPage } from "../../store/filters/FilterSelectors";

function isStringInMenuArray(menuArray: MenuItem[], searchString: string) {
  for (const item of menuArray) {
    if (item.path === searchString) {
      return true;
    }

    if (item.children) {
      if (isStringInMenuArray(item.children, searchString)) {
        return true;
      }
    }
  }

  return false;
}

export function useSideNav() {
  const navigate = useNavigate();
  const [history, setHistory] = useState<string[]>([]);
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState<string>(
    location.pathname.split(screens.root)[1]
      ? location.pathname.split(screens.root)[1]
      : screens.documents.replace("/", "")
  );
  const dispatch = useAppDispatch();
  const { allTasksCounter, myTasksCounter } = useCounterService();
  const myTasksFilters = useGetFiltersByPage(screens.myTasks);
  const allTasksFilters = useGetFiltersByPage(screens.tasks);
  const documentsFilters = useGetFiltersByPage(screens.documents);
  const archiveFilters = useGetFiltersByPage(screens.archive);
  const archiveStatsFilters = useGetFiltersByPage(screens.archiveStats);
  const taskStatsFilters = useGetFiltersByPage(screens.taskStats);
  const reportsFilters = useGetFiltersByPage(screens.reports);
  const digitalSignatureFilters = useGetFiltersByPage(screens.digitalSignature);

  useEffect(() => {
    setHistory((prev) => [location.pathname.split(screens.root)[1], ...prev]);
    if (
      isStringInMenuArray(
        sideMenuItems,
        "/" + location.pathname.split(screens.root)[1]
      )
    ) {
      setActiveRoute(location.pathname.split(screens.root)[1]);
    }
  }, [location]);

  const logOut = () => {
    dispatch(authActions.setUser(null));
    navigate(screens.login);
  };
  const generateSideMenuItems = (
    allTasksCounter: number,
    myTasksCounter: number
  ) => {
    return [
      {
        icon: "fa-regular fa-folder-open",
        label: "Documents",
        value: "documents",
        allowedRoles: [],
        children: [
          {
            icon: "",
            label: "Documents",
            value: "documents",
            path: screens.documents,
            allowedRoles: [],
            query: documentsFilters,
          },
          {
            icon: "",
            label: "Archive",
            value: "archive",
            path: screens.archive,
            allowedRoles: [],
            query: archiveFilters,
          },
          {
            icon: "",
            label: "Dashboard",
            value: "archive-stats",
            path: screens.archiveStats,
            allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR],
            query: archiveStatsFilters,
          },
          {
            icon: "",
            label: "Signatures",
            value: "digital-signature",
            path: screens.digitalSignature,
            allowedRoles: [],
            query: digitalSignatureFilters,
            pilot: true,
          },
        ],
      },
      {
        icon: "fa-solid fa-tasks",
        label: "Tasks",
        value: "tasks",
        allowedRoles: [
          UserRoles.ADMIN,
          UserRoles.SUPERVISOR,
          UserRoles.BO_EXPERT,
        ],
        query: allTasksFilters,
        children: [
          {
            icon: "",
            label: "All Tasks",
            value: "tasks",
            path: screens.tasks,
            counter: allTasksCounter,
            allowedRoles: [
              UserRoles.ADMIN,
              UserRoles.SUPERVISOR,
              UserRoles.BO_EXPERT,
            ],
            query: allTasksFilters,
          },
          {
            icon: "",
            label: "My Tasks",
            value: "my-tasks",
            path: screens.myTasks,
            counter: myTasksCounter,
            allowedRoles: [
              UserRoles.ADMIN,
              UserRoles.SUPERVISOR,
              UserRoles.BO_EXPERT,
            ],
            query: myTasksFilters,
          },
          {
            icon: "",
            label: "Baskets",
            value: "baskets",
            path: screens.baskets,
            allowedRoles: [
              UserRoles.ADMIN,
              UserRoles.SUPERVISOR,
              UserRoles.BO_EXPERT,
            ],
          },
          {
            icon: "",
            label: "Dashboard",
            value: "task-stats",
            path: screens.taskStats,
            allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR],
            query: taskStatsFilters,
          },
          {
            icon: "",
            label: "Reports",
            value: "reports",
            path: screens.reports,
            allowedRoles: [UserRoles.ADMIN, UserRoles.SUPERVISOR],
            query: reportsFilters,
          },
          /* {
            icon: "",
            label: "Rules",
            value: "baskets-rules",
            path: screens.basketRules,
          },*/
        ],
      },
    ];
  };

  const sideMenuItems = useMemo(
    () => generateSideMenuItems(allTasksCounter, myTasksCounter),
    [
      allTasksCounter,
      myTasksCounter,
      myTasksFilters,
      allTasksFilters,
      documentsFilters,
      archiveFilters,
      archiveStatsFilters,
      taskStatsFilters,
      reportsFilters,
    ]
  );
  return {
    navigate,
    activeRoute,
    logOut,
    history,
    sideMenuItems,
  };
}
