import { AddCommentToTaskDTO } from "../../entities/ITask";
import { GenericForm } from "../generic-form/GenericForm.types";
import { GenericFormValidator } from "../generic-form/utility/GenericFormValidator";

export const taskAddCommentForm: GenericForm<AddCommentToTaskDTO> = {
  text: {
    type: "input",
    name: "text",
    label: "Comment",
    defaultValue: "",
    validators: [
      GenericFormValidator.required({
        error: "Comment is required. Please provide a value.",
      }),
    ],
  },
  taskId: {
    type: "hidden",
    visibleIf: () => false,
    name: "taskId",
    label: "Task Id",
    defaultValue: 0,
    validators: [],
  },
};

export interface AddCommentModalProps {
  show: boolean;
  onCancel: () => void;
  onSubmit: (data: AddCommentToTaskDTO) => void;
  taskAddCommentForm: GenericForm<AddCommentToTaskDTO>;
  addingComment: boolean;
}

export interface ITaskComment {
  id: number;
  text: string;
  createdAt: string;
  createdBy: {
    name: "Bernhard Riemann";
  };
}
export interface CommentsProps {
  comments: ITaskComment[];
  showMoreComments: boolean;
  addModalData: AddCommentModalProps;
  showAddCommentModal: () => void;
  showMoreCommentsToggle: () => void;
}
