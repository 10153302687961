import {
  CreateDigitalSignatureRequestDTO,
  IDigitalSignatureRequest,
} from "../../entities/IDigitalSignature";
import {
  CreateDocumentDTO,
  GetDocumentsStatsResponse,
  IDocument,
  IDocumentType,
  UpdateDocumentDTO,
  ValidateDocumentResponse,
  AccountingPeriodsResponse,
  CreateDocumentSessionResponse,
  GetDocumentsBySessionIdResponse,
  UpdateSessionDocumentDTO,
} from "../../entities/IDocument";
import { IMetaDataRule, MetaData } from "../../entities/IMetaData";
import { CreateTaskDTO, ITask } from "../../entities/ITask";
import BaseInstance from "../BaseInstance";
import DigitalSignatureGateway from "./DigitalSignatureGateways";
import TaskGateway from "./TaskGateway";

const DocumentGateway = {
  getDocumentTypes: async (): Promise<IDocumentType[]> => {
    return BaseInstance.get("/document-types");
  },

  getAccountingPeriodsByDocId: async (
    id: string
  ): Promise<AccountingPeriodsResponse[]> => {
    return BaseInstance.get(`/task-types/${id}/list`);
  },
  getAllAccountingPeriods: async (): Promise<AccountingPeriodsResponse[]> => {
    return BaseInstance.get(`/task-types`);
  },
  getMetaDataForDocType: async ({
    code,
  }: {
    code: string;
  }): Promise<IMetaDataRule[]> => {
    return BaseInstance.get(`/document-types/${code}/meta-data`);
  },

  processDocument: async ({
    id,
    docTypeCode,
  }: {
    id: string;
    docTypeCode: string;
  }): Promise<IMetaDataRule[]> => {
    return BaseInstance.post(
      "/documents/process",
      { docId: id, docTypeCode },
      { timeout: 45000 }
    );
  },

  createDocument: async ({
    docTypeCode,
    file,
    metaData,
    draft,
  }: CreateDocumentDTO): Promise<IDocument> => {
    const formData = new FormData();
    formData.append("file", file);
    if (docTypeCode) formData.append("docTypeCode", docTypeCode);
    if (metaData) formData.append("metaData", JSON.stringify(metaData));
    if (draft) formData.append("draft", JSON.stringify(draft));
    return BaseInstance.post("/documents", formData, {
      timeout: 300000 /* 5min timeout for large files or slow internet */,
    });
  },

  createDocumentSession: async ({
    files,
  }: {
    files: File[];
  }): Promise<CreateDocumentSessionResponse> => {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    return BaseInstance.post("/upload-session", formData, {
      timeout: 300000 /* 5min timeout for large files or slow internet */,
    });
  },

  getDocumentsArchive: async (
    query: string
  ): Promise<{
    count: number;
    documents: IDocument[];
  }> => {
    return BaseInstance.get(`/documents/archive${query}`);
  },

  getDocuments: async (
    query: string
  ): Promise<{
    count: number;
    documents: IDocument[];
  }> => {
    return BaseInstance.get(`/documents${query}`);
  },

  getDocumentsBySessionId: async ({
    id,
  }: {
    id: string;
  }): Promise<GetDocumentsBySessionIdResponse> => {
    return BaseInstance.get(`/upload-session/${id}`);
  },

  getDocumentById: async ({ id }: { id: string }): Promise<IDocument> => {
    return BaseInstance.get(`/documents/${id}`);
  },

  getDocumentByIdAndMetaData: async ({
    id,
  }: {
    id: string;
  }): Promise<{
    document: IDocument;
    metaDataRules: IMetaDataRule[] | null;
  }> => {
    const document = await DocumentGateway.getDocumentById({ id });
    let metaDataRules = null;
    if (document.docTypeCode) {
      metaDataRules = await DocumentGateway.getMetaDataForDocType({
        code: document.docTypeCode,
      });
    }
    return { document, metaDataRules };
  },

  validateDocument: async (
    payload: UpdateDocumentDTO
  ): Promise<ValidateDocumentResponse> => {
    return BaseInstance.post("/documents/validate", {
      documentTypeCode: payload.documentTypeCode,
      metaData: payload.metaData,
    });
  },

  updateDocument: async (payload: UpdateDocumentDTO): Promise<IDocument> => {
    const formData = new FormData();
    formData.append("metaData", JSON.stringify(payload.metaData));
    if (payload.file) {
      formData.append("file", payload.file);
    }
    if (payload.documentTypeCode) {
      formData.append("documentTypeCode", payload.documentTypeCode);
    }
    if (payload.status) {
      formData.append("status", payload.status);
    }
    return BaseInstance.put(`/documents/${payload.id}`, formData);
  },

  updateSessionDocument: async (
    payload: UpdateSessionDocumentDTO
  ): Promise<CreateDocumentSessionResponse> => {
    const formData = new FormData();
    formData.append("metaData", JSON.stringify(payload.metaData));
    if (payload.file) {
      formData.append("file", payload.file);
    }
    if (payload.documentTypeCode) {
      formData.append("documentTypeCode", payload.documentTypeCode);
    }
    if (payload.status) {
      formData.append("status", payload.status);
    }
    return BaseInstance.patch(
      `/upload-session/${payload.sessionId}/document/${payload.id}`,
      formData
    );
  },

  dismissDocument: async ({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> => {
    return BaseInstance.delete(`/documents/${id}`);
  },
  dismissDocumentSession: async ({ id }: { id: string }) => {
    return BaseInstance.delete(`/upload-session/${id}`);
  },

  updateDocuments: async ({
    documentIds,
    metaData,
  }: {
    documentIds: number[];
    metaData: MetaData;
  }): Promise<IDocument[]> => {
    return BaseInstance.patch("/documents", { docIds: documentIds, metaData });
  },

  getDocumentsStats: async (
    query: string
  ): Promise<GetDocumentsStatsResponse> => {
    return BaseInstance.get(`/documents/stats${query}`);
  },

  createDocumentAndTask: async ({
    documentPayload,
    taskPayload,
  }: {
    documentPayload: UpdateDocumentDTO;
    taskPayload: CreateTaskDTO;
  }): Promise<{
    document: IDocument;
    task: ITask;
  }> => {
    const document = await DocumentGateway.updateDocument({
      ...documentPayload,
    });
    const task = await TaskGateway.createTask({
      ...taskPayload,
      documentId: document.id,
    });
    return { document, task };
  },

  createDocumentAndRequestSignature: async ({
    documentPayload,
    signaturePayload,
  }: {
    documentPayload: UpdateDocumentDTO;
    signaturePayload: CreateDigitalSignatureRequestDTO;
  }): Promise<{
    document: IDocument;
    signature: IDigitalSignatureRequest;
  }> => {
    const document = await DocumentGateway.updateDocument({
      ...documentPayload,
    });
    const signature =
      (await DigitalSignatureGateway.createDigitalSignatureRequest({
        ...signaturePayload,
        documentId: document.id,
      })) as any;
    return { document, signature };
  },

  createSessionDocumentAndRequestSignature: async ({
    documentPayload,
    signaturePayload,
  }: {
    documentPayload: UpdateSessionDocumentDTO;
    signaturePayload: CreateDigitalSignatureRequestDTO;
  }): Promise<{
    document: IDocument | undefined;
    signature: IDigitalSignatureRequest;
  }> => {
    const document = await DocumentGateway.updateSessionDocument({
      ...documentPayload,
    });

    const signature =
      (await DigitalSignatureGateway.createDigitalSignatureRequest({
        ...signaturePayload,
        documentId: document.id,
      })) as any;
    return {
      document: document.documents.find((item) => item.id === document.id),
      signature,
    };
  },

  createSessionDocumentAndTask: async ({
    documentPayload,
    taskPayload,
  }: {
    documentPayload: UpdateSessionDocumentDTO;
    taskPayload: CreateTaskDTO;
  }): Promise<{
    document: IDocument | undefined;
    task: ITask;
  }> => {
    const document = await DocumentGateway.updateSessionDocument({
      ...documentPayload,
    });

    const task = await TaskGateway.createTask({
      ...taskPayload,
      documentId: +documentPayload.id,
    });

    return {
      document: document.documents.find((item) => item.id === document.id),
      task,
    };
  },
  addNewDocumentToSession: async ({
    file,
    id,
  }: {
    file: File;
    id: string;
  }): Promise<CreateDocumentSessionResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    return BaseInstance.post(
      "/upload-session/" + id + "/add-document",
      formData,
      {
        timeout: 300000 /* 5min timeout for large files or slow internet */,
      }
    );
  },
  deleteDocumentFromSession: async ({
    id,
    documentId,
  }: {
    id: string;
    documentId: string;
  }): Promise<CreateDocumentSessionResponse> => {
    return BaseInstance.delete(
      "/upload-session/" + id + "/document/" + documentId
    );
  },
  exportArchiveStats: async (query: string): Promise<string> => {
    return BaseInstance.get(`/documents/stats/export-report${query}`);
  },
};

export default DocumentGateway;
