import React from "react";
import { PageLayout } from "../../components/page-layout";
import { Button } from "../../components/button";
import { useSplitFile } from "./useSplitFile";
import PageContainer from "./PageContainer";
import { ViewPageModal } from "./ViewPageModal";
import { SplitFileDismissModal } from "./SplitFileDismissModal";
import { DeletePageModal } from "./DeletePageModal";
import { RangeInput } from "../../components/range-input";

export const SplitFileScreen: React.FC = () => {
  const {
    isLoading,
    pages,
    showDeleteModal,
    hideDeleteModal,
    showPageModal,
    hidePageModal,
    showDismissModal,
    hideDismissModal,
    showModal,
    onDeletePage,
    onAddSeparator,
    onRemoveSeparator,
    onResizePages,
    onSubmit,
    divisionCount,
    pagesPerRow,
    onDismiss,
    uploadingDocuments,
  } = useSplitFile();

  return (
    <PageLayout
      title={"Split File"}
      backBtn={showDismissModal}
      titleRight={
        <div className="d-flex justify-content-end gap-2 align-items-center">
          <RangeInput
            label="Resize pages"
            defaultValue={5}
            min={1}
            max={10}
            step={1}
            onChange={onResizePages}
            disabled={isLoading}
          />
          <Button
            className="btn btn-dark"
            onClick={onSubmit}
            loader={{ size: "s" }}
            isLoading={uploadingDocuments}
          >
            {divisionCount > 1
              ? `Split into ${divisionCount} files`
              : "Proceed without splitting file"}
          </Button>
        </div>
      }
    >
      <PageContainer
        isLoading={isLoading}
        pages={pages}
        seePage={showPageModal}
        addSeparator={onAddSeparator}
        removeSeparator={onRemoveSeparator}
        deletePage={showDeleteModal}
        pagesPerRow={pagesPerRow}
      />
      <SplitFileDismissModal
        show={showModal?.action === "dismiss"}
        onCancel={hideDismissModal}
        onDismiss={onDismiss}
      />
      {!!pages.length && (
        <>
          <ViewPageModal
            show={showModal?.action === "see"}
            onClose={hidePageModal}
            page={pages && pages.find((p) => p.id === showModal?.id)}
          />
          <DeletePageModal
            show={showModal?.action === "delete"}
            onCancel={hideDeleteModal}
            onDismiss={onDeletePage}
            pageNumber={
              showModal
                ? pages.find((p) => p.id === showModal?.id)?.pageNumber || 0
                : 0
            }
          />
        </>
      )}
    </PageLayout>
  );
};
