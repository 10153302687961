import { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../../components/toast";
import { ITask, TaskStatuses } from "../../entities/ITask";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useParams } from "react-router-dom";
import {
  taskCancelForm as taskCancelFormDefault,
  taskPutOnHoldForm as taskPutOnHoldFormDefault,
} from "./Task.types";
import { useCounterService } from "../../services/useCounterService";
import { useUser } from "../../store/auth/AuthSelectors";

const useTask = () => {
  const { success, danger } = useToast();
  const id = +(useParams<{ id: string }>().id || -1);
  const [task, setTask] = useState<ITask>();
  const user = useUser();
  const { refreshTaskCounters, refreshCommentsCount } = useCounterService();
  const { execute: getData, loading } = useBaseRequest(
    TaskGateway.getTaskById,
    {
      onCompleted: (data) => {
        setTask(data);
        refreshTaskCounters();
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  useEffect(() => {
    getData(id);
    refreshCommentsCount(id);
  }, [id]);

  const [selectedAction, setSelectedAction] = useState<string>("");
  const onChangeAction = (action: string) => {
    setSelectedAction(action);
  };

  const { execute: cancelTask, loading: cancelingTask } = useBaseRequest(
    TaskGateway.cancelTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        refreshCommentsCount(id);
        success("Task canceled successfully.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const taskCancelForm = {
    ...taskCancelFormDefault,
    id: { ...taskCancelFormDefault.id, defaultValue: task?.id || -1 },
  };

  const { execute: reassignTask, loading: reassigningTask } = useBaseRequest(
    TaskGateway.reassignTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success("Task reassigned successfully.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  const reassignTaskHandler = (userId: number) => {
    reassignTask({ id, userId });
  };

  const { execute: moveToAnotherBasket, loading: movingTask } = useBaseRequest(
    TaskGateway.moveToAnotherBasket,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success("Task moved successfully.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const { execute: changeStatus, loading: changingStatus } = useBaseRequest(
    TaskGateway.changeStatus,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success("Task status changed successfully.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  const moveToAnotherBasketHandler = (basketId: number) => {
    moveToAnotherBasket({ id, basketId });
  };

  const changeStatusHandler = (status: "resolved" | "inprogress") => {
    changeStatus({ id, status });
  };

  const { execute: unassignTask, loading: unassigning } = useBaseRequest(
    TaskGateway.unassignTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        success("Task unassigned successfully.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  const { execute: putOnHold, loading: puttingOnHold } = useBaseRequest(
    TaskGateway.onHoldTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        refreshCommentsCount(id);
        success("Task put on hold");
      },
    }
  );
  const { commentCounter } = useCounterService();

  const unassignTaskHandler = () => {
    unassignTask(id);
  };

  const taskPutOnHoldForm = {
    ...taskPutOnHoldFormDefault,
    id: { ...taskPutOnHoldFormDefault.id, defaultValue: task?.id || -1 },
  };

  const taskTabs = useMemo(() => {
    return [
      { id: 0, name: "Details", link: "details" },
      { id: 1, name: "Comments", link: "comments", counter: commentCounter },
      { id: 2, name: "Logs", link: "logs" },
    ];
  }, [commentCounter]);

  const shouldPermitBOExpertToManageTask = () => {
    /*if (task?.assignee && task?.assignee?.id !== user?.id) {
      return true;
    }*/
    if (task?.status === TaskStatuses.CANCELED && !task.assignee) {
      return true;
    }
    return false;
  };

  const { execute: resolveTask, loading: resolvingTask } = useBaseRequest(
    TaskGateway.resolveTaskById,
    {
      onCompleted: (data) => {
        setSelectedAction("");
        getData(id);
        refreshCommentsCount(id);
        success("Task resolved successfully.");
      },
    }
  );

  const taskResolvedForm = {
    ...taskPutOnHoldFormDefault,
    id: { ...taskPutOnHoldFormDefault.id, defaultValue: task?.id || -1 },
  };

  return {
    task,
    taskId: id,
    loading,
    onChangeAction,
    selectedAction,
    cancelingTask,
    cancelTask,
    taskCancelForm,
    reassignTask: reassignTaskHandler,
    reassigningTask,
    movingTask,
    moveToAnotherBasket: moveToAnotherBasketHandler,
    changingStatus,
    changeStatus: changeStatusHandler,
    unassignTask: unassignTaskHandler,
    unassigning,
    putOnHold,
    puttingOnHold,
    taskPutOnHoldForm,
    taskTabs,
    user,
    shouldPermitBOExpertToManageTask,
    resolveTask,
    resolvingTask,
    taskResolvedForm,
  };
};

export default useTask;
