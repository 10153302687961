import Autocomplete from "../auto-complete/AutoComplete";
import { AutocompleteOptProps } from "./AutocompleteOpt.types";
import useAutocompleteOpt from "./useAutocompleteOpt";

const AutocompleteOpt = ({
  onSelectOption,
  endpoint,
  limit = 10,
  placeholder = "Search options",
  label = "Search options",
  isMultiple = false,
  defaultValue,
  excluded = [],
  showRemoveAll,
  minCharsToSearch = 1,
}: AutocompleteOptProps) => {
  const {
    options,
    selectedOption,
    searchInput,
    onChangeSearchInput,
    onChange,
    loading,
    isEnd,
    onLoadMore,
    onInputFocus,
  } = useAutocompleteOpt(
    onSelectOption,
    endpoint,
    limit,
    excluded,
    defaultValue,
    minCharsToSearch
  );
  return (
    <Autocomplete
      isMultiple={isMultiple}
      label={label}
      isLoading={loading}
      options={options}
      value={selectedOption}
      placeholder={placeholder}
      inputValue={searchInput}
      onChange={onChange}
      onFocused={onInputFocus}
      onInputChange={onChangeSearchInput}
      onLoadMore={onLoadMore}
      isEnd={isEnd}
      showRemoveAll={showRemoveAll}
      minCharsToSearch={minCharsToSearch}
    />
  );
};

export default AutocompleteOpt;
