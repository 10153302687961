import { RefObject, useEffect, useState } from "react";

interface IPoint {
  x: number;
  y: number;
}

const useDoughnutChart = (
  canvasRef: RefObject<HTMLCanvasElement | null>,
  data: { title: string; value: number; color: string }[],
  total: number
) => {
  const [segmentData, setSegmentData] = useState<
    {
      title: string;
      value: number;
      color: string;
      percentageValue: number;
    }[]
  >([]);
  useEffect(() => {
    if (!data || !data.length) {
      setSegmentData([
        { value: 0, color: "#f2f2f2", title: "", percentageValue: 100 },
      ]);
      return;
    }
    setSegmentData(
      data.map((item) => ({
        ...item,
        percentageValue: (item.value / total) * 100,
      }))
    );
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !segmentData.length) return;
    drawDougnnutChart(canvas);
  }, [segmentData]);
  const getCenterPoint = (canvas: HTMLCanvasElement): IPoint => {
    return { x: canvas.width / 2, y: canvas.height / 2 };
  };

  //INPUT DATA
  const outerRadius = 75;
  const donutWidth = 37.5;

  //INPUT DATA
  const drawSegment = (
    ctx: CanvasRenderingContext2D,
    donutParamms: { center: IPoint; outerRadius: number; donutWidth: number },
    currentAngle: number,
    segment: {
      value: number;
      color: string;
      percentageValue: number;
      title: string;
    }
  ) => {
    const { center, outerRadius, donutWidth } = donutParamms;
    const { percentageValue, color } = segment;
    const segmentValue = percentageValue;

    const segmentAngle = (segmentValue / 100) * 2 * Math.PI;
    ctx.beginPath();
    ctx.arc(
      center.x,
      center.y,
      outerRadius,
      currentAngle,
      currentAngle + segmentAngle
    );
    ctx.arc(
      center.x,
      center.y,
      outerRadius - donutWidth,
      currentAngle + segmentAngle,
      currentAngle,
      true
    );
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();

    ctx.beginPath();
    const middleAngle = currentAngle + segmentAngle / 2;
    const middleX = center.x + outerRadius * Math.cos(middleAngle);
    const middleY = center.y + outerRadius * Math.sin(middleAngle);

    const circleRadius = 17.5;
    ctx.shadowBlur = 5;
    ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
    if (!segment.title) return currentAngle;
    ctx.arc(middleX, middleY, circleRadius, 0, 2 * Math.PI);
    ctx.fillStyle = "#FFFFFF";
    ctx.fill();

    ctx.shadowBlur = 0;
    ctx.font = "13px Arial";
    ctx.fillStyle = "#000000";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(`${Math.round(segmentValue)}%`, middleX, middleY + 1);

    return currentAngle + segmentAngle;
  };

  const drawDougnnutChart = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const center = getCenterPoint(canvas);
    let currentAngle = -Math.PI / 2;

    for (let i = 0; i < segmentData.length; i++) {
      currentAngle = drawSegment(
        ctx,
        { center, outerRadius, donutWidth },
        currentAngle,
        segmentData[i]
      );
    }
  };
};

export default useDoughnutChart;
