import { Filter } from "../../components/filters";
import { AccountingPeriods, taskStatuses } from "../../entities/ITask";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";

export type TaskFilters = {
  status: Filter;
  "user=>id": Filter;
  basketId: Filter;
  taskTypeCode: Filter;
  createdAt: Filter;
  "createdBy=>id": Filter;
  "user=>dealerCode": Filter;
  isComplaint: Filter;
};
export const tasksTableFilters = (
  removeFilter: (keyof TaskFilters)[]
): Partial<TaskFilters> => {
  const filters: TaskFilters = {
    status: {
      type: "multiple-choice",
      name: "Statuses",
      defaultValues: ["onhold", "assigned", "inprogress"],
      options: [
        ...Object.keys(taskStatuses).map((item) => ({
          label: taskStatuses[item as keyof typeof taskStatuses],
          value: item,
        })),
      ],
      buildQuery: (filter, values) => {
        return {
          name: "status",
          type: "stringArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    "user=>id": {
      type: "multiple-choice-autocomplete",
      name: "Assignees",
      options: [{ label: "users", value: "users" }],
      buildQuery: (filter, values) => {
        return {
          name: "user=>id",
          type: "numberArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    "user=>dealerCode": {
      type: "multiple-choice-autocomplete",
      name: "PoS",
      options: [{ label: "PoS", value: "dealers" }],
      buildQuery: (filter, values) => {
        return {
          name: "user=>dealerCode",
          type: "stringArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    "createdBy=>id": {
      type: "multiple-choice-autocomplete",
      name: "Created by",
      options: [{ label: "users", value: "users" }],
      buildQuery: (filter, values) => {
        return {
          name: "createdBy=>id",
          type: "numberArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    basketId: {
      type: "multiple-choice-autocomplete",
      name: "Baskets",
      options: [{ label: "baskets", value: "baskets" }],
      buildQuery: (filter, values) => {
        return {
          name: "basketId",
          type: "numberArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    taskTypeCode: {
      type: "multiple-choice",
      name: "Billing cycles",
      options: [
        ...Object.keys(AccountingPeriods).map((item) => ({
          label: AccountingPeriods[item as keyof typeof AccountingPeriods],
          value: item,
        })),
      ],
      buildQuery: (filter, values) => {
        return {
          name: "taskTypeCode",
          type: "stringArray",
          operator: "in",
          value: JSON.stringify(values),
        };
      },
    },
    createdAt: {
      type: "date-range",
      name: "Created date",
      buildQuery: (filter, values) => {
        const { value, operator, type } = buildDateQueryOperator(values);
        return {
          name: "createdAt",
          type,
          operator,
          value: JSON.stringify(value),
        };
      },
    },
    isComplaint: {
      type: "single-choice",
      name: "Is Complaint",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      buildQuery: (filter, value, operator) => {
        const v = value as any as string;
        return {
          name: "complaintNumber",
          type: "string",
          operator: v === "yes" ? "ne" : "eq",
          value: null,
        };
      },
    },
  };

  removeFilter.forEach((filter) => {
    delete filters[filter];
  });

  return filters;
};
