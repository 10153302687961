import { PageLayout } from "../../components/page-layout";
import useReports from "./useReports";
import { Column, Row } from "../../components/grid";
import { Button } from "react-bootstrap";
import { Icon } from "../../modules/icon";
import { Filters } from "../../components/filters";
import Select from "../../components/select/Select";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";

export function ReportsScreen() {
  const {
    loading,
    tableData,
    count,
    tableHeaders,
    exportReport,
    defaultGroupBy,

    onChangeGroupBy,

    onRowPress,
    onFilterChange,
    reportTableFilters,
  } = useReports();

  return (
    <PageLayout
      title={"Reports"}
      titleRight={
        <Button className="btn btn-success" onClick={exportReport}>
          <Icon type="fa-solid fa-file-export" />
          &nbsp; Export
        </Button>
      }
    >
      <Row>
        <Column className="d-flex align-items-end">
          <Filters
            onFilterChange={onFilterChange}
            filters={reportTableFilters}
          />
        </Column>
        <Column>
          <div className="d-flex flex-row my-1 justify-content-end">
            <Select
              options={[
                {
                  label: "Basket",
                  value: "BASKET",
                },
                {
                  label: "User",
                  value: "ASSIGNED_USER",
                },
                {
                  label: "Document type",
                  value: "DOCUMENT_TYPE",
                },
              ]}
              value={defaultGroupBy}
              onChange={onChangeGroupBy}
              title="Group by"
              defaultLabel="Select group by"
            />
          </div>
        </Column>
      </Row>
      <Row>
        <Column>
          <div className="my-2 w-100">
            <TableWithQueryPaginationAndSort
              totalItems={count}
              loading={loading}
              headers={tableHeaders}
              data={tableData}
              onRowPress={onRowPress}
              selection={false}
            />
          </div>
        </Column>
      </Row>
    </PageLayout>
  );
}
