import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { IDocument, IDocumentType } from "../../entities/IDocument";
import { MetaDataForm } from "../../entities/IMetaData";
import { IUser } from "../../entities/IUser";

export interface DocumentDataPreviewProps {
  docType: IDocumentType;
  metaDataForm: GenericForm<MetaDataForm> | null;
}

export interface DocumentDataUpdateProps {
  docType: IDocumentType;
  metaDataForm: GenericForm<MetaDataForm> | null;
  onUpdate: (metaData: MetaDataForm) => any;
  updating?: boolean;
}

export interface DocumentDismissModalProps {
  show: boolean;
  onCancel: () => any;
  onDismiss: () => any;
}

export interface DocumentActionsProps {
  onActionSelected: (action: string) => any;
  document: IDocument;
  user: IUser | null;
}

export enum DocumentActionsTypes {
  VIEW_TASK = "view_task",
  CREATE_TASK = "create_task",
  VIEW_DIGITAL_SIGNATURE = "view_digital_signature",
  REQUEST_DIGITAL_SIGNATURE = "request_digital_signature",
  EDIT_DOCUMENT = "edit_document",
  DELETE_DOCUMENT = "delete_document",
}
