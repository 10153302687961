import { Comments } from "../../components/comments";
import { Loader } from "../../modules/loader";
import useTaskComments from "./useTaskComments";

const TaskComments = () => {
  const {
    comments,
    showMoreComments,
    showCommentForm,
    hideCommentFormHandler,
    addCommentToTask,
    addingComment,
    taskAddCommentForm,
    showCommentFormHandler,
    setShowMoreComments,
    loadingComments,
  } = useTaskComments();
  if (loadingComments) {
    return (
      <div className="w-100 flex-row justify-content-center">
        <Loader size="l" color="black" />
      </div>
    );
  }
  if (!comments) {
    return <div>Comments not found</div>;
  }
  return (
    <Comments
      comments={comments}
      showMoreComments={showMoreComments}
      addModalData={{
        show: showCommentForm,
        onCancel: hideCommentFormHandler,
        onSubmit: addCommentToTask,
        addingComment: addingComment,
        taskAddCommentForm: taskAddCommentForm,
      }}
      showAddCommentModal={showCommentFormHandler}
      showMoreCommentsToggle={() => {
        setShowMoreComments((prev) => !prev);
      }}
    />
  );
};

export default TaskComments;
