import Header from "../../components/header/Header";
import CardContainer from "../../components/card/CardContainer";
import { DraftDocumentCard } from "./DraftDocumentCard";
import { DraftDocumentsProps } from "./UploadDocumentsSession.types";
import { Badge } from "../../components/badge";
import { AddDocumentCard } from "./AddDocumentCard";
import { isDocumentProcessedChecker } from "./utility/isDocumentProcessedChecker";

export const DraftDocuments = ({
  draftDocuments,
  onDismiss,
  onAdd,
  onView,
}: DraftDocumentsProps) => {
  return (
    <>
      {!!draftDocuments.length && (
        <section className="flex-column gap-2">
          <Header
            title="Draft documents"
            size="h5"
            badge={
              <Badge
                className="ms-2 px-1"
                type={"dark"}
                variant="outlined"
              >{`${draftDocuments.length} files`}</Badge>
            }
          />
          <CardContainer
            children={[
              ...draftDocuments.map((item, i) => (
                <DraftDocumentCard
                  key={i}
                  onDismiss={onDismiss}
                  onClick={() => {
                    onView(item.id.toString());
                  }}
                  status={item.status}
                  name={`Document #${item.id}`}
                  id={item.id}
                  isProcessed={isDocumentProcessedChecker(item)}
                />
              )),
              <AddDocumentCard key={draftDocuments.length} onUpload={onAdd} />,
            ]}
            cardGap={16}
            cardMinWidth={160}
            cardMaxWidth={208}
            containerPaddingX={5}
            cardMaxNumberPerRow={5}
          />
        </section>
      )}
    </>
  );
};
