import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { IBasket } from "../../entities/IBasket";

export const createBasketForm: GenericForm<CreateBasketDTO> = {
  name: {
    type: "input",
    name: "name",
    label: "Name",
    defaultValue: "",
    validators: [
      GenericFormValidator.required({
        error: "Basket name is required. Please provide a value.",
      }),
      GenericFormValidator.minChar({
        limit: 3,
        error: "Basket name is too short. Minimum length is 3 characters.",
      }),
      GenericFormValidator.maxChar({
        limit: 128,
        error:
          "Basket name is too long. Maximum length allowed is 128 characters.",
      }),
    ],
  },
  documentTypeCodesForBasketRules: {
    type: "autocompleteMultipleChoice",
    name: "documentTypeCodesForBasketRules",
    label: "Document types",
    options: [{ value: "document-types", label: "Document types" }],
    defaultValue: [],
  },
  isAssignable: {
    type: "checkbox",
    visibleIf: () => false,
    name: "isAssignable",
    label: "Basket is assignable",
    defaultValue: true,
    validators: [],
  },
};

export const updateBasketForm: GenericForm<UpdateBasketDTO> = {
  name: {
    type: "input",
    name: "name",
    label: "Name",
    defaultValue: "",
    validators: [
      GenericFormValidator.required({
        error: "Basket name is required. Please provide a value.",
      }),
      GenericFormValidator.minChar({
        limit: 3,
        error: "Basket name is too short. Minimum length is 3 characters.",
      }),
      GenericFormValidator.maxChar({
        limit: 128,
        error:
          "Basket name is too long. Maximum length allowed is 128 characters.",
      }),
    ],
  },
  documentTypeCodesForBasketRules: {
    type: "autocompleteMultipleChoice",
    name: "documentTypeCodesForBasketRules",
    label: "Document types",
    options: [{ value: "document-types", label: "Document types" }],
    defaultValue: [],
  },
  isAssignable: {
    type: "checkbox",
    visibleIf: () => false,
    name: "isAssignable",
    label: "Basket is assignable",
    defaultValue: true,
    validators: [],
  },
  id: {
    type: "hidden",
    visibleIf: () => false,
    name: "id",
    label: "id",
    defaultValue: 0,
    validators: [],
  },
};

export interface CreateBasketDTO {
  name: string;
  isAssignable: boolean;
  documentTypeCodesForBasketRules: string[];
}

export interface CreateBasketModalProps {
  onDismiss: () => void;
  onSubmit: (data: CreateBasketDTO) => void;
  creating: boolean;
  isVisible: boolean;
  createBasketForm: GenericForm<CreateBasketDTO>;
}

export interface UpdateBasketModalProps {
  onDismiss: () => void;
  onSubmit: (data: UpdateBasketDTO) => void;
  updating: boolean;
  isVisible: boolean;
  updateBasketForm: GenericForm<UpdateBasketDTO>;
}

export interface BasketCardInfoProps {
  name: string;
  numberOfTasks: number;
}
export interface BasketCardActionProps {
  id: number;
  isBookmarked: boolean;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onBookmark: (id: number) => void;
  onClick: () => void;
}

export type BasketCardProps = BasketCardInfoProps & BasketCardActionProps;

export interface IGetBasket {
  id: number;
  name: string;
  isAssignable: boolean;
  isBookmarked: boolean;
  numberOfTasks: number;
  documentTypeCodesForBasketRules: string[];
}

export interface BasketSectionProps {
  baskets: IGetBasket[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onBookmark: (id: number) => void;
}

export type AllBasketSectionProps = BasketSectionProps & {
  showCreateBasketModalHandler: () => void;
};

export type UpdateBasketDTO = IBasket;

export interface IGetBasketApi {
  baskets: IGetBasket[];
  count: number;
}

export interface BasketCardInfoProps {
  name: string;
  numberOfTasks: number;
}
export interface BasketCardActionProps {
  id: number;
  isBookmarked: boolean;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onBookmark: (id: number) => void;
  onClick: () => void;
}

export interface BasketSectionProps {
  baskets: {
    id: number;
    name: string;
    isAssignable: boolean;
    isBookmarked: boolean;
    numberOfTasks: number;
    documentTypeCodesForBasketRules: string[];
  }[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onBookmark: (id: number) => void;
}

export interface DeleteBasketModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  onDelete: () => void;
  deleting: boolean;
}

export const BASKET_SUCCESS_MESSAGES = {
  created: "Basket successfully created!",
  updated: "Basket updated successfully!",
  deleted: "Basket deleted successfully.",
  bookmarked: "Basket successfully bookmarked!",
  unbookmarked: "Basket successfully unbookmarked.",
};
