import { useEffect, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRangeTypeValues } from "../../components/filters";
import moment from "moment";
import { StatCardProps } from "../../components/stat-card";
import { taskStatColors, taskStatLabels } from "./TaskStats.types";
import { useDocumentService } from "../../services/useDocumentService";
import TaskGateway from "../../api/gateways/TaskGateway";
import { StatNumberCardProps } from "../../components/stat-number-card";
import { TaskStatuses } from "../../entities/ITask";

const useTaskStats = () => {
  // Initialize various hooks and states
  const { removeQueryParams } = useDocumentService();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [basketsStat, setBasketsStat] = useState<undefined | StatCardProps[]>(
    undefined
  );
  const [statUrgentTaskNumbers, setStatUrgentTaskNumbers] = useState<
    undefined | StatNumberCardProps[]
  >(undefined);
  const [statStatusTaskNumbers, setStatStatusTaskNumbers] = useState<
    undefined | StatNumberCardProps[]
  >(undefined);
  const [statAHT, setStatAHT] = useState<undefined | StatNumberCardProps>(
    undefined
  );
  const [statART, setStatART] = useState<undefined | StatNumberCardProps>(
    undefined
  );

  // Custom hook for API request handling
  const { execute: getArchiveStats, loading } = useBaseRequest(
    TaskGateway.getTaskStats,
    {
      autoFetch: false,
      onCompleted: (data) => {
        // Update states with fetched data

        setBasketsStat(
          data.baskets.map((item) => ({
            title: item.name,
            total: item.total,
            data: Object.keys(item)
              .filter((itemK) => itemK !== "total" && itemK !== "name")
              .map((itemK, i) => ({
                title: taskStatLabels[itemK as keyof typeof taskStatLabels],
                value: +item[itemK as keyof typeof item],
                color: taskStatColors[itemK as keyof typeof taskStatColors],
              })),
          }))
        );
        const selectedBaskets =
          queryParams.getAll("baskets").map((item) => +item) || [];
        setStatUrgentTaskNumbers([
          {
            title: taskStatLabels.totalOlderThen8,
            total: data.totalOlderThen8,
            color: "#DC3545",
            link: `/tasks?filters=${JSON.stringify({
              name: "status",
              operator: "in",
              value: JSON.stringify([
                TaskStatuses.OPEN,
                TaskStatuses.ASSIGNED,
                TaskStatuses.INPROGRESS,
                TaskStatuses.ONHOLD,
              ]),
              type: "stringArray",
            })}&filters=${JSON.stringify({
              name: "createdAt",
              operator: "lte",
              value: JSON.stringify(
                moment()
                  .subtract(8, "days")
                  .startOf("day")
                  .toDate()
              ),
              type: "date",
            })}${
              selectedBaskets.length
                ? `&filters=${JSON.stringify({
                    name: "basketId",
                    operator: "in",
                    value: JSON.stringify(selectedBaskets),
                    type: "numberArray",
                  })}`
                : ""
            }`,
          },
          {
            title: taskStatLabels.totalOlderThen6,
            total: data.totalOlderThen6,
            color: "#FFC107",
            link: `/tasks?filters=${JSON.stringify({
              name: "status",
              operator: "in",
              value: JSON.stringify([
                TaskStatuses.OPEN,
                TaskStatuses.ASSIGNED,
                TaskStatuses.INPROGRESS,
                TaskStatuses.ONHOLD,
              ]),
              type: "stringArray",
            })}&filters=${JSON.stringify({
              name: "createdAt",
              operator: "lte",
              value: JSON.stringify(
                moment()
                  .subtract(6, "days")
                  .startOf("day")
                  .toDate()
              ),
              type: "date",
            })}${
              selectedBaskets.length
                ? `&filters=${JSON.stringify({
                    name: "basketId",
                    operator: "in",
                    value: JSON.stringify(selectedBaskets),
                    type: "numberArray",
                  })}`
                : ""
            }`,
          },
        ]);
        setStatStatusTaskNumbers([
          {
            title: taskStatLabels.totalAssigned,
            total: data.totalAssigned,
            link: "",
          },
          {
            title: taskStatLabels.totalUnassigned,
            total: data.totalUnassigned,
            link: "",
          },
          {
            title: taskStatLabels.totalResolved,
            total: data.totalResolved,
            link: "",
          },
        ]);
        setStatAHT({
          title: taskStatLabels.aht,
          total: data.aht
            ? data.aht > 24
              ? `${Math.floor(data.aht / 24)} ${
                  Math.floor(data.aht / 24) === 1 ? "day" : "days"
                }`
              : `${Math.floor(data.aht)} ${
                  Math.floor(data.aht) === 1 ? "hour" : "hours"
                }`
            : "0 hours",
        });
        setStatART({
          title: taskStatLabels.art,
          total: data.art
            ? data.art > 24
              ? `${Math.floor(data.art / 24)} ${
                  Math.floor(data.art / 24) === 1 ? "day" : "days"
                }`
              : `${Math.floor(data.art)} ${
                  Math.floor(data.art) === 1 ? "hour" : "hours"
                }`
            : "0 hours",
        });
      },
      onError: (error) => {},
    }
  );

  // Fetch data when query parameters change
  useEffect(() => {
    getArchiveStats(removeQueryParams(location.search, ["dateRange"]));
  }, [location.search]);

  // Function to handle changes in basket selection
  const onChangeBasket = (value: number[] | undefined) => {
    queryParams.delete("baskets");
    if (value)
      value.forEach((item) => {
        queryParams.append("baskets", item.toString());
        if (value.length === 1) queryParams.append("baskets", item.toString());
      });
    navigate({ search: queryParams.toString() });
  };

  // Function to handle changes in date range selection
  const onChangeDateRange = (
    value: {
      fromDate?: Date;
      toDate?: Date;
      value: DateRangeTypeValues;
    } | null
  ) => {
    queryParams.delete("dateRange");
    queryParams.delete("dateFrom");
    queryParams.delete("dateTo");
    if (!value) {
      navigate({ search: queryParams.toString() });
      return;
    }
    if (value.fromDate)
      queryParams.set("dateFrom", value.fromDate.toISOString());
    if (value.toDate) queryParams.set("dateTo", value.toDate.toISOString());
    queryParams.set("dateRange", value.value);

    navigate({ search: queryParams.toString() });
  };

  // Extract and format date range query parameters
  const dateRangeQueryParam = queryParams.get("dateRange");
  const validDateRangeValues = Object.values(DateRangeTypeValues);
  const selectedDateRangeValue =
    dateRangeQueryParam &&
    validDateRangeValues.includes(dateRangeQueryParam as DateRangeTypeValues)
      ? dateRangeQueryParam
      : DateRangeTypeValues.TODAY;
  const fromDate =
    selectedDateRangeValue === DateRangeTypeValues.BETWEEN
      ? queryParams.get("dateFrom") !== null
        ? new Date(queryParams.get("dateFrom") as string)
        : undefined
      : new Date(queryParams.get("dateFrom") || Date.now());
  const toDate =
    selectedDateRangeValue === DateRangeTypeValues.BETWEEN
      ? queryParams.get("dateTo") !== null
        ? new Date(queryParams.get("dateTo") as string)
        : undefined
      : new Date(queryParams.get("dateTo") || moment().toDate());

  // Return relevant values and functions for the component
  return {
    statAHT,
    statART,
    statStatusTaskNumbers,
    statUrgentTaskNumbers,
    basketsStat,
    loading,
    onChangeBasket,
    onChangeDateRange,
    defaultBasket: queryParams.getAll("baskets").map((item) => +item),
    defaultDateRange: {
      value: selectedDateRangeValue as DateRangeTypeValues,
      fromDate,
      toDate,
    },
  };
};

export default useTaskStats;
