import { DateRangeTypeValues } from "../Filters.types";
import { ApplyButton } from "./ApplyButton";
import { DateRangeTypeListProps } from "./DateRangeFilter.types";

export const DateRangeTypeList = ({
  name,
  onChange,
  dateRangeType = DateRangeTypeValues.TODAY,
  onApplyFilter,
}: DateRangeTypeListProps) => (
  <div>
    <div className="px-2 pt-2" style={{ position: "relative" }}>
      {Object.values(DateRangeTypeValues).map((dtValue) => {
        return (
          <div className="form-check" key={dtValue}>
            <input
              className="form-check-input"
              type="radio"
              name={name}
              value={dtValue}
              onChange={onChange}
              checked={dateRangeType === dtValue}
            />
            <label className="form-check-label">{dtValue}</label>
          </div>
        );
      })}
      {![
        DateRangeTypeValues.BETWEEN,
        DateRangeTypeValues.AFTER,
        DateRangeTypeValues.BEFORE,
      ].includes(dateRangeType) && (
        <ApplyButton onApplyFilter={onApplyFilter} />
      )}
    </div>
  </div>
);
