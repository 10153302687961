const publicUrl = (window as any).public_url || "";

const assests = {
  images: {
    logo: publicUrl + "/images/logo.png",
    reupload: publicUrl + "/images/reupload.png",
    documentDraft: publicUrl + "/images/document-draft.png",
    documentUploadSession: publicUrl + "/images/document-bulk-upload.png",
  },
};

export default assests;
