import { BrowserRouter } from "react-router-dom";
import Navigation from "./screens/Navigation";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Toast } from "./components/toast";
import "bootstrap/dist/js/bootstrap.bundle.min";
import environment from "./config/environment";
import { useWindowDimensions } from "./modules/window";

function App() {
  const { width } = useWindowDimensions();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          {environment.env !== "production" ? (
            <div
              className="rounded bg-light position-fixed py-2 px-4"
              style={{
                zIndex: 999,
                top: 12,
                width: 200,
                left: width / 2 - 100,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Test Okruženje
            </div>
          ) : null}
          <Navigation />
        </BrowserRouter>
        <Toast />
      </PersistGate>
    </Provider>
  );
}

export default App;
