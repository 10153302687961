import { Icon } from "../../modules/icon";
import { Button } from "../button";
import Header from "../header/Header";
import { AddCommentModal } from "./AddCommentModal ";
import { CommentsProps } from "./comments.types";
import Comment from "./Comment";

export const Comments = ({
  comments,
  showMoreCommentsToggle,
  showMoreComments,
  showAddCommentModal,
  addModalData,
}: CommentsProps) => {
  return (
    <>
      <AddCommentModal {...addModalData} />
      <div>
        <div className="mt-3 flex-column gap-2">
          <Header
            title="Comments"
            actions={
              <Button
                className={`btn btn-secondary`}
                onClick={showAddCommentModal}
              >
                <Icon type={`fa-solid fa-paper-plane`} />
                &nbsp; Add comment
              </Button>
            }
          />
          <div className="mt-2 flex-column gap-3">
            {comments.map((comment) => (
              <Comment key={comment.id} {...comment} />
            ))}
          </div>
        </div>
        {comments.length > 2 && (
          <Button
            className={`btn btn-primary text-primary mt-4 bg-transparent border-0 w-100`}
            onClick={showMoreCommentsToggle}
          >
            Show {showMoreComments ? "less" : "more"} comments
          </Button>
        )}
      </div>
    </>
  );
};
