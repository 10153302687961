import {
  GetOptionsDTO,
  GetOptionsResponse,
} from "../../components/autocomplete-opt/AutocompleteOpt.types";
import BaseInstance from "../BaseInstance";
import { createFilterQueryWithObjectKeys } from "../createFilterQueryWithObjectKeys";

const OptionListGateway = {
  getOptions: async ({
    textSearch,
    skip,
    limit,
    resetOpts,
    endpoint,
    selected,
  }: GetOptionsDTO): Promise<GetOptionsResponse> => {
    const query = createFilterQueryWithObjectKeys({
      textSearch,
      skip,
      limit,
      excluded: selected?.length === 1 ? [selected[0], selected[0]] : selected,
    });

    return BaseInstance.get(`${endpoint}${query}`);
  },
};

export default OptionListGateway;
