import { Button } from "../../components/button";
import { PageLayout } from "../../components/page-layout";
import { Icon } from "../../modules/icon";
import { DraftDocuments } from "./DraftDocuments";
import { useDraftDocumentsSession } from "./useDraftDocumentsSession";
import { UploadDocumentModal } from "./upload-document-modal/UploadDocumentModal";
import { DismissUploadDocumentSessionModal } from "./DismissUploadDocumentSessionModal";
import { DismissUploadDocumentModal } from "./DismissUploadDocumentModal";

export const UploadDocumentsSessionScreen = () => {
  const {
    onDismiss,
    onDismissDocument,
    draftDocuments,
    onDismissSession,
    onAdd,
    viewId,
    onView,
    onCancelDocumentUpload,
    showDismissSessionModal,
    onCancelDismissSession,
    setShowDismissSessionModal,
    showDismissDocumentModal,
    onCancelDismissDocument,
  } = useDraftDocumentsSession();
  return (
    <PageLayout
      title={"Upload session"}
      titleRight={
        <div className="flex-row w-100 justify-content-end">
          <Button
            type="button"
            className="btn btn-danger fw-medium"
            onClick={() => {
              setShowDismissSessionModal(true);
            }}
          >
            <Icon type={`fa-solid fa-trash`} />
            &nbsp;&nbsp;Dismiss
          </Button>
        </div>
      }
      backBtn
    >
      <DraftDocuments
        onDismiss={onDismiss}
        draftDocuments={draftDocuments}
        onAdd={onAdd}
        onView={onView}
      />
      <UploadDocumentModal
        id={viewId}
        onCancel={onCancelDocumentUpload}
        onDismiss={onDismiss}
      />
      <DismissUploadDocumentModal
        show={showDismissDocumentModal}
        onCancel={onCancelDismissDocument}
        onDismiss={onDismissDocument}
      />
      <DismissUploadDocumentSessionModal
        show={showDismissSessionModal}
        onCancel={onCancelDismissSession}
        onDismiss={onDismissSession}
      />
    </PageLayout>
  );
};
