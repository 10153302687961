import { useEffect, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRangeTypeValues } from "../../components/filters";
import moment from "moment";
import { StatCardProps } from "../../components/stat-card";
import {
  archiveStatsDataColors,
  archiveStatsDataLabels,
  archiveStatsExportHeaders,
} from "./Archive.types";
import { useDocumentService } from "../../services/useDocumentService";

const useArchiveStats = () => {
  // Initialize various hooks and states
  const { removeQueryParams } = useDocumentService();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [total, setTotal] = useState(0);
  const [residential, setResidential] = useState<undefined | StatCardProps>(
    undefined
  );
  const [business, setBusiness] = useState<undefined | StatCardProps>(
    undefined
  );

  // Custom hook for API request handling
  const { execute: getArchiveStats, loading } = useBaseRequest(
    DocumentGateway.getDocumentsStats,
    {
      autoFetch: false,
      onCompleted: (data) => {
        // Update states with fetched data
        setTotal(data.total);
        setResidential({
          title: "Residential",
          total: data.residential.total,
          data: Object.keys(data.residential)
            .filter((item) => item !== "total")
            .map((item, i) => ({
              title:
                archiveStatsDataLabels[
                  item as keyof typeof archiveStatsDataLabels
                ],
              value: data.residential[item as keyof typeof data.residential],
              color:
                archiveStatsDataColors[
                  item as keyof typeof archiveStatsDataLabels
                ],
            })),
        });
        setBusiness({
          title: "Business",
          total: data.business.total,
          data: Object.keys(data.business)
            .filter((item) => item !== "total")
            .map((item, i) => ({
              title:
                archiveStatsDataLabels[
                  item as keyof typeof archiveStatsDataLabels
                ],
              value: data.business[item as keyof typeof data.business],
              color:
                archiveStatsDataColors[
                  item as keyof typeof archiveStatsDataLabels
                ],
            })),
        });
      },
      onError: (error) => {},
    }
  );

  // Fetch data when query parameters change
  useEffect(() => {
    getArchiveStats(removeQueryParams(location.search, ["dateRange"]));
  }, [location.search]);

  // Function to handle changes in point of sale selection
  const onChangePoS = (value: number[] | undefined) => {
    queryParams.delete("pos");
    if (value)
      value.forEach((item) => {
        queryParams.append("pos", item.toString());
        if (value.length === 1) queryParams.append("pos", item.toString());
      });
    navigate({ search: queryParams.toString() });
  };

  // Function to handle changes in date range selection
  const onChangeDateRange = (
    value: {
      fromDate?: Date;
      toDate?: Date;
      value: DateRangeTypeValues;
    } | null
  ) => {
    queryParams.delete("dateRange");
    queryParams.delete("dateFrom");
    queryParams.delete("dateTo");
    if (!value) {
      navigate({ search: queryParams.toString() });
      return;
    }
    if (value.fromDate)
      queryParams.set("dateFrom", value.fromDate.toISOString());
    if (value.toDate) queryParams.set("dateTo", value.toDate.toISOString());
    queryParams.set("dateRange", value.value);

    navigate({ search: queryParams.toString() });
  };

  // Extract and format date range query parameters
  const dateRangeQueryParam = queryParams.get("dateRange");
  const validDateRangeValues = Object.values(DateRangeTypeValues);
  const selectedDateRangeValue =
    dateRangeQueryParam &&
    validDateRangeValues.includes(dateRangeQueryParam as DateRangeTypeValues)
      ? dateRangeQueryParam
      : DateRangeTypeValues.TODAY;
  const fromDate =
    selectedDateRangeValue === DateRangeTypeValues.BETWEEN
      ? queryParams.get("dateFrom") !== null
        ? new Date(queryParams.get("dateFrom") as string)
        : undefined
      : new Date(queryParams.get("dateFrom") || Date.now());
  const toDate =
    selectedDateRangeValue === DateRangeTypeValues.BETWEEN
      ? queryParams.get("dateTo") !== null
        ? new Date(queryParams.get("dateTo") as string)
        : undefined
      : new Date(queryParams.get("dateTo") || moment().toDate());

  // export data
  const { execute: exportArchiveStats, loading: exporting } = useBaseRequest(
    DocumentGateway.exportArchiveStats,
    {
      autoFetch: false,
      onCompleted: (exportData) => {
        const generateCsv = () => {
          const csvContent = "data:text/csv;charset=utf-8," + exportData;
          const link = document.createElement("a");
          link.setAttribute("href", encodeURI(csvContent));
          link.setAttribute(
            "download",
            `archive_export_${new Date().getTime()}.csv`
          );
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        generateCsv();
      },
    }
  );
  const exportAStats = () => {
    exportArchiveStats(
      removeQueryParams(location.search, ["skip", "limit", "dateRange"])
    );
  };
  // Return relevant values and functions for the component
  return {
    total,
    residential,
    business,
    loading,
    onChangePoS,
    onChangeDateRange,
    defaultPoS: queryParams.getAll("pos").map((item) => item),
    defaultDateRange: {
      value: selectedDateRangeValue as DateRangeTypeValues,
      fromDate,
      toDate,
    },
    exportAStats,
    exporting,
  };
};

export default useArchiveStats;
