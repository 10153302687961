import { IDocument } from "../../entities/IDocument";
import { IUser, UserRoles } from "../../entities/IUser";
import { DigitalSignatureRequestStatuses } from "../../entities/IDigitalSignature";
import { taskStatuses } from "../../entities/ITask";

export const useDocumentActions = () => {
  const getActions = (document: IDocument, user: IUser | null) => {
    const task = document.task;
    const actions: any[] = [];
    const canManageDocument = () => {
      if (
        user?.id === document?.createdBy ||
        user?.dealerCode === document?.dealerCode
      ) {
        return true;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR
      ) {
        return true;
      }
      if (task && task.userId && user?.id === task.userId) {
        return true;
      }

      return false;
    };
    if (task) {
      actions.push({
        label: "View Task",
        value: "view_task",
      });
    }
    if (document?.digitalSignRequest) {
      actions.push({
        label: "View Digital Signature",
        value: "view_digital_signature",
      });
    }
    if (canManageDocument()) {
      actions.push({ value: "edit_document", label: "Edit Document" });
      if (
        !task &&
        document?.digitalSignRequest?.status !==
          DigitalSignatureRequestStatuses.CONFIRMED &&
        document?.digitalSignRequest?.status !==
          DigitalSignatureRequestStatuses.PENDING
      ) {
        actions.push({ label: "Create Task", value: "create_task" });
      }
      if (
        !task &&
        !document?.digitalSignRequest &&
        document.files?.[0]?.mimeType === "application/pdf"
      ) {
        actions.push({
          value: "request_digital_signature",
          label: "Request Digital Signature",
        });
      }
    }
    const canDeleteDocument = () => {
      if (
        user?.id === document?.createdBy ||
        user?.dealerCode === document?.dealerCode
      ) {
        return true;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR
      ) {
        return true;
      }
      return false;
    };
    if (canDeleteDocument()) {
      actions.push({ value: "delete_document", label: "Delete Document" });
    }
    return actions;
  };
  return {
    getActions,
  };
};
