import {
  GenericForm,
  GenericFormState,
} from "../generic-form/GenericForm.types";
import { GenericFormValidator } from "../generic-form/utility/GenericFormValidator";
import { CreateTaskDTO } from "../../entities/ITask";

const customValidator = (
  value: string,
  field: string,
  state?: GenericFormState<CreateTaskDTO>
): { valid: boolean; error?: string } => {
  if (!state?.isComplaint?.value) {
    return { valid: true };
  }
  if (!value) {
    return { valid: false, error: `${field} is required.` };
  }
  return { valid: true };
};

export const createTaskForm: GenericForm<CreateTaskDTO> = {
  taskTypeCode: {
    type: "select",
    name: "taskTypeCode",
    label: "Billing cycle",
    defaultValue: "",
    options: [{ label: "Select a billing cycle", value: "" }],
    validators: [
      GenericFormValidator.required({
        error: "Select a billing cycle.",
      }),
    ],
  },
  documentId: {
    type: "hidden",
    visibleIf: () => false,
    name: "documentId",
    label: "Task is assignable",
    defaultValue: -1,
    validators: [],
  },
  basketId: {
    type: "select",
    name: "basketId",
    label: "Basket",
    defaultValue: "",
    options: [{ label: "Select a basket", value: "" }],
    validators: [
      GenericFormValidator.required({
        error: "Select a basket.",
      }),
    ],
  },
  description: {
    type: "textarea",
    name: "description",
    label: "Description",
    defaultValue: "",
  },
  customerName: {
    type: "input",
    name: "customerName",
    label: "Customer Name",
    defaultValue: "",
  },
  isComplaint: {
    type: "checkbox",
    name: "isComplaint",
    label: "Is Complaint",
    defaultValue: false as any,
  },
  complaintMSISDN: {
    type: "input",
    name: "complaintMSISDN",
    label: "Complaint MSISDN",
    defaultValue: "",
    visibleIf: (state) => !!state.isComplaint.value,
    validators: [
      GenericFormValidator.regex({
        pattern: /^\+3816\d{7,8}$/,
        error: "Complaint MSISDN must be in the format +38106xxxxxx",
      }),
      GenericFormValidator.customValidator(customValidator),
    ],
  },
};

export interface CreateTaskModalProps {
  onDismiss: () => void;
  onSubmit: (data: CreateTaskDTO) => void;
  creating: boolean;
  isVisible: boolean;
  createTaskForm: GenericForm<CreateTaskDTO>;
}
