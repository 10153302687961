import { BadgeProps } from "./Badge.types";

export function Badge({
  type,
  className,
  title,
  children,
  variant = "filled",
}: BadgeProps) {
  return (
    <span
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title={title}
      className={`badge border border-${type} border-2 ${
        variant === "filled" ? `bg-${type}` : `text-${type}`
      }${className ? " " + className : ""} `}
    >
      {children}
    </span>
  );
}
