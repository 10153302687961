import { Loader } from "../../modules/loader";
import { CheckBox } from "../checkbox";
import { TableProps, itemsPerPageOptions } from "./Table.types";
import { useTable } from "./useTable";
import "./Table.style.css";
import { Icon } from "../../modules/icon";

export function Table({
  headers,
  data,
  pagination = {
    totalItems: 10,
    initialItemsPerPage: itemsPerPageOptions[0]

  },
  selection = {
    selected: [],
    onSelectionChanged: () => [],
  },
  loading,
  sort,
  onRowPress,
}: TableProps) {
  const {
    onMainCheckBoxChanged,
    onRowCheckBoxChanged,
    onItemsPerPageChange,
    itemsPerPage,
    currentPage,
    numberOfPages,
    setPage,
  } = useTable(data, selection, pagination);
  return (
    <div className="position-relative">
      {loading ? (
        <div
          className="position-absolute h-100 w-100 d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundColor: "rgba(255,255,255,0.8)",
          }}
        >
          <Loader color="black" />
        </div>
      ) : null}
      <div className="overflow-auto">
        <table className="table table-striped">
          <thead>
            <tr>
              {selection ? (
                <th>
                  <CheckBox
                    className="form-check-input cursor-pointer"
                    intermediate={
                      !!selection?.selected?.length &&
                      selection?.selected.length < data.length
                    }
                    checked={
                      selection?.selected?.length === data.length &&
                      data.length > 0
                    }
                    onChange={onMainCheckBoxChanged}
                  />
                </th>
              ) : null}
              {headers.map((header) => (
                <th scope="col" key={header.key}>
                  <span className="d-flex flex-row align-items-end">
                    <span>{header.name}</span>
                    {header.sortable ? (
                      <span className="ml-2 d-flex flex-row mb-1">
                        <Icon
                          type="fa-solid fa-arrow-up"
                          onClick={() => sort?.onSort(header.key, "ASC")}
                          title="Sort ascending"
                        />
                        <Icon
                          type="fa-solid fa-arrow-down"
                          className="ml-1"
                          onClick={() => sort?.onSort(header.key, "DESC")}
                          title="Sort descending"
                        />
                      </span>
                    ) : null}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {selection ? (
                  <td>
                    <CheckBox
                      className="form-check-input cursor-pointer"
                      checked={
                        !!selection?.selected?.find((sr) => sr === row[0])
                      }
                      onChange={(event) =>
                        onRowCheckBoxChanged(event, row[0] as number)
                      }
                    />
                  </td>
                ) : null}
                <td
                  className={
                    onRowPress
                      ? "cursor-pointer text-primary td-pressable"
                      : undefined
                  }
                  onClick={
                    onRowPress ? () => onRowPress(row[0] as number) : undefined
                  }
                >
                  {row[0]}
                </td>
                {row.map((value, index) =>
                  index === 0 ? null : <td key={index}>{value}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="width-100">
        {pagination ? (
          <>
            <div className="w-100 d-flex flex-row align-items-center justify-content-between my-2">
              <div>
                <span>Rows per page </span>
                <select value={itemsPerPage} onChange={onItemsPerPageChange}>
                  {itemsPerPageOptions.map((ip, i) => (
                    <option value={ip} key={i}>
                      {ip}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                {/* Go to first */}
                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer mr-2"
                  onClick={() => setPage(1)}
                >
                  &laquo;
                </span>

                {/* Go to previous */}
                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer"
                  onClick={() => setPage(Math.max(currentPage - 1, 1))}
                >
                  &lsaquo;
                </span>

                {/* current */}
                <span aria-hidden="true" className="mx-3">
                  {currentPage === 1
                    ? pagination.totalItems
                      ? currentPage
                      : pagination.totalItems
                    : (currentPage - 1) * itemsPerPage + 1}{" "}
                  -{" "}
                  {Math.min(itemsPerPage * currentPage, pagination.totalItems)}
                  &nbsp; of &nbsp;
                  {Math.min(
                    numberOfPages * itemsPerPage,
                    pagination.totalItems
                  )}
                </span>

                {/* Go to next */}
                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer"
                  onClick={() =>
                    setPage(Math.min(currentPage + 1, numberOfPages))
                  }
                >
                  &rsaquo;
                </span>

                {/* Go to last */}

                <span
                  aria-hidden="true"
                  className="font-24 cursor-pointer ml-2"
                  onClick={() => setPage(numberOfPages)}
                >
                  &raquo;
                </span>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
