import { GetBasketRulesDTO, IGetBasketRulesApi } from "../../entities/IBasket";
import { CreateBasketRuleDTO } from "../../screens/basket-rules/BasketRules.types";
import {
  CreateBasketDTO,
  IGetBasketApi,
  UpdateBasketDTO,
} from "../../screens/baskets/Basket.types";
import BaseInstance from "../BaseInstance";
import { createFilterQueryWithObjectKeys } from "../createFilterQueryWithObjectKeys";

const BasketGateWay = {
  createBasket: async (payload: CreateBasketDTO) => {
    return BaseInstance.post("/baskets", payload);
  },
  getBaskets: async (): Promise<IGetBasketApi> => {
    return BaseInstance.get("/baskets?limit=1000");
  },
  updateBasket: async (payload: UpdateBasketDTO) => {
    return BaseInstance.put(`/baskets/${payload.id}`, {
      name: payload.name,
      isAssignable: payload.isAssignable,
      documentTypeCodesForBasketRules: payload.documentTypeCodesForBasketRules,
    });
  },
  deleteBasket: async (id: number) => {
    return BaseInstance.delete(`/baskets/${id}`);
  },
  bookmarkBasket: async (id: number) => {
    return BaseInstance.post(`/baskets/${id}/bookmark`);
  },
  unbookmarkBasket: async (id: number) => {
    return BaseInstance.delete(`/baskets/${id}/bookmark`);
  },
  getBasketRules: async (
    payload: GetBasketRulesDTO
  ): Promise<IGetBasketRulesApi> => {
    return BaseInstance.get(
      `/basket-rules${createFilterQueryWithObjectKeys(payload.filters)}`
    );
  },
  createBasketRule: async (payload: CreateBasketRuleDTO) => {
    return BaseInstance.post("/basket-rules", payload);
  },
  deleteBasketRule: async (id: number) => {
    return BaseInstance.delete(`/basket-rules/${id}`);
  },
};

export default BasketGateWay;
