import { useNavigate } from "react-router-dom";
import { Icon } from "../../modules/icon";
import "./BackButton.style.css";

const BackButton = ({ onClick }: { onClick?: (() => void) | boolean }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
          return;
        }
        navigate(-1);
      }}
      className="back-button"
    >
      <Icon size={16} type={"fa-solid fa-chevron-left"} title="Go back" />
    </div>
  );
};

export default BackButton;
