import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { useToast } from "../../components/toast";
import { IDocument } from "../../entities/IDocument";
import { MetaDataForm } from "../../entities/IMetaData";
import { useWindowDimensions } from "../../modules/window";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";
import { TASK_SUCCESS_MESSAGES } from "../../entities/ITask";
import TaskGateway from "../../api/gateways/TaskGateway";
import { createTaskForm as createTaskFormDefault } from "../../components/create-task/CreateTaskModal.types";
import BasketGateWay from "../../api/gateways/BasketGateway";
import OptionListGateway from "../../api/gateways/OptionListGateway";
import { useCounterService } from "../../services/useCounterService";
import { useUser } from "../../store/auth/AuthSelectors";
import { requestDigitalSignatureForm as requestDigitalSignatureFormDefault } from "../../components/request-digital-signature";
import DigitalSignatureGateway from "../../api/gateways/DigitalSignatureGateways";
import { DocumentActionsTypes } from "./Document.types";

export function useDocument(id: string) {
  const [metaDataForm, setMetaDataForm] =
    React.useState<GenericForm<MetaDataForm> | null>(null);
  const [docFile, setDocFile] = React.useState<{
    uri: string;
    type: string;
    name: string;
    file: File | null;
    edited: boolean;
  } | null>(null);

  // states
  const { height: windowHeight } = useWindowDimensions();
  const [showDismissModal, setShowDismissModal] = React.useState(false);
  const navigate = useNavigate();
  const { success, danger } = useToast();
  const [task, setTask] = useState<IDocument["task"]>();
  const user = useUser();

  // requests
  const { mapMetaDataRulesToForm, getDocFileUrl } = useDocumentService();

  const {
    data,
    loading,
    execute: fetch,
  } = useBaseRequest(DocumentGateway.getDocumentByIdAndMetaData, {
    initialPayload: { id },
    autoFetch: true,
    onCompleted: (data) => {
      if (!data) return;
      if (data.metaDataRules) {
        const form = mapMetaDataRulesToForm(
          data.metaDataRules,
          data.document.metaData
        );
        setMetaDataForm(form);
      }
      setDefaultFile(data.document);
      setTask(data.document.task);
      getRecommendedBaskets({
        filters: {
          limit: 1000,
          filters: [{ name: "docTypeCode", value: data.document.docTypeCode }],
        },
      });
    },
  });

  const { execute: update, loading: updating } = useBaseRequest(
    DocumentGateway.updateDocument,
    {
      onCompleted: (data, payload) => {
        fetch({ id: payload.id });
        onClearAction();
        success("Document updated successfully.");
      },
    }
  );

  const { execute: dismiss } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {
      refreshTaskCounters();
      closeModal();
      navigate(screens.documents);
      success("Document deleted successfully.");
    },
  });

  const { execute: getAllBaskets, data: allBaskets } = useBaseRequest(
    OptionListGateway.getOptions,
    {
      autoFetch: true,
      initialPayload: {
        textSearch: "",
        skip: 0,
        limit: 1000,
        endpoint: "/baskets/search-autocomplete",
      },

      onCompleted: (data) => {
        if (!data) return;
      },
    }
  );

  const { execute: getRecommendedBaskets, data: recommendedBaskets } =
    useBaseRequest(BasketGateWay.getBasketRules, {
      onCompleted: (data) => {
        if (!data) return;
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    });
  const { data: accountingPeriods } = useBaseRequest(
    DocumentGateway.getAccountingPeriodsByDocId,
    {
      autoFetch: true,
      initialPayload: id,
    }
  );

  // handlers

  const onEditCancel = () => {
    onClearAction();
    setDefaultFile(data?.document);
  };

  const setDefaultFile = (doc?: IDocument) => {
    const file =
      doc?.files && doc.files.length ? doc.files[doc.files.length - 1] : null;
    if (!file) {
      setDocFile(null);
      return;
    }
    const fileUrl = getDocFileUrl(file.url);
    setDocFile({
      uri: fileUrl,
      type: file.mimeType || "",
      name: file.name,
      file: null,
      edited: false,
    });
  };

  const onFileEditCancel = () => setDefaultFile(data?.document);
  const onFileEdit = (newFile: File) => {
    setDocFile({
      edited: true,
      type: newFile.type,
      name: newFile.name,
      file: newFile,
      uri: URL.createObjectURL(newFile),
    });
  };

  const onUpdateDoc = (metaData: MetaDataForm) => {
    const params: any = {
      id: data?.document?.id?.toString(),
      metaData,
    };
    if (!!docFile && docFile.file && docFile.edited) {
      params.file = docFile.file;
    }
    update(params);
  };

  const closeModal = () => setShowDismissModal(false);
  const openModal = () => setShowDismissModal(true);

  const onDismiss = () => {
    dismiss({ id: data?.document.id?.toString() || "" });
  };

  const formFields = useMemo(() => {
    if (!metaDataForm) return [];
    return Object.keys(metaDataForm).map((key: any) => {
      const field = metaDataForm[key];
      return {
        label: field.label || "",
        value: field.defaultValue.toString(),
        type: field.type,
      };
    });
  }, [metaDataForm]);

  //Create Task
  const { refreshTaskCounters } = useCounterService();
  const { execute: createTask, loading: creatingTask } = useBaseRequest(
    TaskGateway.createTask,
    {
      onCompleted: (data) => {
        fetch({ id });
        refreshTaskCounters();
        success(TASK_SUCCESS_MESSAGES.created);
        setSelectedAction(undefined);
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const createTaskForm = useMemo(() => {
    return {
      ...createTaskFormDefault,
      documentId: {
        ...createTaskFormDefault.documentId,
        defaultValue: data?.document.id || -1,
      },
      taskTypeCode: {
        ...createTaskFormDefault.taskTypeCode,
        defaultValue:
          accountingPeriods &&
          accountingPeriods?.filter(
            (item) =>
              item.group.documentCategory ===
              data?.document?.metaData?.documentCategory
          )?.length > 1
            ? ("" as any)
            : accountingPeriods?.filter(
                (item) =>
                  item.group.documentCategory ===
                  data?.document?.metaData?.documentCategory
              )[0]?.code,
        disabledIf() {
          return !(accountingPeriods && accountingPeriods?.length > 1);
        },
        options: [
          { label: "Select a billing cycle", value: "" },
          ...(accountingPeriods
            ?.filter(
              (item) =>
                item.group.documentCategory ===
                data?.document?.metaData?.documentCategory
            )
            ?.map((item) => ({ value: item.code, label: item.title })) || []),
        ],
      },
      basketId: {
        ...createTaskFormDefault.basketId,
        options: [
          { label: "Select a basket", value: "" },
          ...(recommendedBaskets?.basketRules?.length
            ? recommendedBaskets?.basketRules.map((item) => ({
                label: item.basket.name,
                value: item.basket.id.toString(),
              }))
            : allBaskets?.data?.map((item) => ({
                label: item.label,
                value: item.value.toString(),
              })) || []),
        ],
      },
      customerName: {
        ...createTaskFormDefault.customerName,
        visibleIf() {
          return data?.document?.metaData?.documentCategory === "Business";
        },
      },
      isComplaint: {
        ...createTaskFormDefault.isComplaint,
        visibleIf() {
          return data?.document?.metaData?.documentType === "Complaint";
        },
      },
      complaintMSISDN: {
        ...createTaskFormDefault.complaintMSISDN,
        defaultValue: new RegExp(/^06\d{7,8}$/).test(
          data?.document?.metaData?.msisdn || ""
        )
          ? data?.document?.metaData?.msisdn?.replace("0", "+381") || ""
          : "",
      },
    };
  }, [
    data?.document.id,
    data?.document.metaData?.documentCategory,
    accountingPeriods,
    recommendedBaskets?.basketRules,
    allBaskets?.data,
  ]);

  //Digital Signature
  const requestDigitalSignatureForm = useMemo(() => {
    return {
      ...requestDigitalSignatureFormDefault,
      documentId: {
        ...requestDigitalSignatureFormDefault.documentId,
        defaultValue: data?.document.id || -1,
      },
    };
  }, [data?.document]);

  const {
    execute: requestDigitalSignature,
    loading: requestingDigitalSignature,
  } = useBaseRequest(DigitalSignatureGateway.createDigitalSignatureRequest, {
    onCompleted: (data) => {
      fetch({ id });
      success("Request for Digital Signature is successfully sent.");
      setSelectedAction(undefined);
    },
    onError: (error) => {
      danger(error?.message || "Something went wrong.");
    },
  });

  const digitalSignatureFields = useMemo(() => {
    if (!data?.document.digitalSignRequest) return [];
    const fields = data?.document.digitalSignRequest;

    return [
      {
        label: "Customer Email",
        value: fields.email,
      },
      {
        label: "Status",
        value: fields.status[0].toUpperCase() + fields.status.slice(1),
      },
      ...(fields.confirmedAt
        ? [
            {
              label: "Confirmed At",
              value: fields.confirmedAt,
              type: "date",
            },
          ]
        : []),
      ...(fields.signedAt
        ? [
            {
              label: "Signed At",
              value: fields.signedAt,
              type: "date",
            },
          ]
        : []),
    ];
  }, [metaDataForm]);

  const [selectedAction, setSelectedAction] = useState<string | undefined>();
  const onActionSelected = (action: string) => {
    const task = data?.document.task;
    if (action === DocumentActionsTypes.VIEW_TASK && task) {
      navigate(screens.task.root.replace(":id", task.id.toString()));
      return;
    }
    setSelectedAction(action);
  };
  const onClearAction = () => {
    setSelectedAction(undefined);
  };
  const { execute: deleteDigitalSignatureRequest, loading: deletingSignature } =
    useBaseRequest(DigitalSignatureGateway.deleteDigitalSignatureRequest, {
      onCompleted: (data) => {
        success("Digital Signature Request deleted successfully.");
        closeDeleteModal(true);
        fetch({ id });
      },
    });
  const [showDeleteSignatureModal, setShowDeletesignatureModal] =
    useState(false);

  const closeDeleteModal = (dontShowDigitalSignature?: boolean) => {
    if (dontShowDigitalSignature === undefined || !dontShowDigitalSignature) {
      setSelectedAction(DocumentActionsTypes.VIEW_DIGITAL_SIGNATURE);
    }
    setShowDeletesignatureModal(false);
  };
  const onStartDelete = () => {
    setShowDeletesignatureModal(true);
    setSelectedAction(undefined);
  };
  const onDelete = () => {
    data?.document.digitalSignRequest?.id &&
      deleteDigitalSignatureRequest(data?.document.digitalSignRequest?.id);
  };
  return {
    document: data?.document,
    metaDataForm,
    loading,
    windowHeight,
    onUpdateDoc,
    onEditCancel,
    docFile,
    onFileEdit,
    onFileEditCancel,
    showDismissModal,
    closeModal,
    openModal,
    onDismiss,
    updating,
    createTaskForm,
    onCreateTask: createTask,
    creatingTask,
    isTaskcreated: !!task,
    user,
    formFields,
    isDigitalSignatureRequested: !!data?.document.digitalSignRequest,
    onRequestDigitalSignature: requestDigitalSignature,
    requestingDigitalSignature,
    requestDigitalSignatureForm,
    digitalSignatureFields,
    onActionSelected,
    selectedAction,
    onClearAction,
    onStartDelete,
    onDelete,
    deletingSignature,
    closeDeleteModal,
    showDeleteSignatureModal,
    onResend: () => {
      /* ADD FN */
    },
  };
}
