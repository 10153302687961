export const calculateCardWidth = (
  width: number,
  maxCardNumber: number,
  minCardWidth: number,
  maxCardWidth: number | undefined,
  cardGap: number,
  paddingX: number
) => {
  if (!width || isNaN(width)) {
    return `${minCardWidth}px`;
  }

  let cardNumber = maxCardNumber;

  let widthInPx = width - (cardNumber - 1) * cardGap - paddingX;

  while (widthInPx / cardNumber > (maxCardWidth || Number.MAX_SAFE_INTEGER)) {
    cardNumber++;
    widthInPx = width - (cardNumber - 1) * cardGap - paddingX;
    if (cardNumber === 0) {
      break;
    }
  }

  while (widthInPx / cardNumber < minCardWidth) {
    cardNumber--;
    if (cardNumber === 0) {
      break;
    }
    widthInPx = width - (cardNumber - 1) * cardGap - paddingX;
  }

  const cardWidth = widthInPx / cardNumber - 0.1;
  return `${cardWidth.toFixed(2)}px`;
};
