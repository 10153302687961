import { FiltersWithQuery } from "../../components/filters/FiltersWithQuery";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";

import {
  SearchInput,
  SearchWithQueryInput,
} from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import Toggle from "../../components/toggle/Toggle";
import { Loader } from "../../modules/loader";
import { archiveFilters } from "./Archive.types";
import { useArchive } from "./useArchive";

export function ArchiveScreen() {
  const {
    docTableData,
    searchByIdMode,
    searchId,
    count,
    archiveTableHeaders,
    loading,
    loadingDocument,
    initLoading,
    idSearchTableData,
    minTextError,
    idSearchError,
    noDataMessage,
    noDocumentByIDDataMessage,
    onRowPress,
    toggleSearchByIdMode,
    handleSearchId,
    handleSearchQuery,
  } = useArchive();

  return (
    <PageLayout title="Archive">
      <Row>
        <Column>
          <div className="mt-4 mb-1 d-flex flex-column align-items-start gap-4">
            <div className="d-flex flex-row align-items-center  my-1 gap-2">
              <p className="mb-0 mt-0">ID lookup</p>
              <Toggle on={searchByIdMode} onChange={toggleSearchByIdMode} />
            </div>
            {searchByIdMode && (
              <div
                className="d-flex flex-row my-1"
                style={{
                  position: "relative",
                }}
              >
                <SearchInput
                  placeHolder="Enter ID..."
                  className="ml-auto"
                  onSearchSubmit={handleSearchId}
                  defaultValue={searchId}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "-45px",
                    right: "5px",
                  }}
                >
                  <p className="text-danger">
                    {idSearchError && "Only numbers are allowed"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </Column>
      </Row>
      {!searchByIdMode && (
        <Row>
          <Column>
            <div className="mt-4 mb-1">
              <FiltersWithQuery filters={archiveFilters} />
            </div>
          </Column>
          <Column>
            <div className="mt-4 mb-1">
              <div
                className="d-flex flex-row my-1"
                style={{
                  position: "relative",
                }}
              >
                <SearchWithQueryInput
                  placeHolder="Search documents..."
                  className="ml-auto"
                  validate={handleSearchQuery}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "-45px",
                    right: "5px",
                  }}
                >
                  <p className="text-danger">
                    {minTextError && "Enter at least 6 characters."}
                  </p>
                </div>
              </div>
            </div>
          </Column>
        </Row>
      )}
      <Row>
        <Column>
          <div className="my-2 mt-4">
            {searchByIdMode ? (
              idSearchTableData.length ? (
                <TableWithQueryPaginationAndSort
                  headers={archiveTableHeaders}
                  data={idSearchTableData}
                  totalItems={count}
                  onRowPress={onRowPress}
                  selection={false}
                  loading={loadingDocument}
                />
              ) : initLoading ? (
                <div className="w-100 flex-row justify-content-center">
                  <Loader size="m" color="black" />
                </div>
              ) : noDocumentByIDDataMessage ? (
                <div className="d-flex align-items-center justify-content-center pt-4">
                  <p className="h4 text-secondary">No documents found.</p>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center pt-4">
                  <p className="h4 text-secondary">
                    Enter ID to find document...
                  </p>
                </div>
              )
            ) : docTableData.length ? (
              <TableWithQueryPaginationAndSort
                headers={archiveTableHeaders}
                data={docTableData}
                totalItems={count}
                onRowPress={onRowPress}
                selection={false}
                loading={loading}
              />
            ) : initLoading ? (
              <div className="w-100 flex-row justify-content-center">
                <Loader size="m" color="black" />
              </div>
            ) : noDataMessage ? (
              <div className="d-flex align-items-center justify-content-center pt-4">
                <p className="h4 text-secondary">No documents found.</p>
              </div>
            ) : (
              <div className="d-flex  align-items-center justify-content-center pt-4">
                <p className="h4 text-secondary">
                  Search or/and add filters to find documents...
                </p>
              </div>
            )}
          </div>
        </Column>
      </Row>
    </PageLayout>
  );
}
