import { IDocument } from "../../../entities/IDocument";

export const getPosName = (document?: IDocument) => {
  return !document
    ? ""
    : document.dealer
    ? `${document.dealer.name} (${document.dealerCode})`
    : document.dealerCode && document.dealerCode != document.createdById.toString()
    ? document.dealerCode
    : "";
};
