import { Modal } from "react-bootstrap";
import { DismissDraftDocumentModalProps } from "../upload-document/UploadDocument.types";

export function DismissUploadDocumentModal({
  show,
  onCancel,
  onDismiss,
}: DismissDraftDocumentModalProps) {
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Dismiss document
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to dismiss this document? This action is
        irreversible.
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button type="button" className="btn btn-danger" onClick={onDismiss}>
          Dismiss
        </button>
      </Modal.Footer>
    </Modal>
  );
}
