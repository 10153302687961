import { Column, Container } from "../grid";
import { PageLayoutProps } from "./PageLayout.types";
import { Loader } from "../../modules/loader";
import BackButton from "../back-button/BackButton";
import "./PageLayout.styles.css";

export function PageLayout({
  title,
  children,
  fullScreen = true,
  titleRight,
  loading,
  badge,
  backBtn = false,
}: PageLayoutProps) {
  return (
    <div
      className="w100dis"
      style={{
        height: "calc(100vh - 65px)",
        overflow: "auto",
      }}
    >
      {(title || titleRight) && (
        <div className="page-header">
          <div className="flex-1">
            {title ? (
              <h3
                className="flex-row gap-2 mb-0"
                style={{
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {backBtn ? <BackButton onClick={backBtn} /> : null}

                {title}

                {badge ? badge : null}
              </h3>
            ) : null}
          </div>
          {titleRight ? <div className="flex-1">{titleRight}</div> : null}
        </div>
      )}
      <Container fluid={fullScreen}>
        <Column className="col p-0 p-4 pt-4">
          {loading ? (
            <div className="text-center mt-5">
              <Loader color="black" size="m" />
            </div>
          ) : (
            children
          )}
        </Column>
      </Container>
    </div>
  );
}
