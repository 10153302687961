import { Modal } from "react-bootstrap";
import { Button } from "../../components/button";
import { TaskUnassignModalProps } from "./Task.types";

export function TaskUnassignModal({
  show,
  onSubmit,
  onCancel,
  unassigning,
  task,
  user
}: TaskUnassignModalProps) {
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Unassign from task
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to unassign {task?.assignee?.id === user?.id ? "yourself" : task?.assignee?.name} from task?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          isLoading={unassigning}
          className="btn btn-primary"
          onClick={onSubmit}
          disabled={unassigning}
        >
          Yes
        </Button>
        <Button
          type="button"
          className="btn btn-secondary"
          onClick={onCancel}
          disabled={unassigning}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
