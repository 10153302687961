import { Modal } from "react-bootstrap";
import { TaskReassignModalProps } from "./Task.types";
import { Button } from "../../components/button";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";
import { IOption } from "../../components/select";
import { useState } from "react";
import { UserRoles } from "../../entities/IUser";

export function TaskReassignModal({
  show,
  onSubmit,
  onCancel,
  reassigning,
  currentAssigneeId,
  task,
  user,
}: TaskReassignModalProps) {
  const [selectedUser, setSelectedUser] = useState<IOption<number> | undefined>(
    undefined
  );
  const [assignOption, setAssignOption] = useState("");
  const changeAssignOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedUser(
      value === "self"
        ? { label: user?.name || "", value: user?.id || 0 }
        : undefined
    );
    setAssignOption(value);
    setErrMsg("");
  };
  const [errMsg, setErrMsg] = useState<string>("");
  return (
    <Modal
      show={show}
      animation
      onHide={() => {
        onCancel();
        setErrMsg("");
        setAssignOption("");
        setSelectedUser(undefined);
      }}
    >
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {task.assignee ? "Reassign" : "Assign"} Task
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      {user?.role &&
      [UserRoles.ADMIN, UserRoles.SUPERVISOR, UserRoles.BO_EXPERT].includes(
        user?.role
      ) ? (
        <Modal.Body>
          <p>
            {task?.assignee?.id === user?.id
              ? "Find user by name or dealer code to assign task to"
              : "Assign yourself to task or find user by name or dealer code to assign the task to"}
          </p>
          {task?.assignee?.id === user?.id ? null : (
            <>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={"self"}
                  checked={assignOption === "self"}
                  id="assignOption"
                  name="assignOption"
                  title="Assign to me"
                  onChange={changeAssignOption}
                />
                <label className="form-check-label">Assign to me</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={"other"}
                  checked={assignOption === "other"}
                  id="assignOption"
                  name="assignOption"
                  title="Assign to other"
                  onChange={changeAssignOption}
                />
                <label className="form-check-label">Assign to other</label>
              </div>
            </>
          )}
          {task?.assignee?.id === user?.id || assignOption === "other" ? (
            <AutocompleteOpt
              excluded={currentAssigneeId ? [currentAssigneeId] : undefined}
              onSelectOption={(user) => {
                !Array.isArray(user) && setSelectedUser(user);
                setErrMsg("");
              }}
              endpoint="/users/search-autocomplete"
            />
          ) : null}
          <p className={"text-danger mt-2"}>{errMsg}</p>
          <div className="flex-row">
            <Button
              isLoading={reassigning}
              loader={{ size: "s" }}
              className="btn btn-primary mt-3 w-100"
              onClick={() => {
                typeof selectedUser !== "undefined"
                  ? onSubmit(selectedUser.value)
                  : setErrMsg("User must be chosen.");
              }}
            >
              Confirm {task.assignee ? "reassigning" : "assigning"} task
            </Button>
          </div>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <p>Are you sure you want to assign to this task?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              isLoading={reassigning}
              className="btn btn-primary"
              onClick={() => {
                setSelectedUser({
                  label: user?.name || "",
                  value: user?.id || 0,
                });
                onSubmit(user?.id || 0);
              }}
              disabled={reassigning}
            >
              Yes
            </Button>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
              disabled={reassigning}
            >
              No
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
