import { IOption } from "../../components/select";
import { ITask, TaskStatuses } from "../../entities/ITask";
import { IUser, UserRoles } from "../../entities/IUser";

export function useTaskActions() {
  const getActions = (task: ITask, user: IUser | null): IOption<string>[] => {
    const actions: IOption<string>[] = [];
    taskActions.forEach((callAction) => {
      const ta = callAction(task, user);
      if (!ta) return;
      actions.push(ta);
    });
    return actions;
  };

  const taskActions: Array<
    (task: ITask, user: IUser | null) => IOption<string> | null
  > = [
    (task, user) => {
      const action = { label: "", value: "assigned" };
      if (task.status === TaskStatuses.OPEN) {
        action.label = "Assign";
        return action;
      }
      if (
        task.status === TaskStatuses.CANCELED ||
        task.status === TaskStatuses.RESOLVED
      ) {
        return null;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        user?.role === UserRoles.BO_EXPERT
      ) {
        action.label = "Reassign";
        return action;
      }
      return null;
    },
    (task, user) => {
      const action = { label: "Resolve", value: "resolved" };
      if (
        [TaskStatuses.INPROGRESS, TaskStatuses.ONHOLD].indexOf(task.status) < 0
      ) {
        return null;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task.assignee?.id === user?.id
      ) {
        return action;
      }
      return null;
    },
    (task, user) => {
      const action = { label: "Put on Hold", value: "onhold" };
      if (
        [TaskStatuses.INPROGRESS, TaskStatuses.ASSIGNED].indexOf(task.status) <
        0
      ) {
        return null;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task.assignee?.id === user?.id
      ) {
        return action;
      }
      return null;
    },
    (task, user) => {
      if (!task.assignee) {
        return null;
      }
      const action = { label: "Activate", value: "inprogress" };
      if (
        [
          TaskStatuses.ONHOLD,
          TaskStatuses.ASSIGNED,
          TaskStatuses.CANCELED,
          TaskStatuses.RESOLVED,
        ].indexOf(task.status) < 0
      ) {
        return null;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task.assignee?.id === user?.id
      ) {
        return action;
      }
      return null;
    },
    (task, user) => {
      const action = { label: "Move to another basket", value: "changeBasket" };
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        user?.role === UserRoles.BO_EXPERT ||
        task.assignee?.id === user?.id
      ) {
        return action;
      }
      return null;
    },
    (task, user) => {
      if (!task.assignee) {
        return null;
      }
      const action = { label: "", value: "unassigned" };
      if (task.assignee?.id === user?.id) {
        action.label = "Unassign from task";
        return action;
      }
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR
      ) {
        action.label = "Remove user from task";
        return action;
      }
      return null;
    },
    (task, user) => {
      if (task.status === TaskStatuses.CANCELED) {
        return null;
      }
      const action = { label: "Cancel", value: "canceled" };
      if (
        user?.role === UserRoles.ADMIN ||
        user?.role === UserRoles.SUPERVISOR ||
        task.assignee?.id === user?.id
      ) {
        return action;
      }
      return null;
    },
  ];

  return { getActions };
}
