import { Modal } from "react-bootstrap";
import { ViewPageModalProps } from "./SplitFile.types";
import { useState, useEffect } from "react";
import { Loader } from "../../modules/loader";

export function ViewPageModal({ show, onClose, page }: ViewPageModalProps) {
  const [image, setImage] = useState<string>("");

  useEffect(() => {
    const getImage = async () => {
      // Override image with "" if another one was previously opened
      setImage("");
      if (page) {
        const image = await page.imagePromise;
        setImage(image);
      }
    };
    getImage();
  }, [page]);

  return (
    <Modal size="lg" show={show} animation onHide={onClose}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          {page && `Page ${page.pageNumber}`}
        </h5>
        <div onClick={onClose}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 h-100 flex-row align-items-center justify-content-center">
          {image ? (
            <img src={image} className="w-100" alt={page && `Page ${page.pageNumber}`} />
          ) : (
            <Loader color="black" />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
