import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { useToast } from "../../components/toast";
import { IDocument } from "../../entities/IDocument";
import { MetaDataForm } from "../../entities/IMetaData";
import { useWindowDimensions } from "../../modules/window";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../../screens/Navigation.types";
import { useCounterService } from "../../services/useCounterService";
import { useUser } from "../../store/auth/AuthSelectors";
import { UserRoles } from "../../entities/IUser";

export function useDocumentForm(id: string, onDocUpdate: () => any) {
  const [metaDataForm, setMetaDataForm] =
    React.useState<GenericForm<MetaDataForm> | null>(null);
  const [docFile, setDocFile] = React.useState<{
    uri: string;
    type: string;
    name: string;
    file: File | null;
    edited: boolean;
  } | null>(null);

  // states
  const [editing, setEditing] = React.useState(false);
  const { height: windowHeight } = useWindowDimensions();
  const [showDismissModal, setShowDismissModal] = React.useState(false);
  const navigate = useNavigate();
  const { success } = useToast();
  const [task, setTask] = useState<IDocument["task"]>();
  const user = useUser();

  // requests
  const {
    mapMetaDataRulesToForm,
    getDocFileUrl,
    useGetDocumentTypes,
    useGetMetaDataForDocType,
  } = useDocumentService();
  const { documentTypes } = useGetDocumentTypes();
  const [documentType, setDocumentType] = React.useState("");

  const { getMetaDataForDocType, loading: loadingMetaDataForm } =
    useGetMetaDataForDocType({
      initialPayload: { code: documentType },
      autoFetch: !!documentType,
      onCompleted: (dataRules) => {
        if (!dataRules) return;
        const form = mapMetaDataRulesToForm(
          dataRules,
          data?.document?.metaData
        );

        setMetaDataForm({
          ...form,
        });
      },
    });

  const {
    data,
    loading,
    execute: fetch,
  } = useBaseRequest(DocumentGateway.getDocumentByIdAndMetaData, {
    initialPayload: { id },
    autoFetch: true,
    onCompleted: (data) => {
      if (!data) return;
      if (data.metaDataRules) {
        const form = mapMetaDataRulesToForm(
          data.metaDataRules,
          data.document.metaData
        );
        setMetaDataForm({
          ...form,
        });
      }
      setDefaultFile(data.document);
      setTask(data.document.task);
    },
  });

  const { execute: update, loading: updating } = useBaseRequest(
    DocumentGateway.updateDocument,
    {
      onCompleted: (data, payload) => {
        fetch({ id: payload.id });
        setEditing(false);
        onDocUpdate();
        success("Document updated successfully.");
      },
    }
  );

  const { execute: dismiss } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {
      refreshTaskCounters();
      closeModal();
      navigate(screens.documents);
      success("Document deleted successfully.");
    },
  });

  // handlers
  const onEdit = () => {
    setEditing(true);
  };

  const onEditCancel = () => {
    setEditing(false);
    setDefaultFile(data?.document);
    setDocumentType(data?.document?.docTypeCode || "");
    getMetaDataForDocType({ code: data?.document?.docTypeCode || "" });
  };

  const setDefaultFile = (doc?: IDocument) => {
    const file =
      doc?.files && doc.files.length ? doc.files[doc.files.length - 1] : null;
    if (!file) {
      setDocFile(null);
      return;
    }
    const fileUrl = getDocFileUrl(file.url);
    setDocFile({
      uri: fileUrl,
      type: file.mimeType || "",
      name: file.name,
      file: null,
      edited: false,
    });
  };

  const onFileEditCancel = () => setDefaultFile(data?.document);
  const onFileEdit = (newFile: File) => {
    setDocFile({
      edited: true,
      type: newFile.type,
      name: newFile.name,
      file: newFile,
      uri: URL.createObjectURL(newFile),
    });
  };

  const onUpdateDoc = (metaData: MetaDataForm) => {
    const params: any = {
      id: data?.document?.id?.toString(),
      metaData,
    };
    if (!!docFile && docFile.file && docFile.edited) {
      params.file = docFile.file;
    }
    if (documentType !== data?.document.docTypeCode)
      params.documentTypeCode = documentType;
    update(params);
  };

  const closeModal = () => setShowDismissModal(false);
  const openModal = () => setShowDismissModal(true);

  const onDismiss = () => {
    dismiss({ id: data?.document.id?.toString() || "" });
  };

  const { refreshTaskCounters } = useCounterService();

  const canManageDocument = () => {
    if (
      user?.id === data?.document?.createdBy ||
      user?.dealerCode === data?.document?.dealerCode
    ) {
      return true;
    }
    if (user?.role === UserRoles.ADMIN || user?.role === UserRoles.SUPERVISOR) {
      return true;
    }
    if (task && task.userId && user?.id === task.userId) {
      return true;
    }

    return false;
  };
  const formFields = useMemo(() => {
    if (!metaDataForm) return [];
    return Object.keys(metaDataForm).map((key: any) => {
      const field = metaDataForm[key];
      return {
        label: field.label || "",
        value: field.defaultValue.toString(),
        type: field.type,
      };
    });
  }, [metaDataForm]);
  return {
    document: data?.document,
    metaDataForm,
    formFields,
    loading,
    windowHeight,
    onUpdateDoc,
    editing,
    onEdit,
    onEditCancel,
    docFile,
    onFileEdit,
    onFileEditCancel,
    showDismissModal,
    closeModal,
    openModal,
    onDismiss,
    updating,
    user,
    loadingMetaDataForm,
    canManageDocument,
    documentType: documentType || data?.document?.docTypeCode || "",
    onDocumentTypeChange: (value: string) => {
      setDocumentType(value);
      getMetaDataForDocType({ code: value });
    },
    documentTypes: documentTypes.map((item) => ({
      label: item.title,
      value: item.code,
    })),
  };
}
