import { Icon } from "../../modules/icon";
import { ContinuePreviousUploadProps } from "./UploadDocument.types";
import assests from "../../config/assets";
import Header from "../../components/header/Header";
import Card from "../../components/card/Card";

export const ContinuePreviousUpload = ({
  openModal,
  onContinueDraft,
  type,
}: ContinuePreviousUploadProps) => {
  const title =
    type === "Single"
      ? "Continue previous upload"
      : "Continue previous upload session";
  const image =
    type === "Single"
      ? assests.images.documentDraft
      : assests.images.documentUploadSession;
  const iconTitle = type === "Single" ? "draft document" : "upload session";
  const altText = type === "Single" ? "Document Draft" : "Document Session";
  return (
    <div className="d-flex justify-content-center">
      <Card
        onClick={onContinueDraft}
        className="gap-2"
        style={{ maxWidth: "min-content" }}
      >
        <Header
          size="h5"
          title={title}
          actions={
            <Icon
              title={"Dismiss " + iconTitle}
              type="fa-solid fa-trash"
              className="text-danger cursor-pointer"
              size={18}
              onClick={(event) => {
                openModal();
                event?.stopPropagation();
              }}
            />
          }
        />
        <img
          className="cursor-pointer rounded-1"
          src={image}
          alt={altText}
          width={500}
        />
      </Card>
    </div>
  );
};
