import { BasketCardActionProps } from "../Basket.types";
import { Icon } from "../../../modules/icon";
import { RoleBasedAccess } from "../../../components/guard";
import { UserRoles } from "../../../entities/IUser";

const BasketCardActions = ({
  id,
  isBookmarked,
  onBookmark,
  onDelete,
  onEdit,
}: BasketCardActionProps) => {
  return (
    <div
      className="flex-row basketcard-actions"
      onClick={(event) => {
        event?.stopPropagation();
      }}
    >
      <Icon
        title={isBookmarked ? "Unbookmark" : "Bookmark"}
        type="fa-solid fa-bookmark"
        color={isBookmarked ? "#da291c" : "#666666"}
        onClick={(event) => {
          event?.stopPropagation();
          onBookmark(id);
        }}
      />
      <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
        <Icon
          title="Edit"
          color={"#666666"}
          type="fa-solid fa-pen"
          onClick={(event) => {
            event?.stopPropagation();
            onEdit(id);
          }}
        />
      </RoleBasedAccess>
      <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
        <Icon
          title="Delete"
          color={"#666666"}
          type="fa-solid fa-trash"
          onClick={(event) => {
            event?.stopPropagation();
            onDelete(id);
          }}
        />
      </RoleBasedAccess>
    </div>
  );
};

export default BasketCardActions;
