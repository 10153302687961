import { useEffect, useMemo, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../../components/toast";
import { AccountingPeriods, ITask } from "../../entities/ITask";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useParams } from "react-router-dom";
import { updateTaskForm as updateTaskDefaultForm } from "./Task.types";
import { useUser } from "../../store/auth/AuthSelectors";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";

const useTask = () => {
  const { danger, success } = useToast();
  const id = +(useParams<{ id: string }>().id || -1);
  const [task, setTask] = useState<ITask>();
  const user = useUser();
  const { execute: getData, loading } = useBaseRequest(
    TaskGateway.getTaskById,
    {
      onCompleted: (data) => {
        setTask(data);
        getAccountingPeriods(data.document.id.toString());
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );
  useEffect(() => {
    id && getData(id);
  }, [id]);

  const { execute: updateTask, loading: updating } = useBaseRequest(
    TaskGateway.updateTask,
    {
      onCompleted: (data) => {
        setUpdateInProgress(false);
        getData(id);
        success("Task details updated successfully.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const onCancelUpdate = () => {
    setUpdateInProgress(false);
  };

  const { execute: getAccountingPeriods, data: accountingPeriods } =
    useBaseRequest(DocumentGateway.getAccountingPeriodsByDocId);

  const updateTaskForm = useMemo(
    () => ({
      ...updateTaskDefaultForm,
      id: { ...updateTaskDefaultForm.id, defaultValue: id },
      description: {
        ...updateTaskDefaultForm.description,
        defaultValue: task?.description || "",
      },
      taskTypeCode: {
        ...updateTaskDefaultForm.taskTypeCode,
        defaultValue: task?.taskTypeCode || "",
        options: [
          { label: "Select a billing cycle", value: "" },
          ...(accountingPeriods
            ? accountingPeriods.map((item) => ({
                label: item.title,
                value: item.code,
              }))
            : []),
        ],
        visibleIf() {
          return !!(
            accountingPeriods &&
            accountingPeriods.length &&
            (accountingPeriods?.length > 1 ||
              accountingPeriods[0].code !== task?.taskTypeCode)
          );
        },
      },
      customerName: {
        ...updateTaskDefaultForm.customerName,
        defaultValue: task?.customerName || "",
        visibleIf() {
          return (
            task?.document?.metaData.documentCategory === "Business" ||
            !!task?.customerName
          );
        },
        validators:
          task?.document?.metaData.documentCategory === "Business"
            ? [
                GenericFormValidator.required({
                  error: "Customer name is required.",
                }),
              ]
            : undefined,
      },
    }),
    [
      accountingPeriods,
      id,
      task?.customerName,
      task?.description,
      task?.document?.metaData.documentCategory,
      task?.taskTypeCode,
    ]
  );

  const onDocUpdate = () => {
    getAccountingPeriods(task?.document?.id?.toString() || "");
    getData(task?.id || 0);
  };

  const taskFields = useMemo(() => {
    if (!task) return [];
    return [
      {
        label: "Billing Cycle",
        value:
          AccountingPeriods[
            task.taskTypeCode as keyof typeof AccountingPeriods
          ],
      },
      {
        label: "Description",
        value: task.description,
      },
      ...(task.document.metaData?.documentCategory === "Business" ||
      !!task.customerName
        ? [{ label: "Customer Name", value: task.customerName || "" }]
        : []),
      ...(task?.complaintNumber
        ? [
            { label: "Complaint Number", value: task?.complaintNumber || "" },
            { label: "Complaint MSISDN", value: task?.complaintMSISDN || "" },
          ]
        : []),
    ];
  }, [task]);

  return {
    task,
    taskId: id,
    loading,
    updating,
    updateTaskForm,
    updateTask,
    updateInProgress,
    onCancelUpdate,
    onUpdateStart: () => {
      setUpdateInProgress(true);
    },
    user,
    onDocUpdate,
    taskFields,
  };
};

export default useTask;

/*
<Select
              className="form-group"
              title="Billing cycle"
              options={[
                {
                  value: task.taskTypeCode,
                  label:
                    AccountingPeriods[
                      task.taskTypeCode as keyof typeof AccountingPeriods
                    ],
                },
              ]}
              value={task.taskTypeCode}
              disabled
              onChange={() => {}}
            />
            <div className="form-group">
              <label>Description</label>
              <textarea
                title="Description"
                className="w-100"
                rows={5}
                value={task.description || ""}
                disabled
              />
            </div>
            {(task.document.metaData?.documentCategory === "Business" || !!task.customerName) ? (
              <div className="form-group">
                <label>Customer Name</label>
                <input
                  title="Customer name"
                  className="w-100 form-control bg-light cursor-text"
                  value={task.customerName || ""}
                  disabled
                />
              </div>
            ): null}
          </div>
          */
