import { Button } from "../button";
import Header from "../header/Header";
import { Icon } from "../../modules/icon";
import { Link } from "react-router-dom";
import DocumentMetaDataUpdate from "./DocumentMetaDataUpdate";
import { RoleBasedAccess } from "../guard";
import { UserRoles } from "../../entities/IUser";
import { screens } from "../../screens/Navigation.types";
import { ITask } from "../../entities/ITask";
import Select from "../select/Select";
import { useDocumentForm } from "./useDocumentForm";
import { Loader } from "../../modules/loader";
import { getPosName } from "../../screens/document";
import { FormPreview } from "../form-preview";

const DocumentForm = ({
  id,
  task,
  onDocUpdate,
}: {
  id: string;
  task: ITask;
  onDocUpdate: () => any;
}) => {
  const {
    document,
    onUpdateDoc,
    metaDataForm,
    editing,
    updating,
    onEdit,
    onEditCancel,
    user,
    documentType,
    onDocumentTypeChange,
    documentTypes,
    loadingMetaDataForm,
    formFields,
  } = useDocumentForm(id, onDocUpdate);
  if (!!!document) return <></>;
  return (
    <div className="mt-4 flex-column gap-2">
      <Header
        title="Document Details"
        actions={
          <div className="flex-row gap-2">
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
              ]}
            >
              {user?.role === UserRoles.BO_EXPERT &&
              task.assignee?.id !== user?.id ? null : (
                <Button
                  className={`btn ${editing ? "btn-danger" : "btn-secondary"} `}
                  onClick={editing ? onEditCancel : onEdit}
                >
                  <Icon
                    type={`fa-solid fa-${editing ? "xmark" : "file-pen"}`}
                  />
                  &nbsp; {editing ? "Cancel Editing" : "Edit Document Details"}
                </Button>
              )}
            </RoleBasedAccess>
            <Link
              to={screens.documents + `/${document?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button className="btn btn-secondary">
                <Icon type="fa-regular fa-folder-open" />
                &nbsp; View Document
              </Button>
            </Link>
            {document.files && (
              <Link to={document.files[0].url} target="_blank" rel="noreferrer">
                <Button className="btn btn-secondary">
                  <Icon type="fa-solid fa-file" />
                  &nbsp; View File
                </Button>
              </Link>
            )}
          </div>
        }
      />
      <FormPreview fields={[{ label: "PoS", value: getPosName(document) }]} />
      {editing ? (
        <>
          <Select
            title="Document category and type"
            className="w-100"
            options={documentTypes}
            value={documentType}
            onChange={onDocumentTypeChange}
          />
          {!loadingMetaDataForm ? (
            <DocumentMetaDataUpdate
              metaDataForm={metaDataForm}
              onUpdate={onUpdateDoc}
              updating={updating}
            />
          ) : (
            <div className="w-100 flex-row align-item-center justify-content-center">
              <Loader color="black" />
            </div>
          )}
        </>
      ) : (
        <FormPreview fields={formFields} />
      )}
    </div>
  );
};

export default DocumentForm;
