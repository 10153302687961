import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Filters } from "./Filters";
import { Filter, QueryFilter } from "./Filters.types";
import { parseQueryStringFilters } from "./utility";
interface FiltersWithQueryProps {
  filters: any;
}

export const FiltersWithQuery: React.FC<FiltersWithQueryProps> = ({
  filters,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const onFilterChange = (queryFilters: QueryFilter[]) => {
    queryParams.delete("filters");
    queryFilters.forEach((item) => {
      queryParams.append("filters", JSON.stringify(item));
    });
    navigate({ search: queryParams.toString() }, { replace: true });
  };
  const setDefaultValues = (
    name: keyof typeof filters,
    valueF?: QueryFilter
  ) => {
    let value = valueF?.value;
    if (valueF?.type === "date" || (valueF?.type === "dateArray" && value)) {
      if (Array.isArray(value) && value.length === 2 && value[0] && value[1]) {
        return [new Date(value[0]), new Date(value[1])];
      }
      if (value) {
        if (valueF?.operator === "lte") return [undefined, new Date(value)];
        if (valueF?.operator === "gte") return [new Date(value), undefined];
      }
    }
    if (
      (valueF?.operator === "ne" || valueF?.operator === "eq") &&
      value === null
    ) {
      return valueF?.operator === "ne" ? "yes" : "no";
    }
    if (
      (valueF?.type === "boolean" || valueF?.type === "booleanArray") &&
      value
    ) {
      return valueF?.operator === "eq" ? 1 : 0;
    }
    return typeof value === "number" ? value : value || undefined;
  };

  const getFilters = () => {
    const filtersFromQuery: any[] = parseQueryStringFilters(queryParams);
    let filtersWithValues: Filter[] = Object.keys(filters).map((item) => ({
      ...filters[item as keyof typeof filters],
      defaultValues: setDefaultValues(
        item as keyof typeof filters,
        filtersFromQuery.find((it) => it.name === item)
          ? filtersFromQuery.find((it) => it.name === item)
          : undefined
      ),
    }));

    return filtersWithValues;
  };
  return <Filters filters={getFilters()} onFilterChange={onFilterChange} />;
};
