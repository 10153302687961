import { ChangeEvent, useEffect, useState } from "react";
import { DateRangeTypeValues, FilterProps } from "../Filters.types";
import {
  setDateRangeValuesByType,
  setDefaultDateRangeType,
  setDefaultDateRangeValues,
} from "./utility/setDateRange";
import moment from "moment";

export const useDateRangeFilter = (
  filter: FilterProps["filter"],
  onFilterSet: FilterProps["onFilterSet"],
  ref: any
) => {
  const [dateRangeType, setDateRangeType] = useState<
    DateRangeTypeValues | undefined
  >(setDefaultDateRangeType(filter.defaultValues as (Date | undefined)[]));
  const [appliedDateRangeType, setAppliedDateRangeType] = useState<
    DateRangeTypeValues | undefined
  >(setDefaultDateRangeType(filter.defaultValues as (Date | undefined)[]));

  const [fromDate, setFromDate] = useState<Date | undefined>(
    setDefaultDateRangeValues(filter.defaultValues as (Date | undefined)[])[0]
  );
  const [toDate, setToDate] = useState<Date | undefined>(
    setDefaultDateRangeValues(filter.defaultValues as (Date | undefined)[])[1]
  );
  const [fromAppliedDate, setFromAppliedDate] = useState<Date | undefined>(
    setDefaultDateRangeValues(filter.defaultValues as (Date | undefined)[])[0]
  );
  const [toAppliedDate, setToAppliedDate] = useState<Date | undefined>(
    setDefaultDateRangeValues(filter.defaultValues as (Date | undefined)[])[1]
  );
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value as DateRangeTypeValues;
    setDateRangeType(type);
    setFromDate(setDateRangeValuesByType(type)[0]);
    setToDate(setDateRangeValuesByType(type)[1]);
    if (
      [
        DateRangeTypeValues.AFTER,
        DateRangeTypeValues.BEFORE,
        DateRangeTypeValues.BETWEEN,
      ].includes(type)
    )
      setShowCustomDateRange(true);
    else setShowCustomDateRange(false);
  };

  const [showCustomDateRange, setShowCustomDateRange] =
    useState<boolean>(false);

  const onApplyFilter = () => {
    setShowCustomDateRange(false);
    setAppliedDateRangeType(dateRangeType || DateRangeTypeValues.TODAY);
    const fromAppliedDate = moment(fromDate).isValid()
      ? moment(fromDate).toDate()
      : moment(toDate).isValid()
      ? moment(toDate).startOf("day").toDate()
      : moment().startOf("day").toDate();
    const toAppliedDate = moment(toDate).isValid()
      ? moment(toDate).toDate()
      : moment(fromDate).isValid()
      ? moment(fromDate).endOf("day").toDate()
      : moment().endOf("day").toDate();
    setFromAppliedDate(fromAppliedDate);
    setToAppliedDate(toAppliedDate);
    onFilterSet(
      filter.buildQuery(
        filter,
        dateRangeType !== DateRangeTypeValues.AFTER &&
          dateRangeType !== DateRangeTypeValues.BEFORE
          ? [fromAppliedDate, toAppliedDate]
          : dateRangeType === DateRangeTypeValues.AFTER
          ? [fromAppliedDate, undefined]
          : [undefined, toAppliedDate]
      ),
      filter
    );
  };

  const onShowDateRangeTypes = (show: boolean) => {
    if (show) {
      setDateRangeType(appliedDateRangeType);
      if (
        [
          DateRangeTypeValues.AFTER,
          DateRangeTypeValues.BEFORE,
          DateRangeTypeValues.BETWEEN,
        ].includes(appliedDateRangeType || DateRangeTypeValues.TODAY)
      )
        setShowCustomDateRange(true);
    }
    if (!show) setShowCustomDateRange(false);
  };

  const onChangeFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value
      ? new Date(event.target.value)
      : undefined;
    if (dateRangeType === DateRangeTypeValues.AFTER) {
      setFromDate(selectedDate);
      return;
    }
    moment(toDate).isValid() &&
      moment(selectedDate).isSameOrBefore(toDate) &&
      setFromDate(selectedDate);
    moment(toDate).isValid() &&
      !moment(selectedDate).isSameOrBefore(toDate) &&
      setFromDate(toDate);
  };

  const onChangeTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value
      ? new Date(event.target.value)
      : undefined;
    if (dateRangeType === DateRangeTypeValues.BEFORE) {
      setToDate(selectedDate);
      return;
    }
    moment(fromDate).isValid() &&
      moment(selectedDate).isSameOrAfter(fromDate) &&
      setToDate(selectedDate);
    moment(fromDate).isValid() &&
      !moment(selectedDate).isSameOrAfter(fromDate) &&
      setToDate(moment(fromDate).endOf("day").toDate());
  };

  const setTitle = () => {
    let title = filter.name;

    if (appliedDateRangeType) {
      title += `: ${appliedDateRangeType} `;
      if (
        DateRangeTypeValues.AFTER !== appliedDateRangeType &&
        DateRangeTypeValues.BEFORE !== appliedDateRangeType &&
        DateRangeTypeValues.TODAY !== appliedDateRangeType &&
        DateRangeTypeValues.YESTERDAY !== appliedDateRangeType
      ) {
        title += `(${
          fromAppliedDate ? moment(fromAppliedDate).format("DD.MM.YYYY") : "To"
        } ${!fromAppliedDate ? "" : "-"} ${moment(toAppliedDate).format(
          "DD.MM.YYYY"
        )})`;
      }
      if (
        DateRangeTypeValues.TODAY === appliedDateRangeType ||
        DateRangeTypeValues.YESTERDAY === appliedDateRangeType
      ) {
        title += `(${moment(fromAppliedDate).format("DD.MM.YYYY")})`;
      }
      if (DateRangeTypeValues.AFTER === appliedDateRangeType) {
        title += `${moment(fromAppliedDate).format("DD.MM.YYYY")}`;
      }
      if (DateRangeTypeValues.BEFORE === appliedDateRangeType) {
        title += `${moment(toAppliedDate).format("DD.MM.YYYY")}`;
      }
    }
    return title;
  };

  const title = setTitle();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCustomDateRange(false);
      }
    }

    if (showCustomDateRange) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showCustomDateRange]);

  return {
    onChange,
    onApplyFilter,
    dateRangeType,
    appliedDateRangeType,
    fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "YYYY-MM-DD",
    toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "YYYY-MM-DD",
    onShowDateRangeTypes,
    title,
    onChangeFrom,
    onChangeTo,
    showCustomDateRange,
  };
};
