import { useState } from "react";
import moment from "moment";

import "./Datepicker.style.css";
import { DatepickerProps } from "./Datepicker.types";
import { Icon } from "../../modules/icon";

export const Datepicker = ({
  onChange,
  format = "DD.MM.YYYY",
  className = "",
  disabled = false,
  initialValue = new Date(),
}: DatepickerProps) => {
  const [date, setDate] = useState<moment.Moment | null>(moment(initialValue));

  const formatDateOutput = (date: moment.Moment) => {
    return date.format(format);
  };

  return (
    <>
      <div id="datepicker">
        <input
          type="text"
          className={`${className}`}
          id="datepicker-input"
          value={date ? formatDateOutput(date) : ""}
          readOnly
          onChange={() => {}}
        />
        <Icon type={`fa-regular fa-calendar-days datepicker-toggle-button`} size={24} />
        <input
          type="date"
          className="datepicker-hidden-input"
          value={(date || moment()).format("YYYY-MM-DD")}
          onChange={(e) => {
            setDate(e.target.value ? moment(e.target.value): null);
            onChange(e);
          }}
          disabled={disabled}
        />
      </div>
    </>
  );
};
