import { DigitalSignatureCardInfoProps } from "..";
import { FormPreview } from "../../../components/form-preview";
import { Icon } from "../../../modules/icon";

export const DigitalSignatureCardInfo = ({
  customerEmail,
  fileName,
}: DigitalSignatureCardInfoProps) => (
  <div className="flex-row" style={{ gap: "8px" }}>
    <div
      className="flex-row align-items-center justify-content-center bg-white rounded"
      style={{ height: "200px", minWidth: "130px" }}
    >
      <Icon type="fa-regular fa-file" size={64} title={fileName} />
    </div>
    <FormPreview
      fields={[
        { label: "Customer Email", value: customerEmail },
        { label: "Document Name", value: fileName },
      ]}
    />
  </div>
);
