import "./DigitalSignatureCard.style.css";
import { DigitalSignatureCardProps } from "../";
import { DigitalSignatureCardInfo } from "./DigitalSignatureCardInfo";
import { DigitalSignatureCardActions } from "./DigitalSignaturetCardActions";
import { Button } from "../../../components/button";
import { Icon } from "../../../modules/icon";
import { DigitalSignatureRequestStatuses } from "../../../entities/IDigitalSignature";

export const DigitalSignatureCard = (signature: DigitalSignatureCardProps) => (
  <div
    className="signature-card cursor-pointer"
    style={{ padding: 8 }}
    onClick={signature.onClick}
  >
    <DigitalSignatureCardActions {...signature} />
    <DigitalSignatureCardInfo {...signature} />
    {signature.status === DigitalSignatureRequestStatuses.CONFIRMED ? (
      <div className="btn text-center w-100 btn-success-washed">Confirmed</div>
    ) : null}
  </div>
);
