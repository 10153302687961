import { PageItemProps } from "./SplitFile.types";
import Card from "../../components/card/Card";
import { Icon } from "../../modules/icon";
import "./SplitFile.style.css";
import { useState, useEffect } from "react";
import { Loader } from "../../modules/loader";

export const PageItem: React.FC<PageItemProps> = ({
  addSeparator,
  removeSeparator,
  deletePage,
  seePage,
  id,
  imagePromise,
  isMarkedToSplitAfter,
  pageNumber,
  color,
  isLast,
}) => {
  const [image, setImage] = useState<string>("");

  useEffect(() => {
    const getImage = async () => {
      const image = await imagePromise;
      setImage(image);
    };
    getImage();
  }, []);

  const onChangeSeparatorHandler = () => {
    if (isLast) return;
    if (isMarkedToSplitAfter) {
      removeSeparator(id);
    } else {
      addSeparator(id);
    }
  };

  const renderLoader = () => {
    if (!image) {
      return (
        <div className="w-100 h-100 flex-row align-items-center justify-content-center">
          <Loader color="black" />
        </div>
      );
    }
    return "";
  };

  return (
    <div
      className="d-flex w-100 align-items-center"
      style={{
        gap: "16px",
      }}
    >
      <div
        className="flex-column align-items-center gap-2 p-3 w-100 pb-2"
        style={{
          backgroundColor: "#F2F2F2",
          borderRadius: "16px",
          border: `2px solid ${color}`,
        }}
      >
        <Card
          onClick={() => {
            seePage(id);
          }}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
          }}
          className="position-relative page-item-container"
          aspectRatio={1 / 1.4142}
        >
          <Icon
            title="Delete page"
            id="delete-page-button"
            className="position-absolute top-0 end-0 p-2 bg-white rounded-circle"
            color={"rgb(218, 41, 28)"}
            type="fa-solid fa-trash"
            onClick={(event) => {
              event?.stopPropagation();
              deletePage(id);
            }}
          />
          {renderLoader()}
        </Card>
        <label
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          Page {pageNumber}
        </label>
      </div>
      <div className="position-relative separation-mark-container" onClick={onChangeSeparatorHandler}>
        <div
          style={{
            height: "128px",
            borderLeft: `1px ${isMarkedToSplitAfter ? "solid" : "dashed"} #000`,
            marginLeft: "8px",
            opacity: isLast ? 0 : 1,
          }}
        />
        <Icon
          className={`position-absolute top-50 start-50 ${
            isMarkedToSplitAfter ? "separation-mark-separated" : "separation-mark"
          }`}
          title={isMarkedToSplitAfter ? "Remove separation mark" : "Add separation mark"}
          type={"fa-solid fa-scissors fa-rotate-270"}
          size={"18px"}
        />
      </div>
    </div>
  );
};
