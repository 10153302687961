import { useEffect, useState } from "react";
import useBaseRequest from "../../api/BaseRequest";
import { useToast } from "../../components/toast";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useParams } from "react-router-dom";
import {
  taskAddCommentForm as taskAddCommentFormDefault,
  ITaskComment,
} from "../../components/comments/comments.types";
import { useCounterService } from "../../services/useCounterService";

const useTaskComments = () => {
  const { success, danger } = useToast();
  const id = +(useParams<{ id: string }>().id || -1);
  const { refreshCommentsCount } = useCounterService();

  useEffect(() => {
    id && getComments(id);
  }, [id]);

  const { execute: addCommentToTask, loading: addingComment } = useBaseRequest(
    TaskGateway.addCommentToTask,
    {
      onCompleted: (data) => {
        success("Comment added successfully.");
        getComments(id);
        setShowCommentForm(false);
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const taskAddCommentForm = {
    ...taskAddCommentFormDefault,
    taskId: {
      ...taskAddCommentFormDefault.taskId,
      defaultValue: id || -1,
    },
  };

  const [showCommentForm, setShowCommentForm] = useState<boolean>(false);

  const [comments, setComments] = useState<ITaskComment[]>([]);
  const { execute: getComments, loading: loadingComments } = useBaseRequest(
    TaskGateway.getCommentsByTaskId,
    {
      onCompleted: (data) => {
        setComments(data.comments);
        refreshCommentsCount(id);
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  const [showMoreComments, setShowMoreComments] = useState<boolean>(false);
  const showCommentFormHandler = () => {
    setShowCommentForm(true);
  };
  const hideCommentFormHandler = () => {
    setShowCommentForm(false);
  };

  return {
    addCommentToTask,
    addingComment,
    taskAddCommentForm,
    showCommentFormHandler,
    hideCommentFormHandler,
    showCommentForm,
    comments: comments.slice(0, showMoreComments ? comments.length : 3),
    loadingComments,
    showMoreComments,
    setShowMoreComments,
  };
};

export default useTaskComments;
