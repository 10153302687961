import { Modal } from "react-bootstrap";
import { taskStatuses } from "../../entities/ITask";
import { TaskOtherActionsModalProps } from "./Task.types";
import { Button } from "../../components/button";

export function TaskOtherActionsModal({
  show,
  onSubmit,
  onCancel,
  submiting,
  action,
}: TaskOtherActionsModalProps) {
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Status change
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          Confirm that you want to change the status of this task to{" "}
          {taskStatuses[action]?.toLowerCase()}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          isLoading={submiting}
          className="btn btn-primary"
          onClick={() => {
            onSubmit(action);
          }}
          disabled={submiting}
        >
          Yes
        </Button>
        <Button
          type="button"
          className="btn btn-secondary"
          onClick={onCancel}
          disabled={submiting}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
