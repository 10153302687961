import moment from "moment";
import { ChangeEvent } from "react";
import { Password } from "../password";
import { GenericFormControlProps } from "./GenericForm.types";
import { CheckBox } from "../checkbox";
import AutocompleteOpt from "../autocomplete-opt/AutocompleteOpt";
import { IOption } from "../select";
import { AutocompleteOptProps } from "../autocomplete-opt/AutocompleteOpt.types";
import { Datepicker } from "../datepicker";

const autocompleteMultiple = (
  type: "users" | "baskets" | "document-types" | "dealers",
  selectedOptions: any[] | [],
  onSelectOption: (options: IOption<any>[] | undefined) => void
): AutocompleteOptProps => {
  return {
    label: "",
    placeholder: `Search ${type.replace("-", " ")}`,
    isMultiple: true,
    defaultValue: selectedOptions.map((item) => (["document-types", "dealers"].includes(type) ? item : +item)),
    onSelectOption: (options: IOption<any>[] | IOption<any> | undefined) => {
      if (Array.isArray(options) || typeof options === "undefined") {
        onSelectOption(options);
      }
    },
    limit: type === "baskets" ? 1000 : undefined,
    minCharsToSearch: type === "baskets" ? 0 : undefined,
    endpoint: `/${type}/search-autocomplete`,
    showRemoveAll: false,
  };
};

export function GenericFormControl<T, P>({
  control,
  isTouched,
  isSubmitted,
  error,
  value,
  onChange,
  formState,
}: GenericFormControlProps<T, P>) {
  function onInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = event.target.value;
    onChange(value);
  }

  function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    onChange(value);
  }

  function onDateChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    onChange(value ? new Date(value): null);
  }

  function onCheckedChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.checked;
    onChange(value);
  }

  function onMultipleSelectChange(values: IOption<any>[] | IOption<any> | undefined) {
    if (Array.isArray(values)) onChange(values.map((item) => item.value));
  }

  function renderControl() {
    switch (control.type) {
      case "input":
        return (
          <input
            className={`form-control${
              error && isTouched && isSubmitted
                ? " is-invalid"
                : !error && isTouched && value && isSubmitted
                ? " is-valid"
                : ""
            }`}
            value={value as any}
            onChange={onInputChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "textarea":
        return (
          <textarea
            className={`form-control${
              error && isTouched && isSubmitted
                ? " is-invalid"
                : !error && isTouched && value && isSubmitted
                ? " is-valid"
                : ""
            }`}
            value={value as any}
            onChange={onInputChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "password":
        return (
          <Password
            value={value as any}
            onChange={onInputChange}
            className={`form-control${
              error && isTouched && isSubmitted ? " is-invalid" : ""
            } ${
              !error && isTouched && value && isSubmitted ? "is-valid" : ""
            }}`}
          />
        );
      case "select":
        return (
          <select
            className={`form-select${
              error && isTouched && isSubmitted ? " is-invalid" : ""
            } ${!error && isTouched && isSubmitted ? "is-valid" : ""}}`}
            value={value as any}
            onChange={onSelectChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          >
            {control.options
              ? control.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))
              : null}
          </select>
        );
      case "date":
        return (
          <Datepicker
            onChange={onDateChange}
            className={`form-control ${error && isTouched && isSubmitted ? " is-invalid" : ""} ${
              !error && isTouched && isSubmitted && value ? " is-valid" : ""
            }`}
            initialValue={moment(value || new Date()).toDate()}
          />
        );
      case "checkbox":
        return (
          <CheckBox
            scale={1.5}
            checked={value as any}
            onChange={onCheckedChange}
            disabled={!!control.disabledIf && control.disabledIf(formState) ? true : false}
          />
        );
      case "hidden":
        return <></>;
      case "autocompleteMultipleChoice":
        return (
          <AutocompleteOpt
            {...autocompleteMultiple(
              (control.options ? control.options[0].value : "users") as "users" | "baskets" | "document-types",
              (value as any[]) || [],
              onMultipleSelectChange
            )}
          />
        );
      default:
        console.warn("Unsupported data type! Please consider adding custom FormControl");
        return <></>;
    }
  }
  return renderControl();
}
