import { IDbEntity } from "./IDbEntity";

export interface IUser extends IDbEntity {
  firstName: string;
  lastName: string;
  name?: string;
  email?: string;
  username: string;
  dealerCode?: string;
  role: UserRoles;
  token: string;
}

export enum UserRoles {
  ADMIN = "L4 - Admin",
  SUPERVISOR = "L3 - Supervisor",
  BO_EXPERT = "L2 - BO",
  SALES = "L1 - 1st line",
  AGENT = "L0 - Agent",
}
