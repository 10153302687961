import { DigitalSignaturesCardActionProps } from "..";
import { Icon } from "../../../modules/icon";
import { RoleBasedAccess } from "../../../components/guard";
import { UserRoles } from "../../../entities/IUser";

export const DigitalSignatureCardActions = ({
  id,
  onDelete,
}: DigitalSignaturesCardActionProps) => {
  return (
    <div
      className="flex-row dismiss-signature shadow"
      onClick={(event) => {
        event?.stopPropagation();
      }}
    >
      <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
        <Icon
          title="Delete"
          color={"#666666"}
          type="fa-solid fa-trash"
          onClick={(event) => {
            event?.stopPropagation();
            onDelete(id);
          }}
        />
      </RoleBasedAccess>
    </div>
  );
};
