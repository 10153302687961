import { useEffect, useState } from "react";
import BaseInstance from "../../api/BaseInstance";
import { useSocket } from "../../modules/socket";
import { useUser } from "../../store/auth/AuthSelectors";
import { useNavigate, useParams } from "react-router-dom";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { DocumentStatus, IDocument } from "../../entities/IDocument";
import { useToast } from "../../components/toast";
import { screens } from "../Navigation.types";

export const useDraftDocumentsSession = () => {
  const { success, danger } = useToast();
  const navigate = useNavigate();
  const token = useUser()?.token;
  const id = useParams<{ sessionId: string }>().sessionId;
  const [draftDocuments, setDraftDocuments] = useState<IDocument[]>([]);

  useEffect(() => {
    if (id) getDocumentSession({ id });
  }, [id]);
  const {
    execute: getDocumentSession,
    data,
    loading: loadingDocuments,
  } = useBaseRequest(DocumentGateway.getDocumentsBySessionId, {
    onCompleted: (data) => {
      if (!data) return;
      if (data.status === "done") {
        navigate(screens.documents);
        success("All documents are successfully uploaded.");
      }
      setDraftDocuments(
        data.documents.filter(
          (item) =>
            item.status === DocumentStatus.DRAFT ||
            item.status === DocumentStatus.PROCESSING
        )
      );
    },
    onError: () => {
      navigate(screens.uploadDocument);
    },
  });
  const { connect, disconnect } = useSocket(BaseInstance.getUri(), {
    connection: {
      auth: {
        token: token,
      },
    },
    room: `UploadSession_${id}`,
    listeners: [
      {
        event: "session-changed-event",
        callback: (data) => {
          if (!id) return;
          getDocumentSession({ id });
        },
      },
    ],
  });

  useEffect(() => {
    if (!token && !id) return;
    connect();
    return () => disconnect();
  }, [token, id]);

  const [viewId, setViewId] = useState<string>("");
  const onView = (docId: string) => {
    setViewId(docId);
  };

  const onCancelDocumentUpload = (isDocumentCreated?: boolean) => {
    if (isDocumentCreated && id) {
      getDocumentSession({ id });
    }
    onView("");
  };
  const { execute: onDismissSession, loading: dismissing } = useBaseRequest(
    DocumentGateway.dismissDocumentSession,
    {
      onCompleted: () => {
        navigate(screens.uploadDocument);
      },
    }
  );

  const [showDismissSessionModal, setShowDismissSessionModal] =
    useState<boolean>(false);

  const { execute: addNewDocumentToSession, loading: addingNewDocument } =
    useBaseRequest(DocumentGateway.addNewDocumentToSession);
  const onAdd = (files: File[]) => {
    if (!files || !files.length || !id) return;
    if (files[0].size > 100 * 1024 * 1024) {
      danger("File size should be less than 100MB");
      return;
    }
    addNewDocumentToSession({ file: files[0], id });
    return;
  };

  const { execute: onDismissDocumentFromSession, loading: dismissingDocument } =
    useBaseRequest(DocumentGateway.deleteDocumentFromSession, {
      onCompleted: () => {
        if (!id) return;
        setDismissDocumentId("");
        setShowDismissSessionModal(false);
        setViewId("");
        if (data?.documents?.length === 1) {
          navigate(screens.uploadDocument);
          return;
        }
        getDocumentSession({ id });
      },
    });

  const [dismissDocumentId, setDismissDocumentId] = useState<string>("");
  const onDismiss = (documentId: number) => {
    setDismissDocumentId(documentId.toString());
  };
  const onDismissDocument = () => {
    if (!id) return;
    onDismissDocumentFromSession({
      id,
      documentId: dismissDocumentId.toString(),
    });
  };
  return {
    onDismiss,
    onDismissDocument,
    draftDocuments,
    onAdd,
    onView,
    viewId,
    count: data?.documents?.length || 0,
    loading:
      loadingDocuments || dismissing || addingNewDocument || dismissingDocument,
    onCancelDocumentUpload,
    onDismissSession: () => {
      if (!id) return;
      onDismissSession({ id });
    },
    showDismissSessionModal,
    onCancelDismissSession: () => setShowDismissSessionModal(false),
    setShowDismissSessionModal,
    onCancelDismissDocument: () => setDismissDocumentId(""),
    showDismissDocumentModal: !!dismissDocumentId,
  };
};
