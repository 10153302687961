import BaseInstance from "../api/BaseInstance";
import useBaseRequest from "../api/BaseRequest";
import DocumentGateway from "../api/gateways/DocumentGateway";
import {
  GenericForm,
  GenericFormControlTypes,
  validatorFunc,
} from "../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../components/generic-form/utility/GenericFormValidator";
import {
  IMetaDataRule,
  IValidationRule,
  MetaData,
  MetaDataForm,
  MetaDataType,
} from "../entities/IMetaData";
import { RequestOptions } from "../modules/request";
import { useAppDispatch } from "../store";
import { documentsActions } from "../store/documents/DocumentReducer";
import { useDocumentTypes } from "../store/documents/DocumentSelector";

export function useDocumentService() {
  const dispatch = useAppDispatch();

  function useGetDocumentTypes() {
    const documentTypes = useDocumentTypes();
    const { execute, loading } = useBaseRequest(
      DocumentGateway.getDocumentTypes,
      {
        autoFetch: true,
        initialPayload: {},
        onCompleted: (data) => {
          dispatch(documentsActions.setDocumentTypes(data));
        },
      }
    );

    return { getDocumentTypes: execute, loading, documentTypes };
  }

  function useGetMetaDataForDocType(
    options?: RequestOptions<IMetaDataRule[], { code: string }>
  ) {
    const {
      execute: getMetaDataForDocType,
      loading,
      data: metaData,
    } = useBaseRequest(DocumentGateway.getMetaDataForDocType, options);
    return { getMetaDataForDocType, metaData, loading };
  }

  const metaDataItemTypeToControlType: {
    [key in MetaDataType]: GenericFormControlTypes;
  } = {
    boolean: "checkbox",
    datetime: "date",
    integer: "input",
    select: "select",
    string: "input",
  };

  function mapMetaDataRuleValidatorsToFormValidators(
    validators?: IValidationRule[]
  ): validatorFunc[] | undefined {
    if (!validators) return;
    const formValidators: validatorFunc[] = [];
    validators.forEach((val) => {
      const formValidatorMethod = GenericFormValidator[val.type];
      if (!formValidatorMethod) return;
      formValidators.push(formValidatorMethod(val.params as any));
    });
    return formValidators;
  }

  function getMetaDataRuleDefaultValue<T>(
    value: T | null,
    type: MetaDataType
  ): T {
    if (!!value) {
      if (type === MetaDataType.DATE) {
        return new Date(value as string) as any as T;
      }
      return value;
    }
    if (type === MetaDataType.NUMBER) return 0 as any as T;
    if (type === MetaDataType.DATE) return new Date() as any as T;
    return "" as any as T;
  }

  function mapMetaDataRulesToForm(
    metaDataRules: IMetaDataRule[],
    metaDataValues: MetaData = {}
  ): GenericForm<MetaDataForm> {
    const form: GenericForm<MetaDataForm> = {};
    metaDataRules.forEach((mtdRule) => {
      form[mtdRule.item.key] = {
        name: mtdRule.item.key,
        type: metaDataItemTypeToControlType[mtdRule.item.dataType],
        options: mtdRule.item.allowedValues
          ? mtdRule.item.allowedValues.map((aw) => ({
              label: aw.value,
              value: aw.key,
            }))
          : undefined,
        label: mtdRule.item.name,
        defaultValue: getMetaDataRuleDefaultValue(
          mtdRule.editable && metaDataValues[mtdRule.item.key]
            ? metaDataValues[mtdRule.item.key] || ""
            : mtdRule.defaultValue,
          mtdRule.item.dataType
        ),
        validators: mapMetaDataRuleValidatorsToFormValidators(
          mtdRule.validation
        ),
        disabledIf: () => !mtdRule.editable,
      };
    });
    return form;
  }

  function getDocFileUrl(url: string): string {
    if (!url) return "";
    const urlSegments = url.split("/");
    const relativeUrlSegements = urlSegments.splice(3, urlSegments.length - 1);
    const uri = relativeUrlSegements.join("/");
    const apiEndpoint = BaseInstance.getUri();
    return apiEndpoint + uri;
  }

  function removeQueryParams(url: string, paramsToRemove: string[]): string {
    const [baseUrl, queryString] = url.split("?");

    if (!queryString) {
      return url;
    }

    // Filter out the parameters to be removed from the query string
    const params = queryString.split("&");
    const newParams = params.filter((param) => {
      const [paramName] = param.split("=");
      return !paramsToRemove.includes(paramName);
    });

    // Rebuild the URL with the modified query string
    const newUrl =
      baseUrl + (newParams.length > 0 ? `?${newParams.join("&")}` : "");

    return newUrl;
  }

  return {
    useGetDocumentTypes,
    useGetMetaDataForDocType,
    mapMetaDataRulesToForm,
    getDocFileUrl,
    removeQueryParams,
  };
}
