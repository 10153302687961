import { Modal } from "react-bootstrap";
import { TaskStatusWithCommentModalProps } from "./Task.types";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";

export function TaskCancelModal({
  show,
  onSubmit,
  onDismiss,
  changing,
  taskStatusWithCommentForm,
}: TaskStatusWithCommentModalProps) {
  return (
    <Modal show={show} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Cancel Task
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          In order to cancel this task, please provide a reason for
          cancellation.
        </p>
        <GenericForm
          form={taskStatusWithCommentForm}
          onSubmit={onSubmit}
          submitControl={(submit) => (
            <div className="flex-row">
              <Button
                isLoading={changing}
                loader={{ size: "s" }}
                className="btn btn-primary mt-3 w-100"
                type="submit"
              >
                Confirm cancellation
              </Button>
            </div>
          )}
        />
      </Modal.Body>
    </Modal>
  );
}
