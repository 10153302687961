import moment from "moment";
import { DateRangeType } from "../DateRangeFilter.types";
import { getDateRange } from "./getDateRange";
import { DateRangeTypeValues } from "../../Filters.types";

export const setDefaultDateRangeType = (
  date: (Date | undefined)[]
): DateRangeType | undefined => {
  return date && Array.isArray(date) && date.length === 2
    ? getDateRange(date[0] as Date, date[1] as Date)
    : undefined;
};

export const setDefaultDateRangeValues = (
  date: (Date | undefined)[]
): (Date | undefined)[] => {
  return date && Array.isArray(date) && date.length === 2
    ? [date[0], date[1]]
    : [moment().startOf("day").toDate(), moment().toDate()];
};

export const setDateRangeValuesByType = (
  type: DateRangeType | undefined
): [Date | undefined, Date | undefined] => {
  const to = moment().endOf("day").toDate();
  let fromDate, toDate;
  switch (type) {
    case DateRangeTypeValues.TODAY:
      fromDate = moment().startOf("day").toDate();
      toDate = to;
      break;

    case DateRangeTypeValues.YESTERDAY:
      fromDate = moment().startOf("day").subtract(1, "day").toDate();
      toDate = moment().endOf("day").subtract(1, "day").toDate();
      break;

    case DateRangeTypeValues.THIS_WEEK:
      fromDate = moment().startOf("isoWeek").toDate();
      toDate = to;
      break;

    case DateRangeTypeValues.LAST_WEEK:
      fromDate = moment().subtract(1, "weeks").startOf("isoWeek").toDate();
      toDate = moment().subtract(1, "weeks").endOf("isoWeek").toDate();
      break;

    case DateRangeTypeValues.THIS_MONTH:
      fromDate = moment().startOf("month").toDate();
      toDate = to;
      break;

    case DateRangeTypeValues.LAST_MONTH:
      fromDate = moment().subtract(1, "month").startOf("month").toDate();
      toDate = moment().subtract(1, "month").endOf("month").toDate();
      break;

    case DateRangeTypeValues.THIS_YEAR:
      fromDate = moment().startOf("year").toDate();
      toDate = to;
      break;

    case DateRangeTypeValues.LAST_YEAR:
      fromDate = moment().subtract(1, "year").startOf("year").toDate();
      toDate = moment().subtract(1, "year").endOf("year").toDate();
      break;

    default:
      fromDate = moment().startOf("day").toDate();
      toDate = to;
      break;
  }
  return [fromDate, toDate];
};
