import { useParams } from "react-router";
import { Button } from "../../components/button";
import { FilePreview } from "../../components/file-preview";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { Icon } from "../../modules/icon";
import { DocumentDataUpdate } from "./DocumentDataUpdate";
import { DocumentDismissModal } from "./DocumentDismissModal";
import { useDocument } from "./useDocument";
import { CreateTaskModal } from "../../components/create-task";
import { getPosName } from "./utility/getPosName";
import { FormPreview } from "../../components/form-preview";
import { RequestDigitalSignatureModal } from "../../components/request-digital-signature";
import { ViewDigitalSignatureModal } from "../../components/request-digital-signature/ViewDigitalSignatureModal";
import { DigitalSignatureRequestStatuses } from "../../entities/IDigitalSignature";
import { DocumentActions } from "./DocumentActions";
import { DocumentActionsTypes } from "./Document.types";
import { Badge } from "../../components/badge";
import { DeleteDigitalSignatureModal } from "../../components/delete-digital-signature";

export function DocumentScreen() {
  const { id } = useParams();
  const {
    document,
    loading,
    windowHeight,
    onUpdateDoc,
    metaDataForm,
    docFile,
    onFileEdit,
    onFileEditCancel,
    onEditCancel,
    onDismiss,
    updating,
    onCreateTask,
    creatingTask,
    createTaskForm,
    formFields,
    onRequestDigitalSignature,
    requestingDigitalSignature,
    requestDigitalSignatureForm,
    digitalSignatureFields,
    onActionSelected,
    user,
    onClearAction,
    selectedAction,
    onStartDelete,
    closeDeleteModal,
    onDelete,
    showDeleteSignatureModal,
    onResend,
  } = useDocument(id || "");

  return (
    <PageLayout
      title={`Document #${id}`}
      badge={
        document?.digitalSignRequest ? (
          <Badge
            title={
              "Digital Signature Status: " + document.digitalSignRequest.status
            }
            type={
              document.digitalSignRequest.status ===
              DigitalSignatureRequestStatuses.PENDING
                ? "warning"
                : document.digitalSignRequest.status ===
                  DigitalSignatureRequestStatuses.CONFIRMED
                ? "info"
                : "success"
            }
            variant={
              document.digitalSignRequest.status ===
              DigitalSignatureRequestStatuses.SIGNED
                ? "outlined"
                : "filled"
            }
            className="align-items-center d-flex"
          >
            <label style={{ fontSize: "12px" }}>
              {"Digital Signature Status: " +
                document.digitalSignRequest.status[0].toUpperCase() +
                document.digitalSignRequest.status.slice(1)}
            </label>
          </Badge>
        ) : undefined
      }
      backBtn
      titleRight={
        document ? (
          <div className="d-flex flex-row align-items-center justify-content-end mr-3">
            {selectedAction === DocumentActionsTypes.EDIT_DOCUMENT ? (
              <Button className="btn btn-danger" onClick={onEditCancel}>
                <Icon type="fa-solid fa-xmark" size={16} />
                &nbsp; Cancel
              </Button>
            ) : (
              <DocumentActions
                onActionSelected={onActionSelected}
                document={document}
                user={user}
              />
            )}
          </div>
        ) : undefined
      }
    >
      <DocumentDismissModal
        onDismiss={onDismiss}
        show={selectedAction === DocumentActionsTypes.DELETE_DOCUMENT}
        onCancel={onClearAction}
      />
      <ViewDigitalSignatureModal
        fields={digitalSignatureFields}
        isVisible={
          selectedAction === DocumentActionsTypes.VIEW_DIGITAL_SIGNATURE
        }
        onDismiss={onClearAction}
        onResend={onResend}
        onDelete={onStartDelete}
      />
      <CreateTaskModal
        onDismiss={onClearAction}
        onSubmit={onCreateTask}
        creating={creatingTask}
        isVisible={selectedAction === DocumentActionsTypes.CREATE_TASK}
        createTaskForm={createTaskForm}
      />
      <RequestDigitalSignatureModal
        onDismiss={onClearAction}
        onSubmit={onRequestDigitalSignature}
        creating={requestingDigitalSignature}
        isVisible={
          selectedAction === DocumentActionsTypes.REQUEST_DIGITAL_SIGNATURE
        }
        requestDigitalSignatureForm={requestDigitalSignatureForm}
      />
      <DeleteDigitalSignatureModal
        show={showDeleteSignatureModal}
        onDelete={onDelete}
        onCancel={closeDeleteModal}
      />
      {document ? (
        <Row>
          <Column>
            {docFile ? (
              <div className="d-flex flex-column align-items-center">
                <FilePreview
                  enableCanceling={
                    docFile.edited &&
                    selectedAction === DocumentActionsTypes.EDIT_DOCUMENT
                  }
                  onCancel={onFileEditCancel}
                  onFileChosen={onFileEdit}
                  enableEditing={
                    selectedAction === DocumentActionsTypes.EDIT_DOCUMENT &&
                    document?.digitalSignRequest?.status !==
                      DigitalSignatureRequestStatuses.SIGNED &&
                    document?.digitalSignRequest?.status !==
                      DigitalSignatureRequestStatuses.CONFIRMED
                  }
                  type={docFile.type}
                  url={docFile.uri}
                  name={docFile.name}
                  width={(windowHeight - 250) / 1.4}
                  height={windowHeight - 250}
                />
              </div>
            ) : null}
          </Column>
          <Column>
            <div style={{ maxWidth: (windowHeight - 250) / 1.4 }}>
              {selectedAction === DocumentActionsTypes.EDIT_DOCUMENT ? (
                <DocumentDataUpdate
                  docType={document.docType}
                  metaDataForm={metaDataForm}
                  onUpdate={onUpdateDoc}
                  updating={updating}
                />
              ) : (
                <div>
                  <FormPreview
                    fields={[{ label: "PoS", value: getPosName(document) }]}
                  />
                  <div className="mt-3">
                    <h5 style={{ fontWeight: "600" }}>Details</h5>
                    <FormPreview fields={formFields} />
                  </div>
                </div>
              )}
            </div>
          </Column>
        </Row>
      ) : loading ? null : (
        <h4>No document found.</h4>
      )}
    </PageLayout>
  );
}
