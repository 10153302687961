import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import {
  AccountingPeriods,
  ITask,
  TaskReassignDTO,
  UpdateTaskDTO,
} from "../../entities/ITask";
import { IUser } from "../../entities/IUser";

export interface TaskStatusWithCommentModalProps {
  show: boolean;
  onSubmit: (data: TaskStatusWithCommentChangeDTO) => void;
  onDismiss: () => void;
  changing: boolean;
  taskStatusWithCommentForm: GenericForm<TaskStatusWithCommentChangeDTO>;
}
export interface TaskStatusWithCommentChangeDTO {
  id: number;
  comment: string;
}
export const taskCancelForm: GenericForm<TaskStatusWithCommentChangeDTO> = {
  comment: {
    type: "input",
    name: "comment",
    label: "Comment",
    defaultValue: "",
    validators: [
      GenericFormValidator.required({
        error: "Comment is required. Please provide a reason for cancelation.",
      }),
    ],
  },
  id: {
    type: "hidden",
    visibleIf: () => false,
    name: "id",
    label: "id",
    defaultValue: 0,
    validators: [],
  },
};

export const taskPutOnHoldForm: GenericForm<TaskStatusWithCommentChangeDTO> = {
  comment: {
    type: "input",
    name: "comment",
    label: "Comment",
    defaultValue: "",
    validators: [],
  },
  id: {
    type: "hidden",
    visibleIf: () => false,
    name: "id",
    label: "id",
    defaultValue: 0,
    validators: [],
  },
};

export interface TaskBadgesProps {
  task: ITask;
}

export interface TaskReassignModalProps {
  show: boolean;
  onSubmit: (data: number) => void;
  onCancel: () => void;
  reassigning: boolean;
  currentAssigneeId?: number;
  task: ITask;
  user: IUser | null;
}

export interface TaskUnassignModalProps {
  show: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  unassigning: boolean;
  task: ITask;
  user: IUser | null;
}

export interface TaskMoveToAnotherBasketModalProps {
  show: boolean;
  onSubmit: (data: number) => void;
  onCancel: () => void;
  moving: boolean;
  currentBasketId?: number;
}

export const TASK_TABS = [
  { id: 0, name: "Details", link: "details" },
  { id: 1, name: "Comments", link: "comments", counter: 0 },
  { id: 2, name: "Logs", link: "logs" },
];

export interface TaskOtherActionsModalProps {
  show: boolean;
  onSubmit: (status: "resolved" | "inprogress") => void;
  onCancel: () => void;
  submiting: boolean;
  action: "resolved" | "inprogress";
}

export const updateTaskForm: GenericForm<UpdateTaskDTO> = {
  taskTypeCode: {
    type: "select",
    name: "taskTypeCode",
    label: "Billing cycle",
    defaultValue: "",
    options: [
      ...Object.keys(AccountingPeriods).map((key) => ({
        value: key,
        label: AccountingPeriods[key as keyof typeof AccountingPeriods],
      })),
      { label: "Select a billing cycle", value: "" },
    ],
    validators: [
      GenericFormValidator.required({
        error: "Select a billing cycle.",
      }),
    ],
  },
  description: {
    type: "textarea",
    name: "description",
    label: "Description",
    defaultValue: "",
  },
  customerName: {
    type: "input",
    name: "customerName",
    label: "Customer Name",
    defaultValue: "",
  },
  id: {
    type: "hidden",
    label: "Id",
    visibleIf: () => false,
    name: "id",
    defaultValue: -1,
    validators: [],
  },
};

export interface TaskActionsProps {
  onActionSelected: (action: string) => any;
  task: ITask;
  user: IUser | null;
}
