import { ContainerProps } from "./Grid.types";

export function Container({ fluid, children }: ContainerProps) {
  return (
    <div
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
      className={fluid ? "container-fluid" : "container"}
    >
      {children}
    </div>
  );
}
