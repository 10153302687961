import { useEffect, useState } from "react";
import { PageLayout } from "../../components/page-layout";
import useTask from "./useTask";
import { taskStatuses, taskStatusesColors } from "../../entities/ITask";
import { TaskCancelModal } from "./TaskCancelModal";
import TaskBadges from "./TaskBadges";
import { TaskReassignModal } from "./TaskReassignModal";
import { TaskMoveToAnotherBasketModal } from "./TaskMoveToAnotherBasketModal";
import Tabs from "../../components/tabs/Tabs";
import { Outlet, useLocation } from "react-router-dom";
import { TaskOtherActionsModal } from "./TaskOtherActionsModal";
import { Badge } from "../../components/badge";
import { TaskUnassignModal } from "./TaskUnassignModal";
import { TaskPutOnHoldModal } from "./TaskPutOnHoldModal";
import { RoleBasedAccess } from "../../components/guard";
import { UserRoles } from "../../entities/IUser";
import { TaskActions } from "./TaskActions";
import { TaskResolvedModal } from "./TaskResolvedModal";

const TaskScreen = () => {
  const {
    task,
    loading,
    taskId,
    selectedAction,
    onChangeAction,
    cancelTask,
    cancelingTask,
    taskCancelForm,
    reassignTask,
    reassigningTask,
    moveToAnotherBasket,
    movingTask,
    changeStatus,
    changingStatus,
    unassignTask,
    unassigning,
    putOnHold,
    puttingOnHold,
    taskPutOnHoldForm,
    taskTabs,
    user,
    shouldPermitBOExpertToManageTask,
    resolveTask,
    resolvingTask,
    taskResolvedForm,
  } = useTask();
  const location = useLocation().pathname.split("/")[3];
  const [selected, setSelected] = useState(
    location === undefined || location === "details"
      ? 0
      : location === "comments"
      ? 1
      : 2
  );
  if (!!!task || !!!document) return <></>;
  return (
    <PageLayout
      loading={loading}
      title={`Task #${taskId ? taskId : ""}`}
      badge={
        <Badge
          type={taskStatusesColors[task.status] as any}
          className="fs-6 fw-normal"
        >
          {taskStatuses[task.status]}
        </Badge>
      }
      titleRight={
        <RoleBasedAccess
          allowedRoles={[
            UserRoles.ADMIN,
            UserRoles.SUPERVISOR,
            UserRoles.BO_EXPERT,
          ]}
        >
          {user?.role === UserRoles.BO_EXPERT &&
          shouldPermitBOExpertToManageTask() ? null : (
            <div className="flex-row justify-content-end">
              <TaskActions
                onActionSelected={onChangeAction}
                task={task}
                user={user}
              />
            </div>
          )}
        </RoleBasedAccess>
      }
      backBtn
    >
      <TaskCancelModal
        show={selectedAction === "canceled"}
        onSubmit={cancelTask}
        onDismiss={() => {
          onChangeAction("");
        }}
        changing={cancelingTask}
        taskStatusWithCommentForm={taskCancelForm}
      />
      <TaskPutOnHoldModal
        show={selectedAction === "onhold"}
        onSubmit={putOnHold}
        onDismiss={() => {
          onChangeAction("");
        }}
        changing={puttingOnHold}
        taskStatusWithCommentForm={taskPutOnHoldForm}
      />
      <TaskReassignModal
        currentAssigneeId={task.assignee ? task.assignee.id : undefined}
        show={selectedAction === "assigned"}
        onSubmit={reassignTask}
        onCancel={() => {
          onChangeAction("");
        }}
        reassigning={reassigningTask}
        task={task}
        user={user}
      />
      <RoleBasedAccess
        allowedRoles={[
          UserRoles.ADMIN,
          UserRoles.SUPERVISOR,
          UserRoles.BO_EXPERT,
        ]}
      >
        <TaskMoveToAnotherBasketModal
          show={selectedAction === "changeBasket"}
          onSubmit={moveToAnotherBasket}
          onCancel={() => {
            onChangeAction("");
          }}
          currentBasketId={task.basket.id}
          moving={movingTask}
        />
      </RoleBasedAccess>
      <TaskOtherActionsModal
        show={selectedAction === "inprogress"}
        onSubmit={changeStatus}
        onCancel={() => {
          onChangeAction("");
        }}
        submiting={changingStatus}
        action={selectedAction as "inprogress"}
      />
      <TaskUnassignModal
        show={selectedAction === "unassigned"}
        onSubmit={unassignTask}
        onCancel={() => {
          onChangeAction("");
        }}
        unassigning={unassigning}
        task={task}
        user={user}
      />
      <TaskResolvedModal
        show={selectedAction === "resolved"}
        onSubmit={resolveTask}
        onDismiss={() => {
          onChangeAction("");
        }}
        changing={resolvingTask}
        taskStatusWithCommentForm={taskResolvedForm}
      />
      <TaskBadges task={task} />
      <Tabs tabs={taskTabs} value={selected} setValue={setSelected}>
        <Outlet />
      </Tabs>
    </PageLayout>
  );
};

export default TaskScreen;
