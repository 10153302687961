import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { TableRow } from "../../components/table";
import { useLocation, useSearchParams } from "react-router-dom";
import { Badge } from "../../components/badge";
import { screens } from "../Navigation.types";
import { archiveTableHeaders } from "./Archive.types";

const getURLParamValue = (queryString: string, paramName: string) => {
  const params = new URLSearchParams(queryString);
  return params.get(paramName);
};

export function useArchive() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // documents table states
  const [docTableData, setDocTableData] = React.useState<TableRow[]>([]);
  const [noDataMessage, setNoDataMessage] = React.useState(false);
  const [minTextError, setMinTextError] = React.useState(false);

  // search by id states
  const [searchByIdMode, setSearchByIdMode] = React.useState(false);
  const [searchId, setSearchId] = React.useState("");
  const [noDocumentByIDDataMessage, setNoDocumentByIDDataMessage] =
    React.useState(false);
  const [idSearchError, setIdSearchError] = React.useState(false);
  const [idSearchTableData, setIdSearchTableData] = React.useState<TableRow[]>(
    []
  );

  // initial loading state - when the documents are loading after no data message
  const [initLoading, setInitLoading] = React.useState(false);

  const { execute: getDocument, loading: loadingDocument } = useBaseRequest(
    DocumentGateway.getDocumentById,
    {
      autoFetch: false,
      onCompleted: (data) => {
        if (!data) {
          setIdSearchTableData([]);
          setNoDocumentByIDDataMessage(true);
          return;
        }
        const documents = [data];
        const docTable: TableRow[] = [];
        documents.forEach((doc) => {
          docTable.push([
            doc.id,
            doc.metaData?.jmbg || doc.metaData?.mb || "",
            doc.metaData?.msisdn || "",
            doc.metaData?.signDate
              ? moment(doc.metaData?.signDate).format("DD.MM.YYYY")
              : "",
            doc.docType?.title || "",
            doc.metaData?.archiveType ? (
              <Badge
                variant="outlined"
                type={
                  doc.metaData?.archiveType === "Digital"
                    ? "primary"
                    : "secondary"
                }
              >
                {doc.metaData?.archiveType}
              </Badge>
            ) : (
              ""
            ),
            doc?.metaData?.boxId || "",
            moment(doc?.createdAt).format("DD.MM.YYYY"),
            doc.createdBy?.name || "",
            doc?.dealerCode === doc.createdById.toString()
              ? ""
              : doc.dealerCode || "",
            doc.metaData?.isElectronicallySigned === undefined ||
            doc.metaData?.isElectronicallySigned === null
              ? ""
              : doc.metaData?.isElectronicallySigned
              ? "Yes"
              : "No",
          ]);
        });
        setIdSearchTableData(docTable);
        setNoDocumentByIDDataMessage(false);
      },
    }
  );

  const { data, execute, loading } = useBaseRequest(
    DocumentGateway.getDocumentsArchive,
    {
      autoFetch: false,
      onCompleted: (data) => {
        const { documents } = data;
        const docTable: TableRow[] = [];
        if (documents.length === 0) {
          setNoDataMessage(true);
          setDocTableData([]);
          return;
        }
        documents.forEach((doc) => {
          docTable.push([
            doc.id,
            doc.metaData?.jmbg || doc.metaData?.mb || "",
            doc.metaData?.msisdn || "",
            doc.metaData?.signDate
              ? moment(doc.metaData?.signDate).format("DD.MM.YYYY")
              : "",
            doc.docType?.title || "",
            doc.metaData?.archiveType ? (
              <Badge
                variant="outlined"
                type={
                  doc.metaData?.archiveType === "Digital"
                    ? "primary"
                    : "secondary"
                }
              >
                {doc.metaData?.archiveType}
              </Badge>
            ) : (
              ""
            ),
            doc?.metaData?.boxId || "",
            moment(doc?.createdAt).format("DD.MM.YYYY"),
            doc.createdBy?.name || "",
            doc?.dealerCode === doc.createdById.toString()
              ? ""
              : doc.dealerCode || "",
            doc.metaData?.isElectronicallySigned === undefined ||
            doc.metaData?.isElectronicallySigned === null
              ? ""
              : doc.metaData?.isElectronicallySigned
              ? "Yes"
              : "No",
          ]);
        });
        setDocTableData(docTable);
      },
    }
  );

  const onRowPress = (id: number) => {
    navigate(screens.documents + `/${id}`);
  };

  const toggleSearchByIdMode = () => {
    setSearchByIdMode(!searchByIdMode);
  };

  const handleSearchId = useCallback(
    (id: string) => {
      if (id && isFinite(Number(id))) {
        setSearchId(id);
        getDocument({ id });
        setIdSearchError(false);
      } else if (id && !isFinite(Number(id))) {
        setIdSearchError(true);
      } else {
        setSearchId("");
        setIdSearchTableData([]);
        setNoDocumentByIDDataMessage(false);
      }
    },
    [location.search, searchByIdMode]
  );

  const handleSearchQuery = (text: string) => {
    if (text.length < 6 && text.length > 0) {
      setMinTextError(true);
      return false;
    }
    setMinTextError(false);
    return true;
  };

  useEffect(() => {
    if (!searchByIdMode) {
      const textSearchValue = getURLParamValue(location.search, "textSearch");
      if (
        location.search &&
        (location.search.includes("filters") ||
          (textSearchValue && textSearchValue?.length > 5))
      ) {
        execute(
          location.search && location.search.length > 5 ? location.search : "?"
        );
      } else {
        setSearchParams("");
        setDocTableData([]);
        setNoDataMessage(false);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (loadingDocument || loading) setInitLoading(true);
    else setInitLoading(false);
  }, [loadingDocument, loading]);

  return {
    count: data?.count || 0,
    searchByIdMode,
    searchId,
    docTableData,
    idSearchTableData,
    archiveTableHeaders,
    minTextError,
    idSearchError,
    loading,
    loadingDocument,
    initLoading,
    noDataMessage,
    noDocumentByIDDataMessage,
    getDocument,
    getDocuments: execute,
    onRowPress,
    handleSearchId,
    toggleSearchByIdMode,
    handleSearchQuery,
  };
}
