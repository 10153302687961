import { SearchInputProps } from "./SearchInput.types";
import "./SearchInput.style.css";
import React, { ChangeEvent, FormEvent, useEffect } from "react";

export function SearchInput({
  onSearchSubmit,
  onTextChange,
  placeHolder = "Search anything...",
  className,
  defaultValue = "",
}: SearchInputProps) {
  const [text, setText] = React.useState("");

  useEffect(() => {
    if ((defaultValue === "" || defaultValue) && defaultValue !== text)
      setText(defaultValue);
  }, [defaultValue]);

  const onSearchRequest = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSearchSubmit) {
      onSearchSubmit(text);
    }
  };

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setText(value);
    if (onTextChange) {
      onTextChange(text);
    }
    // note(np): hack to submit search of input cleared
    if (!value && onSearchSubmit) {
      onSearchSubmit("");
    }
  };

  return (
    <form
      onSubmit={onSearchRequest}
      className={`search-form ${className ? className : null}`}
    >
      <i className="fa fa-search"></i>
      <input
        type="search"
        className="form-control search-form-input"
        placeholder={placeHolder}
        value={text}
        onChange={onSearchValueChange}
      />
    </form>
  );
}
