import { Modal } from "react-bootstrap";
import { SplitPdfModalProps } from "./UploadDocument.types";
import { Button } from "../../components/button";

export const SplitPdfModal = ({
  show,
  onDismissUpload,
  onSelectDontSplitFile,
  onSelectSplitFile,
}: SplitPdfModalProps) => {
  return (
    <Modal show={show} animation onHide={onDismissUpload}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Choose an upload method
        </h5>
        <div onClick={onDismissUpload}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body className="flex-column gap-2">
        <Button className="btn btn-primary" onClick={onSelectDontSplitFile}>
          Upload file as single document
        </Button>
        <Button className="btn btn-secondary" onClick={onSelectSplitFile}>
          Split the file and upload it as multiple documents
        </Button>
        <Button className="btn btn-danger" onClick={onDismissUpload}>
          Cancel document upload
        </Button>
      </Modal.Body>
    </Modal>
  );
};
