import Header from "../../components/header/Header";
import { Icon } from "../../modules/icon";
import { AllBasketSectionProps } from "./Basket.types";
import BasketCard from "./BasketCard";
import CardContainer from "../../components/card/CardContainer";
import { Button } from "../../components/button";
import { useNavigate } from "react-router-dom";
import { RoleBasedAccess } from "../../components/guard";
import { UserRoles } from "../../entities/IUser";

const AllBaskets = ({
  baskets,
  onBookmark,
  onDelete,
  onEdit,
  showCreateBasketModalHandler,
}: AllBasketSectionProps) => {
  const navigate = useNavigate();
  return (
    <section className="flex-column gap-2">
      <Header
        title="All Baskets"
        size="h5"
        actions={
          <RoleBasedAccess allowedRoles={[UserRoles.ADMIN]}>
            <Button
              className="btn btn-secondary"
              onClick={showCreateBasketModalHandler}
            >
              <Icon type="fa fa-plus" />
              &nbsp; Create new basket
            </Button>
          </RoleBasedAccess>
        }
      />
      <CardContainer
        children={[
          ...baskets.map((item, i) => (
            <BasketCard
              key={i}
              onClick={() => {
                navigate(`/baskets/${item.id}`);
              }}
              onBookmark={onBookmark}
              onDelete={onDelete}
              onEdit={onEdit}
              name={item.name}
              numberOfTasks={item.numberOfTasks}
              id={item.id}
              isBookmarked={item.isBookmarked}
            />
          )),
        ]}
        cardGap={16}
        containerPaddingX={5}
        cardMinWidth={128}
        cardMaxWidth={208}
        cardMaxNumberPerRow={10}
      />
    </section>
  );
};

export default AllBaskets;
