import React from "react";
import { SocketOptions } from "./Socket.types";
import SocketIO from "./utility/SocketIO";

export function useSocket(url: string, options: SocketOptions = {}) {
  const { connection = {}, listeners, autoConnect = true, room } = options;

  const connect = async () => {
    try {
      await SocketIO.connect(url, connection);
      if (room) {
        SocketIO.joinRoom(room);
      }
      if (listeners) {
        listeners.forEach((l) => SocketIO.addListener(l.event, l.callback));
      }
    } catch (error) {
      console.log("CONNECTION ERROR");
    }
  };

  const disconnect = () => {
    SocketIO.disconnect();
  };

  const joinRoom = (newRoom?: string) => {
    const roomToJoin = newRoom || room;
    if (!roomToJoin) {
      return;
    }
    SocketIO.joinRoom(roomToJoin);
  };

  React.useEffect(() => {
    if (autoConnect) {
      connect();
    }
    return () => {
      disconnect();
    };
  }, []);

  return { connect, disconnect, joinRoom };
}
