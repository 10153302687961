import Header from "../../components/header/Header";
import { BasketSectionProps } from "./Basket.types";
import BasketCard from "./BasketCard";
import CardContainer from "../../components/card/CardContainer";
import { useNavigate } from "react-router-dom";

const BookmarkedBaskets = ({
  baskets,
  onBookmark,
  onDelete,
  onEdit,
}: BasketSectionProps) => {
  const navigate = useNavigate();
  return (
    <>
      {!!baskets.length && (
        <section className="flex-column gap-2">
          <Header title="Bookmarked Baskets" size="h5" />
          <CardContainer
            children={[
              ...baskets.map((item, i) => (
                <BasketCard
                  key={i}
                  onBookmark={onBookmark}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onClick={() => {
                    navigate(`/baskets/${item.id}`);
                  }}
                  name={item.name}
                  numberOfTasks={item.numberOfTasks}
                  id={item.id}
                  isBookmarked={item.isBookmarked}
                />
              )),
            ]}
            cardGap={16}
            containerPaddingX={5}
            cardMinWidth={128}
            cardMaxWidth={208}
            cardMaxNumberPerRow={10}
            isInline
          />
        </section>
      )}
    </>
  );
};

export default BookmarkedBaskets;
