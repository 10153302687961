import { Button } from "../../components/button";
import { FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { Icon } from "../../modules/icon";
import { AddToBoxModal } from "./AddToBoxModal";
import { documentFilters } from "./Documents.types";
import { useDocuments } from "./useDocuments";

export function DocumentsScreen() {
  const {
    docTableData,
    user,
    count,
    setSelectedDocuments,
    selectedDocuments,
    showBoxModal,
    onAddBoxId,
    closeBoxModal,
    openBoxModal,
    updating,
    documentTableHeaders,
    loading,
    onRowPress,
  } = useDocuments();

  return (
    <PageLayout
      title={`Documents - ${
        user?.dealerCode === user?.id.toString()
          ? "Uploaded by you"
          : `POS/Dealer #${user?.dealerCode}`
      }`}
    >
      <Row>
        <Column>
          {selectedDocuments && selectedDocuments.length ? (
            <Button className="btn btn-secondary" onClick={openBoxModal}>
              <Icon type="fa-solid fa-box" />
              &nbsp; Add to box ({selectedDocuments.length})
            </Button>
          ) : null}
          <AddToBoxModal
            show={showBoxModal}
            documentIds={selectedDocuments}
            onAdd={onAddBoxId}
            onCancel={closeBoxModal}
            adding={updating}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <div className="mt-4 mb-1">
            <FiltersWithQuery filters={documentFilters} />
          </div>
        </Column>
        <Column>
          <div className="mt-4 mb-1">
            <div className="d-flex flex-row my-1">
              <SearchWithQueryInput
                placeHolder="Search documents..."
                className="ml-auto"
              />
            </div>
          </div>
        </Column>
      </Row>
      <Row>
        <Column>
          <div className="my-2 w-100">
            <TableWithQueryPaginationAndSort
              headers={documentTableHeaders}
              data={docTableData}
              totalItems={count}
              onRowPress={onRowPress}
              selection={{
                onSelectionChanged: setSelectedDocuments,
                selected: selectedDocuments,
              }}
              loading={loading}
            />
          </div>
        </Column>
      </Row>
    </PageLayout>
  );
}
