import { PageLayout } from "../../components/page-layout";
import useArchiveStats from "./useArchiveStats";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";
import StatCard from "../../components/stat-card/StatCard";
import StatNumberCard from "../../components/stat-number-card/StatNumberCard";
import { StandaloneDateRangeFilter } from "../../components/filters/DateRangeFilter/StandaloneDateRangeFilter";
import { Button } from "../../components/button";
import { Icon } from "../../modules/icon";

const ArchiveStatsScreen = () => {
  const {
    total,
    residential,
    business,
    loading,
    onChangeDateRange,
    onChangePoS,
    defaultPoS,
    defaultDateRange,
    exportAStats,
    exporting,
  } = useArchiveStats();
  return (
    <PageLayout
      loading={loading}
      title={"Archive Dashboard"}
      titleRight={
        <Button
          className="btn btn-success"
          onClick={exportAStats}
          isLoading={exporting}
        >
          <Icon type="fa-solid fa-file-export" />
          &nbsp; Export
        </Button>
      }
    >
      <div className="flex-column gap-3">
        <div
          className="flex-row no-wrap gap-2 flex-1"
          style={{ alignItems: "end" }}
        >
          <AutocompleteOpt
            label="PoS"
            placeholder="Select dealers"
            onSelectOption={(option) => {
              if (Array.isArray(option) || option === undefined)
                onChangePoS(option?.map((item) => item.value));
            }}
            defaultValue={defaultPoS}
            isMultiple
            endpoint={"/dealers/search-autocomplete"}
          />
          <StandaloneDateRangeFilter
            onSet={(value) => {
              onChangeDateRange(value);
            }}
            defaultVal={defaultDateRange}
          />
        </div>
        <StatNumberCard title={"Archived Documents"} total={total} />
        <div className="flex-row gap-2 flex-wrap">
          {business && <StatCard {...business} />}
          {residential && <StatCard {...residential} />}
        </div>
      </div>
    </PageLayout>
  );
};
export default ArchiveStatsScreen;
