import { PageLayout } from "../../components/page-layout";
import useTaskStats from "./useTaskStats";
import AutocompleteOpt from "../../components/autocomplete-opt/AutocompleteOpt";
import StatNumberCard from "../../components/stat-number-card/StatNumberCard";
import CardContainer from "../../components/card/CardContainer";
import StatCard from "../../components/stat-card/StatCard";
import { StandaloneDateRangeFilter } from "../../components/filters/DateRangeFilter/StandaloneDateRangeFilter";

const TaskStatsScreen = () => {
  const {
    loading,
    onChangeDateRange,
    onChangeBasket,
    defaultBasket,
    defaultDateRange,
    statUrgentTaskNumbers,
    statStatusTaskNumbers,
    basketsStat,
    statAHT,
    statART,
  } = useTaskStats();
  return (
    <PageLayout title={"Task Dashboard"}>
      <div className="flex-column gap-3">
        <div
          className="flex-row no-wrap gap-2 flex-1"
          style={{ alignItems: "end" }}
        >
          <AutocompleteOpt
            label="Baskets"
            placeholder="Select baskets"
            onSelectOption={(option) => {
              if (Array.isArray(option) || option === undefined)
                onChangeBasket(option?.map((item) => item.value));
            }}
            defaultValue={defaultBasket}
            isMultiple
            endpoint={"/baskets/search-autocomplete"}
            minCharsToSearch={0}
            limit={1000}
          />

          <StandaloneDateRangeFilter
            onSet={(value) => {
              onChangeDateRange(value);
            }}
            defaultVal={defaultDateRange}
          />
        </div>
        <section className="flex-column gap-1">
          <h6
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title={"Date filter doesn't apply here."}
          >
            By Urgency *
          </h6>
          <div className="flex-row flex-wrap gap-2 w-100">
            {statUrgentTaskNumbers?.map((item, i) => (
              <StatNumberCard {...item} key={i} />
            ))}
          </div>
        </section>
        <section className="flex-column gap-1">
          <h6>By Status</h6>
          <div className="flex-row flex-wrap gap-2 w-100">
            {statStatusTaskNumbers?.map((item, i) => (
              <StatNumberCard {...item} key={i} />
            ))}
          </div>
        </section>
        <section className="flex-column gap-1">
          <h6>Time stats</h6>
          <div className="flex-row flex-wrap gap-2 w-100">
            {statAHT && <StatNumberCard {...statAHT} />}
            {statART && <StatNumberCard {...statART} />}
          </div>
        </section>
        <section className="flex-column gap-1">
          <h6>Basket stats</h6>
          {basketsStat && (
            <CardContainer
              children={[
                ...basketsStat.map((item, i) => (
                  <StatCard
                    legendOptions={{
                      isVisible: true,
                      position: "bottom",
                      total: { title: "Total", isVisible: true },
                    }}
                    {...item}
                    key={i}
                  />
                )),
              ]}
              cardGap={16}
              cardMinWidth={240}
              containerPaddingX={5}
              cardMaxNumberPerRow={4}
            />
          )}
        </section>
      </div>
    </PageLayout>
  );
};
export default TaskStatsScreen;
