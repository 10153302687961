import { ITaskComment } from "../components/comments";
import { Filter } from "../components/filters";
import { TableHeaders } from "../components/table";
import { IDbEntity } from "./IDbEntity";

export interface ITask extends IDbEntity {
  taskTypeCode: TaskTypeCode;
  docTypeDoc: string;
  description: string;
  status: TaskStatuses;
  document: {
    id: number;
    metaData: {
      msisdn: string;
      mb?: string;
      documentType: string;
      documentCategory: string;
      jmbg?: string;
    };
  };
  assignee: {
    id: number;
    name: string;
  };
  createdBy: {
    id: number;
    name: string;
  };
  basket: {
    id: number;
    name: string;
  };
  basketId: number;
  customerName?: string;
  comments: ITaskComment[];
  complaintNumber?: string;
  complaintMSISDN?: string;
}

export type TaskStatus = keyof typeof taskStatuses;
export type TaskTypeCode = keyof typeof AccountingPeriods;

export enum TaskStatuses {
  OPEN = "open",
  ASSIGNED = "assigned",
  INPROGRESS = "inprogress",
  ONHOLD = "onhold",
  RESOLVED = "resolved",
  CANCELED = "canceled",
}

export const tasksTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "JMBG/MB", key: "customerIdentityNumber" },
  { name: "MSISDN", key: "metaData.msisdn" },
  { name: "Type", key: "docTypeDoc" },
  { name: "Billing cycle", key: "taskTypeCode" },
  { name: "Status", key: "status", sortable: true },
  { name: "Assigned to", key: "assignee.name" },
  { name: "Basket", key: "basket.name" },
  { name: "Complaint Nº", key: "complaintNumber" },
  { name: "Customer name", key: "customerName" },
  { name: "Created by", key: "createdBy.name" },
  { name: "Created date", key: "createdAt", sortable: true },
  { name: "Actions", key: "action" },
];

export const AccountingPeriods = {
  bus_c42: "C42",
  res_c17: "C17",
  res_c21: "C21",
  res_c25: "C25",
  res_c43: "C43",
  res_c45: "C45",
  res_c5: "C5",
};

export const TASK_SUCCESS_MESSAGES = {
  created: "Task successfully created!",
};
export interface CreateTaskDTO {
  documentId: number;
  basketId: number | string;
  taskTypeCode: keyof typeof AccountingPeriods | "";
  description?: string;
  customerName: string;
  isComplaint: boolean;
  complaintMSISDN: string;
}
export interface IGetTasksByBasketIdApi {
  count: number;
  tasks: ITask[];
  basketName: string;
}

export interface IGetTasksApi {
  count: number;
  tasks: ITask[];
  baskets: {
    id: number;
    name: string;
  }[];
}

export const tableFilters: Filter[] = [
  {
    type: "single-choice",
    name: "Show only mine tasks",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "myTasks",
        type: "string",
        operator: values[0] === "yes" ? "ne" : "eq",
        value: null,
      };
    },
  },
];

export const taskStatuses = {
  open: "Open",
  assigned: "Assigned",
  inprogress: "In Progress",
  onhold: "On Hold",
  resolved: "Resolved",
  canceled: "Canceled",
};

export const taskStatusesColors = {
  open: "unassigned",
  assigned: "assigned",
  inprogress: "inprogress",
  onhold: "onhold",
  resolved: "success",
  canceled: "closed",
};

export interface AddCommentToTaskDTO {
  taskId: number;
  text: string;
}

export interface GetTaskCommentDTO {
  count: number;
  comments: ITaskComment[];
}

export interface TaskReassignDTO {
  id: number;
  userId: number;
}

export interface TaskChangeStatusDTO {
  id: number;
  status: "resolved" | "onhold" | "inprogress";
}

export interface TaskMoveToAnotherBasketDTO {
  id: number;
  basketId: number;
}

export interface GetTaskStatsResponse {
  totalOlderThen8: number;
  totalOlderThen6: number;
  totalUnassigned: number;
  totalAssigned: number;
  totalResolved: number;
  aht: number;
  art: number;
  baskets: {
    name: string;
    total: number;
    resolved: number;
    inprogress: number;
    onhold: number;
    assigned: number;
    unassigned: number;
  }[];
}

export interface TaskBulkReassignDTO {
  taskIds: number[];
  data: {
    userId: number;
  };
}

export interface TaskBulkMoveToAnotherBasketDTO {
  taskIds: number[];
  data: {
    basketId: number;
  };
}

export interface UpdateTaskDTO {
  id: number;
  description: string;
  taskTypeCode: string;
  customerName: string;
}

export enum TaskLogTemplates {
  BasketChanged = "Moved into basket {basket} by {updatedBy}",
  DescriptionChanged = "Task description updated to {description} by {updatedBy}",
  TaskTypeChnged = "Task type updated to {taskType} by {updatedBy}",
  Activated = "Activated by {updatedBy}",
  Unassigned = "Unassigned by {updatedBy}",
  Reassigned = "Reassigned to {user} by {updatedBy}",
  Assigned = "Assigned to {user} by {updatedBy}",
  Canceled = "Canceled by {updatedBy}",
  Opened = "Opened by {updatedBy}",
  PutOnHold = "Put on hold by {updatedBy}",
  Resolved = "Resolved by {updatedBy}",
}
