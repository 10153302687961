import { DeleteDigitalSignatureModal } from "../../components/delete-digital-signature";
import { PageLayout } from "../../components/page-layout";
import { DigitalSignatures } from "./DigitalSignatures";
import { useDigitalSignature } from "./useDigitalSignature";

export const DigitalSignatureScreen = () => {
  const {
    documents,
    onResend,
    loading,
    showDeleteModal,
    onStartDelete,
    closeDeleteModal,
    onDelete,
  } = useDigitalSignature();
  return (
    <PageLayout title={"Pending Signatures"} loading={loading}>
      <DigitalSignatures
        documents={documents}
        onDelete={onStartDelete}
        onResend={onResend}
      />
      <DeleteDigitalSignatureModal
        show={showDeleteModal}
        onDelete={onDelete}
        onCancel={closeDeleteModal}
      />
    </PageLayout>
  );
};
