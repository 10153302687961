import { useState, useEffect } from "react";
import { Icon } from "../../modules/icon";
import { SideNavMenuItemProps } from "./SideNav.types";
import { Badge } from "../badge";
import { RoleBasedAccess } from "../guard";

export function SideNavMenuItem({
  menuItem,
  activeRoute,
  isOpen,
  navigate,
}: SideNavMenuItemProps) {
  const [toggle, setToggle] = useState(
    menuItem.children?.length &&
      menuItem.children?.find((item) => item.value === activeRoute)
      ? true
      : false
  );

  const navigateTo = () => {
    if (typeof menuItem.path === "string") {
      navigate({ pathname: menuItem.path, search: menuItem.query });
    }
    if (menuItem.children?.length) setToggle((prev) => !prev);
  };

  return (
    <div
      className={`side-nav-menu-item flex-column
      ${isOpen ? "" : "justify-content-center"}
      ${
        menuItem.value === activeRoute && !menuItem.children?.length
          ? " active"
          : ""
      }
      `}
      style={{
        paddingLeft: !menuItem.children?.length && isOpen ? "30px" : "0px",
        borderBottom:
          menuItem.children?.length && toggle ? "1px solid #d5d5d5" : "none",
      }}
    >
      <div className="flex-row w-100  align-items-center" onClick={navigateTo}>
        <div className="flex-row  align-items-center w-100">
          {menuItem.icon && (
            <div className={`side-nav-menu-item-icon${isOpen ? "" : " mx-0"}`}>
              <Icon type={menuItem.icon} size={18} />
            </div>
          )}
          <div className={`side-nav-menu-item-label`}>
            {menuItem.children || isOpen ? (
              <span className="side-nav-menu-item-label-text flex-row justify-content-between">
                {menuItem.label}
                <Badge
                  title="Number of active tasks"
                  className="mr-2"
                  type={"secondary"}
                  variant="outlined"
                >
                  {menuItem.counter}
                </Badge>
                {menuItem.pilot ? (
                  <Badge type="danger" variant="outlined" className="mr-2">
                    PILOT
                  </Badge>
                ) : null}
              </span>
            ) : (
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                className="side-nav-menu-item-label-text text-center fw-bold"
                title={menuItem.label}
              >
                {menuItem.label[0]}
              </span>
            )}
          </div>
        </div>
        {menuItem.children && isOpen && (
          <Icon
            type={`fa-solid fa-caret-${toggle ? "up" : "down"}`}
            size={18}
          />
        )}
      </div>
      {toggle && menuItem.children && (
        <div
          style={{
            width: "100%",
          }}
        >
          {menuItem.children.map((item, i) => (
            <RoleBasedAccess allowedRoles={item.allowedRoles} key={i}>
              <SideNavMenuItem
                menuItem={item}
                activeRoute={activeRoute}
                isOpen={isOpen}
                navigate={navigate}
              />
            </RoleBasedAccess>
          ))}
        </div>
      )}
    </div>
  );
}
