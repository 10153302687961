import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { AuthGuard, RoleBasedAccess } from "../components/guard";
import { SideNav } from "../components/side-nav";
import { ArchiveScreen } from "./archive";
import { DocumentScreen } from "./document";
import { DocumentsScreen } from "./documents";
import { LoginScreen } from "./login";
import { screens } from "./Navigation.types";
import { NewDocumentScreen } from "./new-document";
import { SearchScreen } from "./search";
import { UploadDocumentScreen } from "./upload-document";
import { BasketsScreen } from "./baskets";
import BasketTasksScreen from "./basket-tasks/BasketTasksScreen";
import TaskScreen from "./task/TaskScreen";
import ArchiveStatsScreen from "./archive-stats/ArchiveStatsScreen";
import TaskStatsScreen from "./task-stats/TaskStatsScreen";
import TasksScreen from "./tasks/TasksScreen";
import { ReportsScreen } from "./reports";
import TaskDetails from "./task/TaskDetails";
import TaskComments from "./task/TaskComments";
import TaskLogs from "./task/TaskLogs";
import MyTasksScreen from "./my-tasks/MyTasksScreen";
import QueryAppender from "../components/query-appender/QueryAppender";
import { UserRoles } from "../entities/IUser";
import { UploadDocumentsSessionScreen } from "./upload-documents-session";
import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { filterActions } from "../store/filters/FilterReducer";
import { SplitFileScreen } from "./split-file";
import { DigitalSignatureScreen } from "./digital-signature";

export default function Navigation() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    dispatch(
      filterActions.setFilterByPage({
        page: location.pathname,
        filter: queryParams.toString(),
      })
    );
  }, [location]);

  return (
    <Routes>
      <Route
        path=""
        element={
          <AuthGuard>
            <SideNav />
          </AuthGuard>
        }
      >
        <Route index path="" element={<Navigate to={screens.documents} />} />
        <Route path={screens.documents}>
          <Route
            path=""
            element={
              <QueryAppender queryKey={screens.documents}>
                <DocumentsScreen />
              </QueryAppender>
            }
          />
          <Route path=":id" element={<DocumentScreen />} />
        </Route>
        <Route
          path={screens.uploadDocument}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
                UserRoles.SALES,
              ]}
              navigateTo={screens.root}
            >
              <UploadDocumentScreen />
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.newDocument}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
                UserRoles.SALES,
              ]}
              navigateTo={screens.root}
            >
              <NewDocumentScreen />
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.taskStats}
          element={
            <RoleBasedAccess
              allowedRoles={[UserRoles.ADMIN, UserRoles.SUPERVISOR]}
              navigateTo={screens.root}
            >
              <QueryAppender queryKey={screens.taskStats}>
                <TaskStatsScreen />
              </QueryAppender>
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.baskets}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
              ]}
              navigateTo={screens.root}
            >
              <BasketsScreen />
            </RoleBasedAccess>
          }
        />
        {/* <Route path={screens.basketRules} element={<BasketRulesScreen />} />*/}
        <Route
          path={screens.basketTasks}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
              ]}
              navigateTo={screens.root}
            >
              <QueryAppender queryKey={screens.basketTasks}>
                <BasketTasksScreen />
              </QueryAppender>
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.archive}
          element={
            <QueryAppender queryKey={screens.archive}>
              <ArchiveScreen />
            </QueryAppender>
          }
        />
        <Route path={screens.search} element={<SearchScreen />} />
        <Route
          path={screens.myTasks}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
              ]}
              navigateTo={screens.root}
            >
              <QueryAppender queryKey={screens.myTasks}>
                <MyTasksScreen />
              </QueryAppender>
            </RoleBasedAccess>
          }
        />
        <Route path={screens.task.root} element={<TaskScreen />}>
          <Route path={screens.task.details} element={<TaskDetails />} />
          <Route path={screens.task.comments} element={<TaskComments />} />
          <Route path={screens.task.logs} element={<TaskLogs />} />
          <Route
            index
            element={<Navigate to={screens.task.details} replace />}
          />
        </Route>
        <Route
          path={screens.tasks}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
              ]}
              navigateTo={screens.root}
            >
              <QueryAppender queryKey={screens.tasks}>
                <TasksScreen />
              </QueryAppender>
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.archiveStats}
          element={
            <RoleBasedAccess
              allowedRoles={[UserRoles.ADMIN, UserRoles.SUPERVISOR]}
              navigateTo={screens.root}
            >
              <QueryAppender queryKey={screens.archiveStats}>
                <ArchiveStatsScreen />
              </QueryAppender>
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.reports}
          element={
            <RoleBasedAccess
              allowedRoles={[UserRoles.ADMIN, UserRoles.SUPERVISOR]}
              navigateTo={screens.root}
            >
              <QueryAppender queryKey={screens.reports}>
                <ReportsScreen />
              </QueryAppender>
            </RoleBasedAccess>
          }
        />
        {/* <Route path={screens.document} element={<DocumentScreen />} /> */}
        <Route
          path={screens.uploadSession}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
                UserRoles.SALES,
              ]}
              navigateTo={screens.root}
            >
              <UploadDocumentsSessionScreen />
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.splitFile}
          element={
            <RoleBasedAccess
              allowedRoles={[
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.BO_EXPERT,
                UserRoles.SALES,
              ]}
              navigateTo={screens.root}
            >
              <SplitFileScreen />
            </RoleBasedAccess>
          }
        />
        <Route
          path={screens.digitalSignature}
          element={
            <QueryAppender queryKey={screens.digitalSignature}>
              <DigitalSignatureScreen />
            </QueryAppender>
          }
        />
      </Route>
      <Route path={screens.login} element={<LoginScreen />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
