import { SelectAction } from "../../components/select";
import { DocumentActionsProps } from "./Document.types";
import { useDocumentActions } from "./useDocumentActions";

export function DocumentActions({
  onActionSelected,
  document,
  user,
}: DocumentActionsProps) {
  const { getActions } = useDocumentActions();
  return (
    <SelectAction
      defaultLabel="Document Actions"
      options={getActions(document, user)}
      onChange={onActionSelected}
    />
  );
}
