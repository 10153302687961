import React, { ChangeEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import useBaseRequest from "../../api/BaseRequest";
import DocumentGateway from "../../api/gateways/DocumentGateway";
import { GenericForm } from "../../components/generic-form/GenericForm.types";
import { useToast } from "../../components/toast";
import {
  DocumentStatus,
  IDocument,
  UpdateDocumentDTO,
} from "../../entities/IDocument";
import { MetaDataForm } from "../../entities/IMetaData";
import { useWindowDimensions } from "../../modules/window";
import { useDocumentService } from "../../services/useDocumentService";
import { screens } from "../Navigation.types";
import { CreateTaskDTO } from "../../entities/ITask";
import { createTaskForm as createTaskFormDefault } from "../../components/create-task";
import OptionListGateway from "../../api/gateways/OptionListGateway";
import BasketGateWay from "../../api/gateways/BasketGateway";
import { useParams } from "react-router-dom";
import { GenericFormValidator } from "../../components/generic-form/utility/GenericFormValidator";
import { CreateDigitalSignatureRequestDTO } from "../../entities/IDigitalSignature";
import { requestDigitalSignatureForm as requestDigitalSignatureFormDefault } from "../../components/request-digital-signature";

let isRequestSignature = false;
let isTaskCreating = false;

export function useNewDocument(
  documentId?: string,
  closeDocumentModal?: (isDocumentCreated?: boolean) => void
) {
  // states
  const id = useParams()?.id;
  const sessionId = useParams()?.sessionId;
  const navigate = useNavigate();
  const { success, danger } = useToast();
  const { mapMetaDataRulesToForm, useGetDocumentTypes, getDocFileUrl } =
    useDocumentService();
  const { height: windowHeight } = useWindowDimensions();
  const { documentTypes } = useGetDocumentTypes();
  const [documentType, setDocumentType] = React.useState("");
  const [metaDataForm, setMetaDataForm] =
    React.useState<GenericForm<MetaDataForm> | null>(null);
  const [showDismissModal, setShowDismissModal] = React.useState(false);

  React.useEffect(() => {
    if (documentId) {
      getDraft({
        id: documentId,
      });
      return;
    }
    if (id) {
      getDraft({
        id,
      });
    }
  }, [id, documentId]);

  // requests
  const { execute: processDocument, loading: processing } = useBaseRequest(
    DocumentGateway.processDocument,
    {
      onCompleted: (data) => {
        if (!data || !data.length) {
          setMetaDataForm(null);
          return;
        }
        const form = mapMetaDataRulesToForm(data);
        setMetaDataForm(form);
      },
    }
  );

  const { execute: updateDoc, loading: creating } = useBaseRequest(
    DocumentGateway.updateDocument,
    {
      onCompleted: (data, payload) => {
        success("Document uploaded.");
        navigate(screens.documents + `/${data.id}`, {
          replace: true,
        });
      },
      onError: (error) => {
        danger(
          error?.message || "Something went wrong. Please try again later."
        );
      },
    }
  );

  const { execute: updateSessionDoc, loading: creatingSessionDoc } =
    useBaseRequest(DocumentGateway.updateSessionDocument, {
      onCompleted: () => {
        success("Document uploaded.");
        closeDocumentModal && closeDocumentModal(true);
      },
      onError: (error) => {
        danger(
          error?.message || "Something went wrong. Please try again later."
        );
      },
    });

  const {
    execute: getDraft,
    loading: fetching,
    data: draftDocument,
  } = useBaseRequest(DocumentGateway.getDocumentByIdAndMetaData, {
    onCompleted: async (data) => {
      if (
        !data ||
        !data.document ||
        !data.document.files ||
        !data.document.files.length
      ) {
        navigate(screens.uploadDocument);
        return;
      }
      if (data.document.docTypeCode) {
        setDocumentType(data.document.docTypeCode);
      }
      if (data.metaDataRules) {
        const form = mapMetaDataRulesToForm(
          data.metaDataRules,
          data.document.metaData
        );
        setMetaDataForm(form);
      }
    },
  });

  const { execute: dismiss } = useBaseRequest(DocumentGateway.dismissDocument, {
    onCompleted: (data) => {
      closeModal();
      navigate(-1);
    },
  });

  //handlers
  const onDocumentTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setDocumentType(value);
    if (!value) {
      setMetaDataForm(null);
      return;
    }
    processDocument({
      docTypeCode: value,
      id: (draftDocument?.document?.id || "").toString(),
    });
  };

  const setIsTaskCreating = (value: boolean) => {
    isTaskCreating = value;
  };
  const onSubmitDraft = (mtdForm: MetaDataForm) => {
    const document = {
      id: (draftDocument?.document?.id || "").toString(),
      metaData: mtdForm,
      documentTypeCode: documentType,
      status: DocumentStatus.PENDING,
    };
    if (isTaskCreating) {
      if (!document?.documentTypeCode) return;
      validateDocument(document);
      return;
    }
    if (isRequestSignature) {
      if (!document?.documentTypeCode) return;
      validateDocument(document);
      return;
    }
    if (sessionId) {
      updateSessionDoc({ ...document, sessionId });
      return;
    }
    updateDoc(document);
  };
  const onSubmitError = () => {
    isTaskCreating = false;
    isRequestSignature = false;
  };

  const closeModal = () => setShowDismissModal(false);
  const openModal = () => setShowDismissModal(true);

  const onDismiss = () => {
    if (!draftDocument) return;
    dismiss({ id: draftDocument?.document?.id?.toString() || "" });
  };

  //Upload document and create task
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const closeCreateTaskModal = () => setShowCreateTaskModal(false);
  const openCreateTaskModal = () => setShowCreateTaskModal(true);

  const { execute: getAllBaskets, data: allBaskets } = useBaseRequest(
    OptionListGateway.getOptions,
    {
      autoFetch: true,
      initialPayload: {
        textSearch: "",
        skip: 0,
        limit: 1000,
        endpoint: "/baskets/search-autocomplete",
      },
    }
  );

  const { execute: getRecommendedBaskets, data: recommendedBaskets } =
    useBaseRequest(BasketGateWay.getBasketRules, {});

  const [documentPayload, setDocumentPayload] = useState<UpdateDocumentDTO>();
  const {
    execute: uploadDocumentAndCreateTask,
    loading: uploadingDocumentAndCreatingTask,
  } = useBaseRequest(DocumentGateway.createDocumentAndTask, {
    onCompleted: (data) => {
      success("Document uploaded and task created.");
      navigate(screens.documents + `/${data.document.id}`, {
        replace: true,
      });
    },
  });

  const {
    execute: uploadSessionDocumentAndCreateTask,
    loading: uploadingSessionDocumentAndCreatingTask,
  } = useBaseRequest(DocumentGateway.createSessionDocumentAndTask, {
    onCompleted: (data) => {
      success("Document uploaded and task created.");
      closeDocumentModal && closeDocumentModal(true);
    },
  });
  const uploadDocumentAndCreateTaskHandler = (taskPayload: CreateTaskDTO) => {
    if (documentPayload) {
      if (sessionId) {
        uploadSessionDocumentAndCreateTask({
          documentPayload: { ...documentPayload!, sessionId },
          taskPayload,
        });
        return;
      }
      uploadDocumentAndCreateTask({ documentPayload, taskPayload });
    }
  };

  const { data: accountingPeriods } = useBaseRequest(
    DocumentGateway.getAllAccountingPeriods,
    {
      autoFetch: true,
      initialPayload: {},
    }
  );
  const createTaskForm = useMemo(() => {
    return {
      ...createTaskFormDefault,
      documentId: {
        ...createTaskFormDefault.documentId,
        defaultValue: documentPayload?.id || -1,
      },
      taskTypeCode: {
        ...createTaskFormDefault.taskTypeCode,
        disabledIf() {
          return !(
            accountingPeriods &&
            accountingPeriods?.filter(
              (item) =>
                item.group.documentCategory ===
                documentPayload?.metaData?.documentCategory
            )?.length > 1
          );
        },
        defaultValue:
          accountingPeriods &&
          accountingPeriods?.filter(
            (item) =>
              item.group.documentCategory ===
              documentPayload?.metaData?.documentCategory
          )?.length > 1
            ? ("" as any)
            : accountingPeriods?.filter(
                (item) =>
                  item.group.documentCategory ===
                  documentPayload?.metaData?.documentCategory
              )[0]?.code,
        options: [
          { label: "Select an billing cycle", value: "" },
          ...(accountingPeriods
            ?.filter(
              (item) =>
                item.group.documentCategory ===
                documentPayload?.metaData?.documentCategory
            )
            ?.map((item) => ({ value: item.code, label: item.title })) || []),
        ],
      },
      basketId: {
        ...createTaskFormDefault.basketId,
        options: [
          { label: "Select a basket", value: "" },
          ...(recommendedBaskets?.basketRules?.length
            ? recommendedBaskets?.basketRules.map((item) => ({
                label: item.basket.name,
                value: item.basket.id.toString(),
              }))
            : allBaskets?.data?.map((item) => ({
                label: item.label,
                value: item.value.toString(),
              })) || []),
        ],
      },
      customerName: {
        ...createTaskFormDefault.customerName,
        visibleIf() {
          return documentPayload?.metaData?.documentCategory === "Business";
        },
        validators:
          documentPayload?.metaData?.documentCategory === "Business"
            ? [GenericFormValidator.required()]
            : null,
      },
      isComplaint: {
        ...createTaskFormDefault.isComplaint,
        visibleIf() {
          return documentPayload?.metaData?.documentType === "Complaint";
        },
      },
      complaintMSISDN: {
        ...createTaskFormDefault.complaintMSISDN,
        defaultValue: new RegExp(/^06\d{7,8}$/).test(
          documentPayload?.metaData?.msisdn || ""
        )
          ? documentPayload?.metaData?.msisdn?.replace("0", "+381") || ""
          : "",
      },
    };
  }, [
    documentPayload?.id,
    documentPayload?.metaData?.documentCategory,
    accountingPeriods,
    recommendedBaskets?.basketRules,
    allBaskets?.data,
  ]);

  const { execute: validateDocument, loading: validating } = useBaseRequest(
    DocumentGateway.validateDocument,
    {
      onCompleted: (data, payload) => {
        if (!data.errors?.length) {
          setDocumentPayload(payload);
          getRecommendedBaskets({
            filters: {
              limit: 1000,
              filters: [
                { name: "docTypeCode", value: payload.documentTypeCode },
              ],
            },
          });
          isTaskCreating ? openCreateTaskModal() : openSignatureModal();
          isTaskCreating = false;
          isRequestSignature = false;
          return;
        }
        danger(
          data.errors[0] || "Something went wrong. Please try again later."
        );
      },
    }
  );

  // Upload document and request signature
  const setIsRequestSignature = (value: boolean) => {
    isRequestSignature = value;
  };
  const {
    execute: uploadDocumentAndRequestSignature,
    loading: uploadingDocumentAndRequestingSignature,
  } = useBaseRequest(DocumentGateway.createDocumentAndRequestSignature, {
    onCompleted: (data) => {
      success("Document uploaded and task created.");
      navigate(screens.documents + `/${data.document.id}`, {
        replace: true,
      });
    },
  });

  const {
    execute: uploadSessionDocumentAndRequestSignature,
    loading: uploadingSessionDocumentAndRequestingSignature,
  } = useBaseRequest(DocumentGateway.createSessionDocumentAndRequestSignature, {
    onCompleted: (data) => {
      success(
        "Request for digital signing successfully sent. Document successfully created."
      );
      closeDocumentModal && closeDocumentModal(true);
    },
  });
  const uploadDocumentAndRequestSignatureHandler = (
    signaturePayload: CreateDigitalSignatureRequestDTO
  ) => {
    if (documentPayload) {
      if (sessionId) {
        uploadSessionDocumentAndRequestSignature({
          documentPayload: { ...documentPayload!, sessionId },
          signaturePayload,
        });
        return;
      }
      uploadDocumentAndRequestSignature({ documentPayload, signaturePayload });
    }
  };

  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const closeSignatureModal = () => setShowSignatureModal(false);
  const openSignatureModal = () => setShowSignatureModal(true);
  const requestDigitalSignatureForm = useMemo(() => {
    return {
      ...requestDigitalSignatureFormDefault,
      documentId: {
        ...requestDigitalSignatureFormDefault.documentId,
        defaultValue: documentPayload?.id || -1,
      },
    };
  }, [documentPayload]);
  return {
    draftDocument: draftDocument?.document,
    documentType,
    documentTypes,
    onDocumentTypeChange,
    metaDataForm,
    loadingMetaDataForm: processing,
    onSubmitDraft,
    creating: creating || creatingSessionDoc,
    windowHeight,
    closeModal,
    openModal,
    onDismiss,
    fetching,
    getDocFileUrl,
    showDismissModal,
    //Upload document and create task
    closeCreateTaskModal,
    showCreateTaskModal,
    uploadingDocumentAndCreatingTask:
      uploadingDocumentAndCreatingTask ||
      validating ||
      uploadingSessionDocumentAndCreatingTask,
    uploadDocumentAndCreateTask: uploadDocumentAndCreateTaskHandler,
    createTaskForm,
    onSubmitError,
    setIsTaskCreating,
    // Upload document and request signature
    uploadDocumentAndRequestSignature: uploadDocumentAndRequestSignatureHandler,
    uploadingDocumentAndRequestingSignature,
    uploadingSessionDocumentAndRequestingSignature,
    setIsRequestSignature,
    requestDigitalSignatureForm,
    closeSignatureModal,
    showSignatureModal,
    openSignatureModal,
  };
}
