import { useRef } from "react";
import { CardsGridProps } from "./Card.types";
import useCard from "./useCard";

const CardGontainer = ({
  children,
  cardGap,
  cardMaxNumberPerRow,
  cardMinWidth,
  cardMaxWidth,
  isInline = false,
  containerPaddingX = 0,
}: CardsGridProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { cardWidth, isLoading } = useCard(
    containerRef,
    cardGap,
    cardMaxNumberPerRow,
    cardMinWidth,
    cardMaxWidth,
    containerPaddingX
  );
  return (
    <div
      ref={containerRef}
      className={`card-container d-flex ${
        isInline ? "flex-nowrap" : "flex-wrap"
      }`}
      style={{
        gap: `${cardGap}px`,
      }}
    >
      {isLoading ? (
        <></>
      ) : (
        children.map((item, i) => (
          <div
            key={i}
            style={{
              width: cardWidth,
              transition: "width 0.3s ease-out",
              minWidth: `${cardMinWidth}px`,
              flexShrink: 0,
            }}
          >
            {item}
          </div>
        ))
      )}
    </div>
  );
};

export default CardGontainer;
