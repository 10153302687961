import { Modal } from "react-bootstrap";
import { TaskStatusWithCommentModalProps } from "./Task.types";
import { Button } from "../../components/button";
import { GenericForm } from "../../components/generic-form";

export function TaskPutOnHoldModal({
  show,
  onSubmit,
  onDismiss,
  changing,
  taskStatusWithCommentForm,
}: TaskStatusWithCommentModalProps) {
  return (
    <Modal show={show} animation onHide={onDismiss}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Put task on hold
        </h5>
        <div onClick={onDismiss}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>Please provide a reason for putting task on hold.</p>
        <GenericForm
          form={taskStatusWithCommentForm}
          onSubmit={onSubmit}
          submitControl={(submit) => (
            <div className="flex-row">
              <Button
                isLoading={changing}
                loader={{ size: "s" }}
                className="btn btn-primary mt-3 w-100"
                type="submit"
              >
                Confirm putting task on hold
              </Button>
            </div>
          )}
        />
      </Modal.Body>
    </Modal>
  );
}
