import { IDocument } from "../../../entities/IDocument";

export const isDocumentProcessedChecker = (item: IDocument): boolean => {
  if (!item.docTypeCode) {
    return false;
  }
  if (item.metaData && JSON.stringify(item.metaData) !== "{}") {
    const isBusiness = item.docTypeCode.includes("bus_");
    if (isBusiness) {
      return (
        !!item.metaData.msisdn || !!item.metaData.mb || !!item.metaData.iccid
      );
    }
    return !!item.metaData.msisdn || !!item.metaData.jmbg;
  }
  return false;
};
