import { GenericForm } from "../generic-form/GenericForm.types";
import { GenericFormValidator } from "../generic-form/utility/GenericFormValidator";
import { CreateDigitalSignatureRequestDTO } from "../../entities/IDigitalSignature";
import { IOption } from "../select";

export const requestDigitalSignatureForm: GenericForm<CreateDigitalSignatureRequestDTO> =
  {
    email: {
      type: "input",
      name: "email",
      label: "Customer Email",
      defaultValue: "",
      validators: [
        GenericFormValidator.required(),
        GenericFormValidator.email(),
      ],
    },
    documentId: {
      type: "hidden",
      visibleIf: () => false,
      name: "documentId",
      label: "",
      defaultValue: -1,
      validators: [],
    },
  };

export interface RequestDigitalSignatureModalProps {
  onDismiss: () => void;
  onSubmit: (data: CreateDigitalSignatureRequestDTO) => void;
  creating: boolean;
  isVisible: boolean;
  requestDigitalSignatureForm: GenericForm<CreateDigitalSignatureRequestDTO>;
}

export interface ViewDigitalSignatureModalProps {
  onDismiss: () => void;
  isVisible: boolean;
  fields: IOption<string>[];
  onDelete: () => void;
  onResend: () => void;
}
