import { useGenericForm } from ".";
import { Button } from "../button";
import { GenericFormProps } from "./GenericForm.types";
import { GenericFormControl } from "./GenericFormControl";

export function GenericForm<T>({
  className,
  submitControl,
  submitFormOnEnter = true,
  onSubmit,
  onError,
  form,
  resetFormOnSubmit,
}: GenericFormProps<T>) {
  const { onFormSubmit, submit, formState, onValueChange } = useGenericForm({
    form,
    onSubmit,
    resetFormOnSubmit,
    submitFormOnEnter,
    onError,
  });

  function renderControls() {
    return Object.keys(form).map((k) => {
      const key = k as keyof T;
      const control = form[key];
      const controlState = formState[key];

      function onControlValueChange<TValue>(value: TValue) {
        onValueChange(key, value);
      }

      return !controlState ||
        (!!control.visibleIf && !control.visibleIf(formState)) ? null : (
        <div
          className={
            control.type === "checkbox"
              ? "form-group d-flex  gap-3  ml-1  align-items-center"
              : "form-group"
          }
          key={k}
        >
          {control.label ? (
            <label className={control.type === "checkbox" ? "order-2" : ""}>
              <span className="text-danger">
                {controlState.required ? "*" : ""}
              </span>
              {control.label}
            </label>
          ) : null}
          <GenericFormControl<T, T[keyof T]>
            isTouched={controlState.isTouched}
            isSubmitted={controlState.isSubmitted}
            control={control}
            value={controlState.value}
            error={controlState.error}
            formState={formState}
            onChange={onControlValueChange}
          />
          {controlState.error &&
          controlState.isTouched &&
          controlState.isSubmitted ? (
            <div className="text-danger">
              <small>{controlState.error}</small>
            </div>
          ) : null}
        </div>
      );
    });
  }

  return (
    <form onSubmit={onFormSubmit} className={className}>
      {renderControls()}
      {!!submitControl ? (
        submitControl(submit)
      ) : (
        <Button type="submit" className="btn btn-primary w-100 mt-2">
          Submit
        </Button>
      )}
    </form>
  );
}
