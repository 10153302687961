import React from "react";

export interface TableProps {
  headers: TableHeaders[];
  data: TableRow[];
  loading?: boolean;
  sort?: TableSort;
  selection?: false | Selection;
  pagination?: false | Pagination;
  onRowPress?: (id: number) => any;
}

export interface TableWithQueryPaginationAndSortProps {
  headers: TableHeaders[];
  data: TableRow[];
  loading?: boolean;
  selection?: false | Selection;
  onRowPress?: (id: number) => any;
  totalItems: number;
  defaultSort?: {
    key?: string;
    order?: "DESC" | "ASC";
  };
}

export interface TableHeaders {
  name: string;
  key: string;
  sortable?: boolean;
}

export type TableData = string | number | React.ReactElement;

export type TableRow = TableData[];

export interface Selection {
  onSelectionChanged?: (selected: any[]) => any;
  selected?: any[];
}
export interface Pagination {
  totalItems: number;
  onItemsPerPageChange?: (itemsNo: number) => any;
  onPageChange?: (pageNo: number) => any;
  initialItemsPerPage: number;
}

export const itemsPerPageOptions = [10, 15, 20, 30, 50, 100];

export interface TableSort {
  key?: string;
  order?: "DESC" | "ASC";
  onSort: (key: string, order: "DESC" | "ASC") => any;
}
