import { Loader } from "../../modules/loader";
import Header from "../header/Header";
import { Log } from "./";
import useLogs from "./useLogs";
const Logs = () => {
  const { logs, loading } = useLogs();
  if (loading)
    return (
      <div className="w-100 flex-row justify-content-center">
        <Loader size="l" color="black" />
      </div>
    );
  return (
    <div className="mt-4 flex-column gap-3">
      <Header title="Logs" />
      <div className="flex-column">
        {logs.map((item) => (
          <Log key={item.id} {...item} />
        ))}
        <div className="flex-column ">
          <div
            style={{
              width: "16px",
              height: "16px",
              borderRadius: "8px",
              flex: "0 0 auto",
            }}
            className="bg-secondary"
          />
          <div
            className="bg-secondary"
            style={{ width: "2px", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
export default Logs;
