import { useGetContainerWidth } from "./utility/useGetContainerWidth";
import { calculateCardWidth } from "./utility/calculateCardWith";
import { useState } from "react";

const useCard = (
  containerRef: any,
  cardGap: number,
  cardMaxNumberPerRow: number,
  cardMinWidth: number,
  cardMaxWidth: number | undefined,
  paddingX: number
) => {
  const containerWidth = useGetContainerWidth(containerRef);
  const cardWidth = calculateCardWidth(
    containerWidth,
    cardMaxNumberPerRow,
    cardMinWidth,
    cardMaxWidth,
    cardGap,
    paddingX
  );
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 100);

  return {
    containerWidth,
    cardWidth,
    isLoading,
  };
};

export default useCard;
