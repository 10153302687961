import { Modal } from "react-bootstrap";
import { DeletePageModalProps } from "./SplitFile.types";

export function DeletePageModal({
  show,
  onCancel,
  onDismiss,
  pageNumber,
}: DeletePageModalProps) {
  return (
    <Modal show={show} animation onHide={onCancel}>
      <Modal.Header>
        <h5 className="modal-title" id="Dismiss Modal">
          Delete page {pageNumber}
        </h5>
        <div onClick={onCancel}>
          <span className="font-20 cursor-pointer">&times;</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete page {pageNumber}?
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          No
        </button>
        <button type="button" className="btn btn-danger" onClick={onDismiss}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
}
