import { Filter } from "../../components/filters";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";
import { IOption } from "../../components/radio-button/RadioButton.types";
import { TableHeaders } from "../../components/table";

export interface CSVRow {
  [key: string]: string | number;
}

export const headersReport = {
  basketId: "Basket id",
  basketName: "Basket name",
  assignedTasks: "Tasks assigned",
  inProgressTasks: "Tasks in progress",
  doneTasks: "Tasks done",
  oldestTask: "Oldest task",
  userId: "Assigned User id",
  userName: "Assigned User name",
  documentType: "Document type",
  aht: "AHT",
  art: "ART",
};

export const reportsTableHeadersBasket: TableHeaders[] = [
  { name: "Basket ID", key: "basketId" },
  { name: "Basket name", key: "basketName" },
  { name: "AHT", key: "aht" },
  { name: "ART", key: "art" },
  { name: "Tasks assigned", key: "assignedTasks" },
  { name: "Tasks in progress", key: "inProgressTasks" },
  { name: "Tasks done", key: "doneTasks" },
  { name: "Oldest task", key: "oldestTask" },
];

export const reportsTableHeadersAssignedUser: TableHeaders[] = [
  { name: "Assigned User ID", key: "userId" },
  { name: "Assigned User name", key: "userName" },
  { name: "AHT", key: "aht" },
  { name: "ART", key: "art" },
  { name: "Tasks assigned", key: "assignedTasks" },
  { name: "Tasks in progress", key: "inProgressTasks" },
  { name: "Tasks done", key: "doneTasks" },
  { name: "Oldest task", key: "oldestTask" },
];

export const reportsTableHeadersDocumentType: TableHeaders[] = [
  { name: "Document type", key: "documentType" },
  { name: "Tasks assigned", key: "assignedTasks" },
  { name: "Tasks in progress", key: "inProgressTasks" },
  { name: "Tasks done", key: "doneTasks" },
  { name: "Oldest task", key: "oldestTask" },
];

export const groupByOptions: IOption[] = [
  {
    label: "Basket",
    name: "group-by-types",
    value: "BASKET",
  },
  {
    label: "User",
    name: "group-by-types",
    value: "ASSIGNED_USER",
  },
  {
    label: "Document type",
    name: "group-by-types",
    value: "DOCUMENT_TYPE",
  },
];

export enum ReportsGroupByEnum {
  BASKET = "BASKET",
  ASSIGNED_USER = "ASSIGNED_USER",
  DOCUMENT_TYPE = "DOCUMENT_TYPE",
}

export type ReportFilters = {
  assignedUserIds: Filter;
  basketIds: Filter;
  documentTypeIds: Filter;
  createdAt: Filter;
};
export const reportTableFilters: Filter[] = [
  {
    type: "multiple-choice-autocomplete",
    name: "Assignees",
    options: [{ label: "users", value: "users" }],
    buildQuery: (filter, values) => {
      return {
        name: "assignedUserIds",
        type: "numberArray",
        operator: "in",
        value: values,
      };
    },
  },
  {
    type: "multiple-choice-autocomplete",
    name: "Baskets",
    options: [{ label: "baskets", value: "baskets" }],
    buildQuery: (filter, values) => {
      return {
        name: "basketIds",
        type: "numberArray",
        operator: "in",
        value: values,
      };
    },
  },
  {
    type: "multiple-choice-autocomplete",
    name: "Document types",
    options: [{ label: "document types", value: "document-types" }],
    buildQuery: (filter, values) => {
      return {
        name: "documentTypeIds",
        type: "stringArray",
        operator: "in",
        value: values,
      };
    },
  },
  {
    type: "date-range",
    name: "Created date",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "createdAt",
        type,
        operator,
        value: value,
      };
    },
  },
];
