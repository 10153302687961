import * as React from "react";
import TabPanel from "./TabPanel";
import { useNavigate } from "react-router-dom";
import { Tab } from "./Tab";

interface IProps {
  tabs: Array<ITabs>;
  children?: React.ReactNode;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}
interface ITabs {
  id: number;
  name: string;
  counter?: number;
  link?: string;
}

export default function Tabs(props: IProps) {
  const { tabs, children, value, setValue } = props;
  const navigate = useNavigate();
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  return (
    <div
      className="mt-3"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ul
        className="flex-row gap-2"
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          padding: "8px",
          borderRadius: "8px",
        }}
      >
        {tabs?.map((tab, index) => (
          <Tab
            isActive={value === index}
            label={tab.name}
            counter={tab?.counter}
            key={index}
            onClick={() => {
              tab.link && navigate(tab.link, { replace: true });
              handleChange(index);
            }}
          />
        ))}
      </ul>
      <TabPanel>
        {props.tabs[0]?.link
          ? children
          : React.Children.toArray(children)[value]}
      </TabPanel>
    </div>
  );
}
