import moment from "moment";
import { FormPreviewProps } from "./FormPreview.types";

export const FormPreview = ({ fields }: FormPreviewProps) => {
  return (
    <div className={"flex-column gap-2 w-100"}>
      {fields.map((field, i) => (
        <div
          className={"flex-column"}
          key={i}
          style={{
            padding: "8px 12px",
            borderRadius: 8,
            backgroundColor: "#FFFFFF",
            gap: 0,
            flex: 1,
          }}
        >
          <label className="small" style={{ fontWeight: "bold" }}>
            {field.label}
          </label>
          <label style={{ wordBreak: "break-word" }}>
            {field.type === "date" && field.value
              ? moment(field.value).format("DD.MM.YYYY HH:mm")
              : field.type === "checkbox"
              ? field.value
                ? "Yes"
                : "No"
              : field.value || "Empty"}
          </label>
        </div>
      ))}
    </div>
  );
};
