import { ISplitPage, initialColor } from "../SplitFile.types";

export const getPagesFromFile = async (
  file: File,
  splitIntoImagePromises: (
    file: File
  ) => Promise<Promise<string>[] | undefined>,
  splitIntoPages: (file: File) => Promise<File[] | undefined>,
  danger: (text: string) => void
) => {
  const pdfPages = await splitIntoPages(file);
  console.time("d");
  const imagePromises = await splitIntoImagePromises(file);
  console.timeEnd("d");

  if (imagePromises && pdfPages) {
    return pdfPages.map((pdfPage, i) => {
      const page: ISplitPage = {
        id: i + 1,
        pageNumber: i + 1,
        color: initialColor,
        imagePromise: imagePromises[i],
        isMarkedToSplitAfter: false,
        pdf: pdfPage,
      };
      return page;
    });
  }
  danger("Something went wrong with the file");

  return [];
};
