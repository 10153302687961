import { FilterProps } from "./Filters.types";
import { Button } from "../button";
import { Icon } from "../../modules/icon";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import React, { ChangeEvent, useEffect } from "react";
import "./Filters.style.css";

export function MultipleChoiceFilter({
  filter,
  onRemove,
  onFilterSet,
}: FilterProps) {
  const [values, setValues] = React.useState<any[]>([]);
  const [appliedValue, setAppliedValue] = React.useState<any[]>([]);

  useEffect(() => {
    if (
      !filter.defaultValues ||
      !filter ||
      !Array.isArray(filter.defaultValues)
    )
      return;
    setValues([...filter.defaultValues]);
    setAppliedValue([...filter.defaultValues]);
  }, [filter]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as any;
    if (values.includes(selectedValue)) {
      // Remove value if already selected
      setValues(values.filter((value: any) => value !== selectedValue));
    } else {
      // Add value if not selected
      setValues([...values, selectedValue]);
    }
  };

  const applyFilter = () => {
    if (values.length === 0) return;
    setAppliedValue([...values]);
    onFilterSet(filter.buildQuery(filter, values), filter);
  };

  return (
    <div className="mr-3 my-1">
      <div className="btn-group">
        <DropdownButton
          as={ButtonGroup}
          variant="outline-dark"
          onToggle={(show) => {
            if (show) {
              setValues([...appliedValue]);
            }
          }}
          onSelect={() => true}
          title={
            <>
              {filter.name}:{" "}
              {!!appliedValue.length &&
                (appliedValue.length < 4
                  ? appliedValue.map((selectedValue: any, index: number) => {
                      const option = filter?.options?.find(
                        (o) => o.value === selectedValue
                      );
                      return (
                        <span key={selectedValue}>
                          {index > 0 && ", "}
                          {option?.label}
                        </span>
                      );
                    })
                  : `${appliedValue.length} selected`)}
              &nbsp;
            </>
          }
        >
          <div>
            <div className="px-2 pt-2">
              {filter.options?.map((option, key) => (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={option.value.toString()}
                    name={filter.name}
                    value={option.value}
                    onChange={onChange}
                    checked={values.includes(option.value)}
                  />
                  <label
                    htmlFor={option.value.toString()}
                    className="form-check-label"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
              <div className="apply" style={{ position: "sticky", bottom: 0 }}>
                <Dropdown.Item
                  className="btn btn-primary mt-3"
                  onClick={applyFilter}
                >
                  Apply
                </Dropdown.Item>
              </div>
            </div>
          </div>
        </DropdownButton>
        <Button
          className="btn btn-outline-dark"
          onClick={() => onRemove(filter)}
        >
          <Icon title="Remove" type="fa-solid fa-xmark" size={16} />
        </Button>
      </div>
    </div>
  );
}
