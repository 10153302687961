import { BadgeProps } from "../components/badge/Badge.types";
import { QueryFilter } from "../components/filters";
import { IDbEntity } from "./IDbEntity";
import { IDealer } from "./IDealer";
import { IDigitalSignatureRequest } from "./IDigitalSignature";
import { MetaData } from "./IMetaData";
import { IUser } from "./IUser";

export interface IDocument extends IDbEntity {
  status: DocumentStatus;
  createdById: number;
  docTypeCode: string;
  docType: IDocumentType;
  createdBy?: IUser;
  files?: IFile[];
  metaData?: MetaData;
  task?: {
    id: number;
    status: string;
    basketId: number;
    userId: number;
  };
  digitalSignRequest: null | IDigitalSignatureRequest;
  dealerCode?: string;
  dealer?: IDealer;
  uploadSessionId?: number;
}

export interface IFile {
  id?: number;
  createdAt?: number;
  updatedAt?: number;
  url: string;
  mimeType?: string;
  name: string;
  documentId?: number;
}

export enum DocumentStatus {
  DRAFT = "draft",
  PENDING = "pending",
  ARCHIVED = "archived",
  DISMISSED = "dismissed",
  PROCESSING = "processing",
  DIGITAL_SIGN = "digital_sign",
}

export const DocStatusBadgeMap: {
  [status in DocumentStatus]: BadgeProps["type"];
} = {
  pending: "success",
  archived: "primary",
  dismissed: "danger",
  draft: "secondary",
  processing: "warning",
  digital_sign: "info",
};

export interface IDocumentType {
  code: string;
  title: string;
  key: string;
}

export interface CreateDocumentDTO {
  file: File;
  docTypeCode?: string;
  metaData?: any;
  draft?: boolean;
}

export interface UpdateDocumentDTO {
  file?: File;
  metaData: MetaData;
  id: string;
  documentTypeCode: string;
  status?: DocumentStatus;
}

export interface UpdateSessionDocumentDTO extends UpdateDocumentDTO {
  sessionId: string;
}

export interface GetDocumentsDTO {
  textSearch?: string;
  skip?: number;
  limit?: number;
  filters?: QueryFilter[];
  sort?: { key: string; value: string };
}

export interface ValidateDocumentResponse {
  valid: boolean;
  errors?: string[];
  items: Array<{ key: string; valid: boolean; error?: string }>;
}

export interface GetDocumentsStatsDTO {
  pos?: number[];
  dateFrom?: Date;
  dateTo?: Date;
}
export interface IDocumentStats {
  total: number;
  change: number;
  renewal: number;
  complaint: number;
  newcontract: number;
}
export interface GetDocumentsStatsResponse {
  total: number;
  residential: IDocumentStats;
  business: IDocumentStats;
}

export interface AccountingPeriodsResponse {
  title: string;
  code: string;
  group: {
    documentCategory: "Business" | "Residential";
  };
}
export interface CreateDocumentSessionResponse {
  documents: IDocument[];
  id: number;
}
export enum SessionStatus {
  ACTIVE = "active",
  DONE = "done",
}
export interface GetDocumentsBySessionIdResponse {
  createdAt: Date;
  createdById: number;
  documents: IDocument[];
  id: number;
  status: SessionStatus;
  totalDocuments: number;
}

export const preArchiveStatues: DocumentStatus[] = [
  DocumentStatus.DRAFT,
  DocumentStatus.PROCESSING,
  DocumentStatus.DIGITAL_SIGN,
];

export const archivedStatues: DocumentStatus[] = [
  DocumentStatus.PENDING,
  DocumentStatus.ARCHIVED,
];
