import { CreateDigitalSignatureRequestDTO } from "../../entities/IDigitalSignature";
import BaseInstance from "../BaseInstance";

const DigitalSignatureGateway = {
  createDigitalSignatureRequest: async (
    payload: CreateDigitalSignatureRequestDTO
  ) => {
    return BaseInstance.post("/digital-sign-request", payload);
  },
  deleteDigitalSignatureRequest: async (id: number) => {
    return BaseInstance.delete(`/digital-sign-request/${id}`);
  },
};

export default DigitalSignatureGateway;
