import { PageLayout } from "../../components/page-layout";
import { FileUpload } from "../../modules/file-upload";
import { ContinuePreviousUpload } from "./ContinuePreviousUpload";
import { DismissDraftDocumentModal } from "./DismissDraftDocument";
import { SplitPdfModal } from "./SplitPdfModal";
import { useUploadDocument } from "./useUploadDocument";

export function UploadDocumentScreen() {
  const {
    onUpload,
    documents,
    loading,
    onContinueDraft,
    showDismissModal,
    showSplitPdfModal,
    closeDismissModal,
    openDismissModal,
    onDismiss,
    onSplitPdf,
    onDontSplitPdf,
    dismissFileUpload,
  } = useUploadDocument();

  if (!!documents && documents.length) {
    return (
      <PageLayout title="Upload Document(s)" backBtn loading={loading}>
        <ContinuePreviousUpload
          openModal={openDismissModal}
          onContinueDraft={onContinueDraft}
          type={
            documents.length && documents[0].uploadSessionId
              ? "Multiple"
              : "Single"
          }
        />
        <DismissDraftDocumentModal
          onDismiss={onDismiss}
          show={showDismissModal}
          onCancel={closeDismissModal}
        />
      </PageLayout>
    );
  }
  return (
    <PageLayout title="Upload Document(s)" backBtn loading={loading}>
      <FileUpload onUpload={onUpload} />
      <DismissDraftDocumentModal
        onDismiss={onDismiss}
        show={showDismissModal}
        onCancel={closeDismissModal}
      />
      <SplitPdfModal
        show={showSplitPdfModal}
        onSelectSplitFile={onSplitPdf}
        onSelectDontSplitFile={onDontSplitPdf}
        onDismissUpload={dismissFileUpload}
      />
    </PageLayout>
  );
}
