import { Filter } from "../../components/filters";
import { buildDateQueryOperator } from "../../components/filters/DateRangeFilter/utility/buildDateQuery";
import { TableHeaders } from "../../components/table";

export type DocumentFilterKeys = {
  "metaData.boxId": Filter;
  "metaData.archiveType": Filter;
  "metaData.signDate": Filter;
  createdAt: Filter;
  "metaData.isElectronicallySigned": Filter;
};
export const documentFilters: DocumentFilterKeys = {
  "metaData.boxId": {
    type: "single-choice",
    name: "Box ID",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "metaData.boxId",
        type: "string",
        operator: values[0] === "y" ? "ne" : "eq",
        value: null,
      };
    },
  },
  "metaData.archiveType": {
    type: "single-choice",
    name: "Archive Type",
    options: [
      { label: "Digital", value: "Digital" },
      { label: "Physical", value: "Physical" },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "metaData.archiveType",
        type: "string",
        operator: "eq",
        value: values, //check this
      };
    },
  },
  "metaData.signDate": {
    type: "date-range",
    name: "Sign Date",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "metaData.signDate",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  createdAt: {
    type: "date-range",
    name: "Upload Date",
    buildQuery: (filter, values) => {
      const { value, operator, type } = buildDateQueryOperator(values);
      return {
        name: "createdAt",
        type,
        operator,
        value: JSON.stringify(value),
      };
    },
  },
  "metaData.isElectronicallySigned": {
    type: "single-choice",
    name: "Electronically Signed",
    options: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    buildQuery: (filter, values) => {
      return {
        name: "metaData.isElectronicallySigned",
        type: "boolean",
        operator: values ? "eq" : "ne",
        value: JSON.stringify(true),
      };
    },
  },
};

export const documentTableHeaders: TableHeaders[] = [
  { name: "ID", key: "id", sortable: true },
  { name: "JMBG/MB", key: "customerIdentityNumber" },
  { name: "MSISDN", key: "metaData.msisdn" },
  { name: "Sign Date", key: "metaData.signDate", sortable: true },
  { name: "Type", key: "metaData.documentType" },
  { name: "Archive", key: "metaData.archiveType" },
  { name: "Box ID", key: "metaData.boxId" },
  { name: "Upload Date", key: "createdAt", sortable: true },
  { name: "Uploaded By", key: "createdBy" },
  { name: "eSigned", key: "metaData.isElectronicallySigned" },
  { name: "Actions", key: "action" },
];

export interface DocumentsTableProps {
  textSearch?: string;
}

export interface AddToBoxModalProps {
  show: boolean;
  onAdd: (boxId: string) => any;
  adding: boolean;
  documentIds: number[];
  onCancel: () => any;
}
