import { ToggleProps } from "./Toggle.types";
import "./Toggle.styles.css";
const Toggle = ({ on, onChange }: ToggleProps) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={on} onChange={onChange} />
      <span className="slider round"></span>
    </label>
  );
};
export default Toggle;
