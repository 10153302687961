import { useNavigate } from "react-router-dom";
import { StatCardProps } from "./StatCard.types";
import DoughnutChart from "../doughnut-chart/DoughnutChart";
import "./StatCard.styles.css";

const StatCard = ({
  link,
  title,
  total,
  data,
  legendOptions = {
    isVisible: true,
    position: "right",
    total: { title: "Total", isVisible: true },
  },
}: StatCardProps) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex-column p-3 stat-card"
      onClick={() => {
        link && navigate(link);
      }}
    >
      <div
        className="flex-row justify-content-between align-items-start  gap-3"
        style={{ height: "54px" }}
      >
        <label className="text-light bg-secondary stat-card-label">
          {title}
        </label>
      </div>
      <DoughnutChart total={total} data={data} legendOptions={legendOptions} />
    </div>
  );
};
export default StatCard;
