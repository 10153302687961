import moment from "moment";
import { ITaskComment } from "./comments.types";

const Comment = ({ createdAt, createdBy, text }: ITaskComment) => {
  return (
    <div className={"flex-row gap-2"}>
      <div
        className="bg-secondary text-light rounded-circle d-flex justify-content-center align-items-center"
        style={{ width: "32px", height: "32px" }}
      >
        {createdBy.name[0]}
      </div>
      <div className="flex-column w-50">
        <div
          className="flex-column w-100 p-3"
          style={{
            borderRadius: "16px",
            backgroundColor: "rgb(129, 169, 188, 0.1)",
          }}
        >
          <span className="fw-bold">{createdBy.name}</span>
          <span>{text}</span>
        </div>
        <span className="text-muted ml-3" style={{ fontSize: "14px" }}>
          {moment(createdAt).format("DD.MM.YYYY HH:mm")}
        </span>
      </div>
    </div>
  );
};
export default Comment;
