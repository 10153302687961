export interface ISplitPage {
  id: number;
  pageNumber: number;
  pdf: File;
  imagePromise: Promise<string>;
  isMarkedToSplitAfter: boolean;
  color: string;
}

export interface PageContainerProps {
  isLoading: boolean;
  pages: ISplitPage[];
  addSeparator: (id: number) => void;
  removeSeparator: (id: number) => void;
  seePage: (id: number) => void;
  deletePage: (id: number) => void;
  pagesPerRow: number;
}

export interface PageItemProps extends ISplitPage {
  addSeparator: (id: number) => void;
  removeSeparator: (id: number) => void;
  seePage: (id: number) => void;
  deletePage: (id: number) => void;
  isLast?: boolean;
}

export interface ViewPageModalProps {
  show: boolean;
  onClose: () => void;
  page?: ISplitPage;
}

export const initialColor = "#D3D3D3";
export const colors = [
  "#FF6961", // Pastel Red
  "#FFD700", // Pastel Yellow
  "#C2B280", // Pastel Beige
  "#B2D8D8", // Pastel Aqua
  "#C2A2DA", // Pastel Lavender
  "#77DD77", // Pastel Green
  "#FFB6C1", // Pastel Pink
  "#AB83A1", // Pastel Mauve
  "#F0E68C", // Pastel Khaki
  "#AEC6CF", // Pastel Blue
];

export interface DeletePageModalProps {
  show: boolean;
  onCancel: () => any;
  onDismiss: () => any;
  pageNumber: number;
}
