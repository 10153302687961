import { useEffect, useState } from "react";
import { useDrawerContext } from "../../../modules/drawer";
import { debounce } from "./debounce";

export const useGetContainerWidth = (containerRef: any) => {
  const [width, setWidth] = useState(0);
  const { isOpen } = useDrawerContext();

  useEffect(() => {
    if (!containerRef?.current) return;
    const getWidth = () =>
      containerRef?.current && containerRef?.current.offsetWidth;

    const handleResize = debounce(() => {
      setWidth(getWidth());
    }, 500);

    if (containerRef?.current) {
      setWidth(getWidth());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  useEffect(() => {
    if (!containerRef?.current) return;
    const getWidth = () =>
      containerRef?.current && containerRef?.current.offsetWidth;
    if (containerRef?.current)
      setTimeout(() => {
        setWidth(getWidth());
      }, 400);
  }, [isOpen, containerRef]);

  return width;
};
