import { ButtonGroup, DropdownButton } from "react-bootstrap";
import { SelectActionProps } from "./Select.types";
import { Button } from "../button";
import { Icon } from "../../modules/icon";
import { useState } from "react";
export function SelectAction<T>({
  options,
  onChange,
  disabled,
  error,
  title,
  defaultLabel = "Choose action",
  className = "",
}: SelectActionProps<T>) {
  const [toggle, setToggle] = useState(false);
  return (
    <DropdownButton
      as={ButtonGroup}
      variant="dark"
      onToggle={(show) => {
        setToggle(show);
      }}
      onSelect={() => true}
      title={
        <>
          {defaultLabel}
          &nbsp;&nbsp;
          <Icon
            type={`fa-solid fa-caret-${toggle ? "up" : "down"}`}
            size={18}
          />
        </>
      }
    >
      <div>
        <div className="px-2 flex-column gap-2">
          {options
            ? options.map((option, index) => (
                <Button
                  onClick={() => {
                    onChange(option.value);
                  }}
                  key={index}
                  className="btn btn-outline-dark"
                  type="button"
                >
                  {option.label}
                </Button>
              ))
            : null}
        </div>
      </div>
    </DropdownButton>
  );
}

/*
 <div className={`max-content ${className}`}>
      {!!title && <label>{title}</label>}
      <select
        className={`form-select${error ? " is-invalid" : ""}  ${
          disabled ? "bg-light" : ""
        } cursor-text`}
        value={
          value === null || typeof value === "undefined"
            ? ""
            : JSON.stringify(value)
        }
        onChange={(event) => {
          onChange(JSON.parse(event.target.value));
        }}
        disabled={disabled}
      >
        {options
          ? optionL.map((option, index) => (
              <option
                className={option.value === "" ? "d-none" : ""}
                key={index}
                value={JSON.stringify(option.value)}
              >
                {option.label}
              </option>
            ))
          : null}
      </select>
    </div>
    */
