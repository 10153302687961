export interface FiltersProps {
  filters: Filter[];
  onFilterChange: (queryFilters: QueryFilter[]) => any;
}

export interface FilterProps {
  filter: Filter;
  onRemove: (filter: Filter) => any;
  onFilterSet: (queryFilter: QueryFilter, filter: Filter) => any;
}

export interface Filter {
  type: FilterType;
  name: string;
  defaultValues?:
    | string[]
    | Date[]
    | number[]
    | string
    | Date
    | number
    | undefined;
  buildQuery: (
    filter: Filter,
    value:
      | string[]
      | Date[]
      | number[]
      | (undefined | string | number | Date)[],
    operator?: "in" | "between" | "eq" | "gt" | "lt" | "gte" | "lte" | "ne"
  ) => QueryFilter;
  options?: Array<{ label: string; value: string | number }>;
}

export interface QueryFilter {
  name: string;
  value: any;
  operator: string;
  type: DataType;
}

export type FilterType =
  | "date-range"
  | "single-choice"
  | "multiple-choice"
  | "multiple-choice-autocomplete";

export type DataType =
  | "string"
  | "date"
  | "number"
  | "stringArray"
  | "dateArray"
  | "numberArray"
  | "boolean"
  | "booleanArray";

export enum DateRangeTypeValues {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  THIS_WEEK = "This week",
  LAST_WEEK = "Last week",
  THIS_MONTH = "This month",
  LAST_MONTH = "Last month",
  THIS_YEAR = "This year",
  LAST_YEAR = "Last year",
  BETWEEN = "Between",
  BEFORE = "Before",
  AFTER = "After",
}

export interface DateRangeValueProps {
  value: DateRangeTypeValues;
  toDate: Date | undefined;
  fromDate: Date | undefined;
}
