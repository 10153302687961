import { Button } from "../button";
import { GenericForm } from "../generic-form";
import { DocumentMetaDataUpdateProps } from "./DocumentForm.types";

const DocumentMetaDataUpdate = ({
  metaDataForm,
  onUpdate,
  updating,
}: DocumentMetaDataUpdateProps) => (
  <>
    {metaDataForm ? (
      <div className="flex-col gap-2">
        <GenericForm
          className="mt-3"
          form={metaDataForm}
          onSubmit={onUpdate}
          submitControl={(submit) => (
            <Button
              className="btn btn-primary w-100 mt-3"
              type="button"
              isLoading={updating}
              disabled={updating}
              onClick={submit}
            >
              Save
            </Button>
          )}
        />
      </div>
    ) : null}
  </>
);
export default DocumentMetaDataUpdate;
