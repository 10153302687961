import { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import { DraftDocumentCardProps } from "./UploadDocumentsSession.types";
import "./DraftDocumentCard.style.css";
import { Icon } from "../../modules/icon";

export const DraftDocumentInProcessingCard = () => {
  const [dots, setDots] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots === 3 ? 0 : dots + 1));
    }, 500);
    return () => clearInterval(interval);
  }, []);
  return (
    <div
      className="p-2 flex-column gap-2"
      style={{ backgroundColor: "#E9EBEC", borderRadius: "8px" }}
    >
      <div
        style={{
          borderRadius: "4px",
          backgroundColor: "#CACDD0",
          aspectRatio: 1,
        }}
        className="opacity-change"
      />
      <span>{`Processing${
        dots === 0 ? "" : dots === 1 ? "." : dots === 2 ? ".." : "..."
      }`}</span>
    </div>
  );
};

export const DraftDocumentCard = ({
  id,
  name,
  status,
  onClick,
  onDismiss,
  isProcessed,
}: DraftDocumentCardProps) => {
  if (status === "processing") return <DraftDocumentInProcessingCard />;
  return (
    <div
      className="p-2 flex-column gap-2 draft-document-card cursor-pointer"
      style={{}}
      onClick={() => {
        onClick(id);
      }}
    >
      <Card
        style={{
          borderRadius: "4px",
        }}
        className="position-relative flex-row justify-content-center align-items-center"
        aspectRatio={1}
      >
        <Icon
          title="Dismiss draft document"
          color={"#666666"}
          type="fa-solid fa-trash"
          onClick={(event) => {
            event?.stopPropagation();
            onDismiss(id);
          }}
          className="position-absolute top-0 end-0 p-2 dismiss-draft-document-icon"
        />
        <Icon
          color={isProcessed ? "#198754" : "#ffc107"}
          title={isProcessed ? "File is processed" : "File is not processed"}
          type="fa-regular fa-file"
          size={64}
        />
        <Icon
          color={isProcessed ? "#198754" : "#ffc107"}
          type={`fa-solid fa-circle-${isProcessed ? "check" : "exclamation"}`}
          title={isProcessed ? "File is processed" : "File is not processed"}
          className="position-absolute top-40 mt-3"
          size={24}
        />
      </Card>
      <span>{name}</span>
    </div>
  );
};
