import { Badge } from "../badge";
import { TabProps } from "./Tabs.props";

export function Tab({ label, onClick, isActive, counter }: TabProps) {
  return (
    <h6
      style={{ borderRadius: "4px", margin: 0 }}
      className={`cursor-pointer flex-row gap-2 text-bold px-2 py-2 ${
        isActive ? "active" : "text-muted"
      }`}
      onClick={onClick}
    >
      {label}
      {!!counter && (
        <span
          className="px-2"
          style={{
            borderRadius: "2px",
            fontSize: "14px",
            backgroundColor: isActive
              ? "rgb(255, 255, 255)"
              : "rgb(129, 169, 188, 0.1)",
          }}
        >
          {counter}
        </span>
      )}
    </h6>
  );
}
