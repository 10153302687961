import { SelectProps } from "./Select.types";
export default function Select<T>({
  options,
  value,
  onChange,
  disabled,
  error,
  title,
  defaultLabel = "Select option",
  className = "",
}: SelectProps<T>) {
  const optionL =
    value === "" ? [...options, { label: defaultLabel, value: "" }] : options;
  return (
    <div className={`max-content ${className}`}>
      {!!title && <label>{title}</label>}
      <select
        className={`form-select${error ? " is-invalid" : ""}  ${
          disabled ? "bg-light" : ""
        } cursor-text`}
        value={
          value === null || typeof value === "undefined"
            ? ""
            : JSON.stringify(value)
        }
        onChange={(event) => {
          onChange(JSON.parse(event.target.value));
        }}
        disabled={disabled}
      >
        {options
          ? optionL.map((option, index) => (
              <option
                className={option.value === "" ? "d-none" : ""}
                key={index}
                value={JSON.stringify(option.value)}
              >
                {option.label}
              </option>
            ))
          : null}
      </select>
    </div>
  );
}
