const publicUrl = (window as any).public_url || "";

export const screens = {
  documents: "/documents",
  splitFile: "/split-file",
  login: "/login",
  uploadDocument: "/upload-document",
  archive: "/archive",
  newDocument: "/new-document/:id",
  search: "/search",
  baskets: "/baskets",
  basketTasks: "/baskets/:id",
  basketRules: "/baskets-rules",
  tasks: "/tasks",
  myTasks: "/my-tasks",
  task: {
    root: "/task/:id",
    comments: "comments",
    details: "details",
    logs: "logs",
  },
  taskStats: "/task-stats",
  archiveStats: "/archive-stats",
  root: publicUrl + "/",
  reports: "/reports",
  uploadSession: "/upload-session/:sessionId",
  digitalSignature: "/digital-signature",
};
