import { IDbEntity } from "./IDbEntity";

export interface IDigitalSignatureRequest extends IDbEntity {
  status: DigitalSignatureRequestStatuses;
  email: string;
  confirmedAt?: string;
  signedAt?: string;
  token?: string;
  documentId: number;
  createdById: number;
  dealerCode: string;
}

export enum DigitalSignatureRequestStatuses {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  SIGNED = "signed",
}

export interface CreateDigitalSignatureRequestDTO {
  email: string;
  documentId: number;
}
