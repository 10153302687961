import { DateRangeTypeValues } from "../../Filters.types";
import { DateRangeType } from "../DateRangeFilter.types";

export function getDateRange(fromDate: Date, toDate: Date): DateRangeType {
  const today = new Date();
  if (fromDate && !toDate) return DateRangeTypeValues.AFTER;
  if (!fromDate && toDate) return DateRangeTypeValues.BEFORE;
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const thisWeekStart = new Date(today);
  thisWeekStart.setDate(today.getDate() - ((today.getDay() + 6) % 7));

  const thisWeekEnd = new Date(today);
  thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

  const lastWeekStart = new Date(thisWeekStart);
  lastWeekStart.setDate(thisWeekStart.getDate() - 7);

  const lastWeekEnd = new Date(lastWeekStart);
  lastWeekEnd.setDate(lastWeekStart.getDate() + 6);

  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

  const thisYearStart = new Date(today.getFullYear(), 0, 1);

  const lastMonthStart = new Date(thisMonthStart);
  lastMonthStart.setMonth(thisMonthStart.getMonth() - 1);
  const lastMonthEnd = new Date(thisMonthStart);
  lastMonthEnd.setDate(0);

  const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
  const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);

  if (isSameDate(fromDate, today) && isSameDate(toDate, today)) {
    return DateRangeTypeValues.TODAY;
  } else if (isSameDate(fromDate, yesterday) && isSameDate(toDate, yesterday)) {
    return DateRangeTypeValues.YESTERDAY;
  } else if (isSameDate(fromDate, thisWeekStart) && isSameDate(toDate, today)) {
    return DateRangeTypeValues.THIS_WEEK;
  } else if (
    isSameDate(fromDate, lastWeekStart) &&
    isSameDate(toDate, lastWeekEnd)
  ) {
    return DateRangeTypeValues.LAST_WEEK;
  } else if (
    isSameDate(fromDate, thisMonthStart) &&
    isSameDate(toDate, today)
  ) {
    return DateRangeTypeValues.THIS_MONTH;
  } else if (
    isSameDate(fromDate, lastMonthStart) &&
    isSameDate(toDate, lastMonthEnd)
  ) {
    return DateRangeTypeValues.LAST_MONTH;
  } else if (
    isSameDate(fromDate, lastYearStart) &&
    isSameDate(toDate, lastYearEnd)
  ) {
    return DateRangeTypeValues.LAST_YEAR;
  } else if (isSameDate(fromDate, thisYearStart) && isSameDate(toDate, today)) {
    return DateRangeTypeValues.THIS_YEAR;
  }

  return DateRangeTypeValues.BETWEEN;
}

function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}
