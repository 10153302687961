import { GenericFormState, validatorFunc } from "../GenericForm.types";

export class GenericFormValidator {
  field: string;
  value: string;
  constructor(value: string, field: string) {
    this.field = field;
    this.value = typeof value === "string" ? value.trim() : value;
  }
  public static required(params?: { error?: string }): validatorFunc {
    return (value: string, field: string) => {
      if (!value) {
        return {
          valid: false,
          error: params?.error || `${field} is required.`,
        };
      }
      return {
        valid: true,
      };
    };
  }

  public static email(params?: { error?: string }): validatorFunc {
    return GenericFormValidator.regex({
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      error: params?.error || "Invalid email",
    });
  }

  public static password(params?: { error?: string }): validatorFunc {
    return GenericFormValidator.regex({
      pattern:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      error:
        params?.error ||
        "At least 8 characters, 1 upper case, 1 lower case, 1 number and 1 special character required",
    });
  }

  public static minChar(params: {
    limit: number;
    error?: string;
  }): validatorFunc {
    return (value: string, filed: string) => {
      if (!value || (!!value && value.length >= params.limit)) {
        return {
          valid: true,
        };
      }
      return {
        valid: false,
        error:
          params.error || `${filed} must be at least ${params.limit} long.`,
      };
    };
  }

  public static maxChar(params: {
    limit: number;
    error?: string;
  }): validatorFunc {
    return (value: string, filed: string) => {
      if (!value || (!!value && value.length <= params.limit)) {
        return {
          valid: true,
        };
      }
      return {
        valid: false,
        error: params.error || `${filed} can't be longer then ${params.limit}.`,
      };
    };
  }

  public static numericOnly(params?: { error?: string }): validatorFunc {
    return GenericFormValidator.regex({
      pattern: /^[0-9]*$/,
      error: params?.error || `Must contain numeric characters only.`,
    });
  }

  public static regex(params: {
    pattern: string | RegExp;
    error?: string;
  }): validatorFunc {
    return (value: string, field: string) => {
      const regex = new RegExp(params.pattern);
      if (!!value && !regex.test(value)) {
        return {
          valid: false,
          error: params.error || `Invalid ${field}.`,
        };
      }
      return {
        valid: true,
      };
    };
  }

  public static charLength(params: {
    limit: number;
    error?: string;
  }): validatorFunc {
    return (value: string, field: string) => {
      if (!!value && value.length !== params.limit) {
        return {
          valid: false,
          error:
            params.error || `${field} must be ${params.limit} characters long.`,
        };
      }
      return { valid: true };
    };
  }

  public static number(params?: { error?: string }): validatorFunc {
    return (value: string, field: string) => {
      if (!value) {
        return { valid: true };
      }
      const num = parseFloat(value);
      if (isNaN(num)) {
        return {
          valid: false,
          error: params?.error || `${field} must be a number.`,
        };
      }
      return {
        valid: true,
      };
    };
  }

  public static customValidator(
    validator: (
      value: string,
      field: string,
      state?: GenericFormState<any>
    ) => { valid: boolean; error?: string }
  ): validatorFunc {
    return (value: string, field: string, state?: GenericFormState<any>) => {
      return validator(value, field, state);
    };
  }

  public validate<T>(
    validators: validatorFunc[],
    state: GenericFormState<T>
  ): {
    valid: boolean;
    error?: string;
  } {
    for (const validFunc of validators) {
      const validated = validFunc(this.value, this.field, state);
      if (!validated.valid) {
        return validated;
      }
    }
    return {
      valid: true,
    };
  }
}
