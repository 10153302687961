import { Button } from "../../components/button";
import { CreateTaskModal } from "../../components/create-task";
import { FilePreview } from "../../components/file-preview";
import { GenericForm } from "../../components/generic-form";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { RequestDigitalSignatureModal } from "../../components/request-digital-signature";
import { CreateDigitalSignatureRequestDTO } from "../../entities/IDigitalSignature";
import { Icon } from "../../modules/icon";
import { Loader } from "../../modules/loader";
import { DismissNewDocumentModal } from "./DismissNewDocumentModal";
import { useNewDocument } from "./useNewDocument";

export function NewDocumentScreen({
  id,
  closeDocumentModal,
}: {
  id?: string;
  closeDocumentModal?: (isDocumentCreated?: boolean) => void;
}) {
  const {
    draftDocument,
    documentType,
    documentTypes,
    onDocumentTypeChange,
    metaDataForm,
    loadingMetaDataForm,
    onSubmitDraft,
    creating,
    windowHeight,
    showDismissModal,
    openModal,
    closeModal,
    onDismiss,
    getDocFileUrl,
    uploadingDocumentAndCreatingTask,
    showCreateTaskModal,
    uploadDocumentAndCreateTask,
    closeCreateTaskModal,
    createTaskForm,
    onSubmitError,
    setIsTaskCreating,
    uploadingDocumentAndRequestingSignature,
    setIsRequestSignature,
    uploadDocumentAndRequestSignature,
    requestDigitalSignatureForm,
    closeSignatureModal,
    showSignatureModal,
  } = useNewDocument(id, closeDocumentModal);

  return (
    <PageLayout
      backBtn={!id}
      title={id ? "" : `New Document`}
      titleRight={
        <>
          {!id && (
            <Button className="btn btn-danger" onClick={openModal}>
              <Icon type="fa-solid fa-xmark" size={16} />
              &nbsp; Dismiss
            </Button>
          )}
        </>
      }
    >
      <Row>
        <Column>
          {draftDocument ? (
            <div className="d-flex flex-column align-items-center">
              <FilePreview
                enableEditing={false}
                type={draftDocument.files![0].mimeType || ""}
                url={
                  draftDocument.files![0].url
                    ? getDocFileUrl(draftDocument.files![0].url)
                    : ""
                }
                name={draftDocument.files![0].name || ""}
                width={(windowHeight - 250) / 1.4}
                height={windowHeight - 250}
              />
            </div>
          ) : null}
        </Column>
        <Column>
          <div
            className="d-flex flex-column"
            style={{ maxWidth: (windowHeight - 250) / 1.4 }}
          >
            <div className="form-group">
              <label>Document type</label>
              <select
                className="form-select"
                value={documentType}
                onChange={onDocumentTypeChange}
              >
                <option value=""></option>
                {documentTypes.map((docType) => (
                  <option key={docType.code} value={docType.code}>
                    {docType.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-4">
              {loadingMetaDataForm ? (
                <div className="text-center">
                  <Loader color="#0d6efd" />
                </div>
              ) : metaDataForm ? (
                <div>
                  <h5>Document data</h5>
                  <GenericForm
                    className="mt-3"
                    form={metaDataForm}
                    onError={onSubmitError}
                    onSubmit={onSubmitDraft}
                    submitFormOnEnter={false}
                    submitControl={(submit) => (
                      <>
                        <Button
                          className="btn btn-primary w-100 mt-3"
                          type="button"
                          onClick={submit}
                          isLoading={creating}
                          disabled={creating}
                        >
                          Upload Document
                        </Button>
                        {!!(
                          draftDocument?.files?.[0]?.mimeType ===
                          "application/pdf"
                        ) && (
                          <Button
                            className="btn btn-secondary w-100 mt-3"
                            type="button"
                            onClick={() => {
                              setIsRequestSignature(true);
                              submit();
                            }}
                            isLoading={uploadingDocumentAndRequestingSignature}
                            disabled={uploadingDocumentAndRequestingSignature}
                          >
                            Upload Document and Request Digital Signature
                          </Button>
                        )}
                        <Button
                          className="btn btn-secondary w-100 mt-3"
                          type="button"
                          onClick={() => {
                            setIsTaskCreating(true);
                            submit();
                          }}
                          isLoading={uploadingDocumentAndCreatingTask}
                          disabled={uploadingDocumentAndCreatingTask}
                        >
                          Upload Document and Create Task
                        </Button>
                      </>
                    )}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Column>
      </Row>
      <DismissNewDocumentModal
        onDismiss={onDismiss}
        show={showDismissModal}
        onCancel={closeModal}
      />
      <CreateTaskModal
        onDismiss={closeCreateTaskModal}
        onSubmit={uploadDocumentAndCreateTask}
        creating={uploadingDocumentAndCreatingTask}
        isVisible={showCreateTaskModal}
        //@ts-ignore
        createTaskForm={createTaskForm}
      />
      <RequestDigitalSignatureModal
        onDismiss={closeSignatureModal}
        onSubmit={uploadDocumentAndRequestSignature}
        creating={uploadingDocumentAndRequestingSignature}
        isVisible={showSignatureModal}
        requestDigitalSignatureForm={requestDigitalSignatureForm as any}
      />
    </PageLayout>
  );
}
