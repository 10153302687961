import { Filters, FiltersWithQuery } from "../../components/filters";
import { Column, Row } from "../../components/grid";
import { PageLayout } from "../../components/page-layout";
import { SearchWithQueryInput } from "../../components/search-input";
import useTasks from "./useTasks";
import { tasksTableHeaders } from "../../entities/ITask";
import TaskBulkActions from "../../components/task-bulk-actions/TaskBulkActions";
import { TableWithQueryPaginationAndSort } from "../../components/table/TableWithQueryPaginationAndSort";
import { tasksTableFilters } from "./TasksScreen.types";
const TasksScreen = () => {
  const {
    tasks,
    loading,
    onRowPress,
    total,
    setSelectedTasks,
    selectedTasks,
    selectedAction,
    setSelectedAction,
    reassignBulkTasks,
    reassigningTasks,
    moveBulkTasks,
    movingTasks,
  } = useTasks();
  return (
    <PageLayout title={`All Tasks`}>
      <div className="flex-column gap-2">
        <TaskBulkActions
          selectedTasks={selectedTasks}
          onDismiss={() => {
            setSelectedTasks([]);
          }}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          onReassign={reassignBulkTasks}
          reassigning={reassigningTasks}
          moving={movingTasks}
          onMove={moveBulkTasks}
        />
        <Row>
          <Column>
            <FiltersWithQuery filters={tasksTableFilters([])} />
          </Column>
          <Column>
            <div className="d-flex flex-row my-1 justify-content-end">
              <SearchWithQueryInput placeHolder="Search tasks" />
            </div>
          </Column>
        </Row>
        <Row>
          <Column>
            <TableWithQueryPaginationAndSort
              totalItems={total}
              loading={loading}
              onRowPress={onRowPress}
              headers={tasksTableHeaders}
              data={tasks}
              selection={{
                onSelectionChanged: setSelectedTasks,
                selected: selectedTasks,
              }}
            />
          </Column>
        </Row>
      </div>
    </PageLayout>
  );
};
export default TasksScreen;
