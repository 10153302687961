import { useEffect, useState } from "react";
import {
  AccountingPeriods,
  TaskStatuses,
  taskStatuses,
  taskStatusesColors,
} from "../../entities/ITask";
import useBaseRequest from "../../api/BaseRequest";
import TaskGateway from "../../api/gateways/TaskGateway";
import { useToast } from "../../components/toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TableRow } from "../../components/table";
import moment from "moment";
import { Icon } from "../../modules/icon";
import { screens } from "../Navigation.types";
import { Badge } from "../../components/badge";
import { BadgeTypes } from "../../components/badge/Badge.types";
import { useCounterService } from "../../services/useCounterService";

const useTasks = () => {
  const { danger, success } = useToast();
  const navigate = useNavigate();
  const onRowPress = (id: number) => {
    navigate(screens.task.root.replace(":id", id.toString()));
  };
  const [tasks, setTasks] = useState<TableRow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const location = useLocation();
  const { refreshTaskCounters } = useCounterService();
  useEffect(() => {
    getData(location.search);
  }, [location.search]);

  const { execute: getData, loading } = useBaseRequest(TaskGateway.getTasks, {
    onCompleted: (data) => {
      if (data) {
        const taskTable: TableRow[] = [];

        data.tasks.forEach((item) => {
          taskTable.push([
            item.id,
            item.document.metaData.mb || item.document.metaData.jmbg || "",
            item.document.metaData.msisdn,
            item.document.metaData.documentCategory +
              "-" +
              item.document.metaData.documentType,

            AccountingPeriods[item.taskTypeCode],

            <Badge
              type={taskStatusesColors[item.status] as BadgeTypes}
              className="fw-bold"
            >
              {taskStatuses[item.status]}
            </Badge>,
            item.assignee &&
            item.status !== TaskStatuses.RESOLVED &&
            item.status !== TaskStatuses.CANCELED
              ? item.assignee.name
              : "",
            <Link
              to={screens.basketTasks.replace(":id", item.basketId.toString())}
            >
              {data.baskets
                ? data.baskets.find((it) => it.id === item.basketId)?.name || ""
                : "Basket"}
            </Link>,
            item.complaintNumber || "",
            item.customerName || "",
            item.createdBy.name,
            moment(item.createdAt).format("DD.MM.YYYY"),
            <div>
              <Link to={screens.task.root.replace(":id", item.id.toString())}>
                <Icon
                  className="mr-3"
                  title="See task's info"
                  type="fa-solid fa-circle-info"
                  color="black"
                  size={16}
                />
              </Link>
              <Link
                className="mr-3"
                to={screens.documents + `/${item.document.id}`}
                title="See document info"
              >
                <Icon type="fa-solid fa-file" color="black" size={16} />
              </Link>
              {!!item.comments.length && (
                <Link
                  to={
                    screens.task.root.replace(":id", item.id.toString()) +
                    `/comments`
                  }
                  title={
                    "Last comment: " +
                    item.comments[item.comments.length - 1].text
                  }
                >
                  <Icon type="fa-solid fa-message" color="black" size={16} />
                </Link>
              )}
            </div>,
          ]);
        });
        setTasks(taskTable);
        setTotal(data.count);
      }
    },
    onError: (error) => {
      danger(error?.message || "Something went wrong.");
    },
  });

  //Bulk actions
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
  const [selectedAction, setSelectedAction] = useState<"" | "assign" | "move">(
    ""
  );
  const { execute: reassignBulkTasks, loading: reassigningTasks } =
    useBaseRequest(TaskGateway.reassignBulkTasks, {
      onCompleted: () => {
        setSelectedTasks([]);
        setSelectedAction("");
        getData(location.search);
        refreshTaskCounters();
        success("Tasks successfully reassigned.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    });
  const { execute: moveBulkTasks, loading: movingTasks } = useBaseRequest(
    TaskGateway.moveToAnotherBasketBulkTasks,
    {
      onCompleted: () => {
        setSelectedTasks([]);
        setSelectedAction("");
        getData(location.search);
        success("Tasks successfully moved.");
      },
      onError: (error) => {
        danger(error?.message || "Something went wrong.");
      },
    }
  );

  return {
    tasks,
    total,
    getData,
    onRowPress,
    loading,
    selectedTasks,
    setSelectedTasks,
    selectedAction,
    setSelectedAction,
    reassignBulkTasks,
    reassigningTasks,
    moveBulkTasks,
    movingTasks,
  };
};

export default useTasks;
